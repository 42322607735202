import { StockTickerAutocomplete } from "./inputs/StockTickerAutocomplete";
import { Alert, Button, Col, Row, Typography, message, Checkbox } from "antd";
import {
  OptionFormInitialValues,
  useOptionsForm,
} from "../hooks/useOptionsForm";
import { OrdertypeInput } from "./inputs/OrderTypeInput";
import { DirectionInput } from "./inputs/DirectionInput";
import { SideInput } from "./inputs/SideInput";
import { ExpirationDatesInput } from "./inputs/ExpirationDatesInput";
import { StrikePricesInput } from "./inputs/StrikePricesInput";
import { TIFInput } from "./inputs/TIFInput";
import { QuantityInput } from "./inputs/QuantityInput";
import { LimitInput } from "./inputs/LimitInput";
import { StopInput } from "./inputs/StopInput";
import { SendOutlined } from "@ant-design/icons";
import { OptionsOrderConfirmationDialog } from "./OptionsOrderConfirmationDialog";
import { ErrorMessage } from "../../shared/components/ErrorMessage";
import { useOptionsEstimate } from "../hooks/useOptionsEstimate";
import { formatMoney } from "../../shared/utils";
import { FormLabel } from "./FormLabel";
import { useEffect } from "react";
import api from "../../api";
import { useWatchListItem } from "../../watchlist/context/watchlist-item-context";
import { useShareTradeContext } from "../context/share-trade-context";

const { Text } = Typography;

interface TradeOptionsProps {
  initialValues?: OptionFormInitialValues;
  onSuccess?: () => void;
  syncWithWatchlist?: boolean,
  positionCloseMode?: boolean,
  onConfirmationModalVisibilityToggle?: (value: boolean) => void,
}

export const TradeOptions = ({
  initialValues,
  onSuccess,
  syncWithWatchlist=false,
  positionCloseMode=false,
  onConfirmationModalVisibilityToggle,
}: TradeOptionsProps) => {

  const [messageApi, contextHolder] = message.useMessage();
  const { canShare, share, setShare } = useShareTradeContext();

  const {
    // form fields related state
    stock,
    setStock,
    expirationDate,
    setExpirationDate,
    direction,
    setDirection,
    strikePrice,
    setStrikePrice,
    orderType,
    setOrderType,
    side,
    setSide,
    tif,
    setTif,
    limitPrice,
    setLimitPrice,
    stopPrice,
    setStopPrice,
    quantity,
    setQuantity,

    // options for dropdowns
    options,

    // are limit and stop prices required
    limitRequired,
    stopRequired,
    showTif,

    // the selected stock is not optionable
    notOptionable,

    // confirm & submit state
    showConfirmation,
    setShowConfirmation,
    handleConfirmClick,
    handleSubmitClick,
    submitting,
    error,

    optionsQuote,
  } = useOptionsForm(initialValues);

  const { item: selectedWatchlistItem } = useWatchListItem();

  useEffect(() => {
    if (onConfirmationModalVisibilityToggle) {
      onConfirmationModalVisibilityToggle(showConfirmation);
    }
  }, [onConfirmationModalVisibilityToggle, showConfirmation])

  useEffect(() => {
    if (!syncWithWatchlist || selectedWatchlistItem == null) {
      return
    }
    setStock(selectedWatchlistItem?.symbol)
  }, [selectedWatchlistItem, syncWithWatchlist]);

  const estimatedPrice = useOptionsEstimate({
    quantity,
    limitPrice,
  });

  const onConfirm = async () => {
    const response = await handleConfirmClick();
    // return if response is empty
    if (!response || !response.id) {
      return
    }
    messageApi.success("Order Placed");
    if (onSuccess !== undefined) {
      onSuccess();
    }
    if (canShare && share) {
      await api.signals.share({ order_id: response.id });
    }
  };

  return (
    <div>
      {contextHolder}
      <Row gutter={[12, 12]}>
        <Col span={24}>
          {/* side  */}
          <SideInput
            options={options.sides.data}
            value={side}
            setValue={setSide}
          />
        </Col>

        {!!error && (
          <Col span={24}>
            <ErrorMessage style={{ margin: 0, marginTop: 8 }} error={error} />
          </Col>
        )}

        <Col span={24}>
          {/* direction */}
          {/* <FormLabel>Direction</FormLabel> */}
          <DirectionInput
            options={options.directions.data}
            value={direction}
            setValue={setDirection}
            disabled={positionCloseMode}
          />
        </Col>

        <Col span={24}>
          {/* stock */}
          <FormLabel>Stock</FormLabel>
          <StockTickerAutocomplete
            searchOptions={true}
            style={{ width: "100%" }}
            value={stock}
            placeholder="Stock"
            setValue={setStock}
            disabled={positionCloseMode}
          />
          {notOptionable && (
            <Alert
              style={{ marginTop: 8 }}
              type="error"
              message="This stock does not offer options"
            />
          )}
        </Col>

        <Col span={24}>
          {/* expiration date */}
          <FormLabel>Expiration Date</FormLabel>
          <ExpirationDatesInput
            dates={options.expirationDates.data}
            loading={options.expirationDates.loading}
            value={expirationDate}
            setValue={setExpirationDate}
            disabled={positionCloseMode}
          />
        </Col>

        <Col span={12}>
          {/* strike price */}
          <FormLabel>Strike Price</FormLabel>
          <StrikePricesInput
            prices={options.strikePrices.data}
            loading={options.strikePrices.loading}
            setValue={setStrikePrice}
            value={strikePrice}
            disabled={positionCloseMode}
          />
        </Col>

        <Col span={12}>
          <FormLabel>Order Type</FormLabel>
          <OrdertypeInput
            options={options.orderTypes.data}
            value={orderType}
            setValue={setOrderType}
          />
        </Col>

        <Col span={24}>
          <FormLabel>Quantity</FormLabel>
          <QuantityInput 
            disabled={optionsQuote.loading}
            loading={optionsQuote.loading}
            value={quantity} 
            setValue={setQuantity} />
        </Col>

        {/* limit required */}
        {limitRequired && (
          <Col span={stopRequired ? 12 : 24}>
            <FormLabel>Limit Price</FormLabel>
            <LimitInput 
              disabled={optionsQuote.loading}
              loading={optionsQuote.loading}
              value={limitPrice} 
              setValue={setLimitPrice} 
            />
          </Col>
        )}

        {/* stop required */}
        {stopRequired && (
          <Col span={limitRequired ? 12 : 24}>
            <FormLabel>Stop Price</FormLabel>
            <StopInput value={stopPrice} setValue={setStopPrice} />
          </Col>
        )}

        {
          showTif && (
            <Col span={24}>
              {/* tif */}
              <FormLabel>Time In Force</FormLabel>
              <TIFInput
                options={options.timeInForces.data}
                value={tif}
                setValue={setTif}
              />
            </Col>
          )
        }

        {!!estimatedPrice && (
          <Col span={24}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Text type="secondary">Estimated Price</Text>
              <Text strong>{formatMoney(estimatedPrice)}</Text>
            </div>
          </Col>
        )}

        {
          canShare && <Col span={24}>
            <Checkbox
              checked={share}
              onChange={(event) =>
                setShare(event.target.checked)
              }
            >
              Share
            </Checkbox>
          </Col>
        }

        <Col span={24}>
          {/* submit button  */}
          <Button
            disabled={submitting}
            loading={submitting}
            onClick={handleSubmitClick}
            style={{ marginTop: 8 }}
            block
            size="large"
            type="primary"
          >
            <SendOutlined /> Place Order
          </Button>
        </Col>
      </Row>

      {/* options order confirmation dialog */}
      <OptionsOrderConfirmationDialog
        onOk={onConfirm}
        open={showConfirmation}
        setOpen={setShowConfirmation}
        orderType={orderType}
        direction={direction}
        expirationDate={expirationDate}
        limitPrice={limitPrice}
        quantity={quantity}
        side={side}
        stock={stock}
        stopPrice={stopPrice}
        strikePrice={strikePrice}
        timeInForce={tif}
      />
    </div>
  );
};
