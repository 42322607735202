import { Navigate, useParams } from "react-router-dom";
import { Card, Col, Row, Space, Tag, Typography } from "antd";
import { SubscribersMembershipsTable } from "../../subscribers/components/SubscribersMembershipsTable";
import { MembershipPlan } from "../../api/signal-providers";
import { SubscribersPaymentsTable } from "../../subscribers/components/SubscribersPaymentsTable";
import { EarningsChart } from "../../analyst-earnings/earnings-chart";
import { useAuthUserSignalProviders } from "../../signal-providers/hooks/useAuthUserSignalProviders";

const { Title } = Typography;

const SignalProviderMembershipPlanDetail = () => {
  const plan = usePlan();
  const signalProvider = useSignalProvider();

  if (signalProvider === null || plan === null) {
    return <Navigate to='/app/dashboard' />
  }

  return (
    <>
      <Space direction="vertical" style={{ width: '100%' }}>
        <PlanInfoCard plan={plan} />
        <Row gutter={[8, 8]} align='stretch'>        
          <Col span={24}>
            <Card size="small" title='6 months earnings'>
              <EarningsChart planIds={[plan.id]} />
            </Card>
          </Col>
          <Col span={24} xl={{ span: 12 }} style={{ display: 'flex', alignItems: 'stretch' }}>
            <Card size="small" title="Subscriptions" style={{ width: '100%' }}>
              <SubscribersMembershipsTable planIds={[plan.id]} />
            </Card>
          </Col>
          <Col span={24} xl={{ span: 12 }} style={{ display: 'flex', alignItems: 'stretch' }}>
            <Card size="small" title="Payment History" style={{ width: '100%' }}>
              <SubscribersPaymentsTable planIds={[plan.id]} />
            </Card>
          </Col>
        </Row>
      </Space>
    </>
  );
};

function useSignalProvider() {
  const { signalProviderId } = useParams();
  const { providers } = useAuthUserSignalProviders();
  const parsedId = parseInt(signalProviderId || '');
  if (isNaN(parsedId)) {
    return null;
  }
  return providers.find(provider => provider.id === parsedId) || null;
}

function usePlan() {
  const { planId } = useParams();
  const signalProvider = useSignalProvider();
  
  const membershipPlans = (signalProvider?.membership_plans || []);
  const plan = membershipPlans.find(plan => plan.id === Number(planId)) || null;
  return plan;
}

function PlanInfoCard({ plan } :  { plan: MembershipPlan }) {
  return <Card>
    <Title level={4}>{ plan.name }</Title>
    <div style={{ marginTop: 16, display: 'flex', flexWrap: 'wrap' }}>
      {
        <Tag color={plan.active ? "success" : "error"}>
          { plan.active ? 'Active' : 'Inactive' }
        </Tag>
      }
      {
        plan.stocks && <Tag color="success">
          Stocks
        </Tag>
      }
      {
        plan.options && <Tag color="success">
          Options
        </Tag>
      }
      {
        plan.crypto && <Tag color="success">
          Crypto
        </Tag>
      }
    </div>
  </Card>
}

export default SignalProviderMembershipPlanDetail;
