import { CryptoQuoteResponse } from "../../api/crypto-quote";
import { CryptoAmountUnit, OrderType, Side, TimeInForce } from "../../api/enums";
import { BrokerType } from "../../brokers/entities/broker-account.entity";

export const cryptoLimitPriceRequired = (orderType: OrderType) => {
  return [OrderType.Limit, OrderType.StopLimit].includes(orderType);
}

export const cryptoStopPriceRequired = (orderType: OrderType) => {
  return [OrderType.Stop, OrderType.StopTrail, OrderType.StopLimit].includes(orderType);
}

export const cryptoGetSupportedTIFS = (
  brokerType?: BrokerType | null,
  orderType?: OrderType | null
) => {

  if (brokerType === BrokerType.robinhood) {
    return [ TimeInForce.GoodTillCancelled ]
  }

  if (brokerType === BrokerType.webull_pay) {
    // only ioc for market orders
    if (orderType === OrderType.Market) {
      return [TimeInForce.ImmediateOrCancelled];
    }
    
    // for stop and stop limit orders
    return [
        TimeInForce.Day,
        TimeInForce.GoodTillCancelled,
    ];
  }

  return [];
}

export function cryptoGetSupportedAmountUnits(brokerType: BrokerType, side: Side) {

  if (brokerType === BrokerType.webull_pay) {
    
    if (side === Side.Buy) {
      return [
        CryptoAmountUnit.Coin,
        CryptoAmountUnit.USD
      ]
    }
    
    if (side === Side.Sell) {
      return [
        CryptoAmountUnit.Coin,
      ]
    }

    return [];
  }

  if (brokerType === BrokerType.robinhood) {
    return [
      CryptoAmountUnit.Coin,
      CryptoAmountUnit.USD
    ]
  }

  return [];
}

export function cryptoGetSupportedSides(brokerType: BrokerType) {
  if (brokerType === BrokerType.webull_pay) {
    return [Side.Buy, Side.Sell];
  }

  if (brokerType === BrokerType.robinhood) {
    return [ Side.Buy, Side.Sell ];
  }
  
  return [];
}


export function cryptoGetSupportedOrderTypes(brokerType: BrokerType) {
  if (brokerType == BrokerType.robinhood) {
    return [
      OrderType.Limit,
      OrderType.Market,
    ]
  }

  if (brokerType == BrokerType.webull_pay) {
    return [
      OrderType.Limit,
      OrderType.Market,
      OrderType.StopLimit,
    ]
  }
  
  return [];
}

export function displayCryptoSymbol(symbol: string) {
  if (symbol.toUpperCase().endsWith('-USD')) {
    return symbol.replace(/\-usd$/i, '');
  }
  if (symbol.toUpperCase().endsWith('USD')) {
    return symbol.replace(/usd$/i, '');
  }
  return symbol;
}

export function getCryptoMidOrLastPrice(quote: CryptoQuoteResponse | null) {
  if (!quote) {
    return null;
  }

  if (quote.ask !== null && quote.bid !== null) {
    return (quote.ask + quote.bid) / 2;
  }

  if (quote.last !== null) {
    return quote.last;
  }

  return null;
}