import { SmallContainer } from "../../shared/components/SmallContainer";
import { MarketplaceSearchContextProvider } from "../components/MarketplaceSearchContext";
import { MarketplaceSearchAndFilterCard } from "../components/MarketplaceSearchAndFilterCard";
import { MarketplaceSearchResults } from "../components/MarketplaceSearchResults";
import Flex from "../../shared/components/Flex";
import { MarketplaceBanner } from "../components/MarketplaceBanner";
import { EmailVerifiedMessage } from "../../auth/components/EmailVerifiedMessage";
import { BecomeAnalystCallout } from "../components/BecomeAnalystCallout";
import { useAuthUser } from "../../auth/hooks/useAuthUser";
import { UserRole } from "../../api/enums";
import { ReferAndEarnCard } from "../components/ReferAndEarnCard";

export const MarketPlacePage = () => {
  const { user, initialized } = useAuthUser();
  const showBecomeAnalystCallout = initialized && user?.role == UserRole.trader;

  return <MarketplaceSearchContextProvider>
    <SmallContainer>
      <Flex flexDirection='column' gap={16} style={{ margin: '1rem 0' }}>
        <EmailVerifiedMessage />
        <MarketplaceBanner />
        <MarketplaceSearchAndFilterCard />
        <MarketplaceSearchResults />
        {
          showBecomeAnalystCallout && (
            <BecomeAnalystCallout />
          )
        }
        <ReferAndEarnCard />
      </Flex>
    </SmallContainer>
  </MarketplaceSearchContextProvider>
}