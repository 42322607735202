import { BotAlertWithOrders, CryptoBotAlertWithOrders, isCryptoBotAlert, isOptionsBotAlert, isStockBotAlert, OptionsBotAlertWithOrders, StockBotAlertWithOrders } from "../../api/autotrade-alerts";
import { AssetType, BotAction, BotEnforce, BotOrderType, Direction, OrderType, Side, TimeInForce } from "../../api/enums";
import { CryptoFormInitialValues } from "../../trade/hooks/useCryptoForm";
import { OptionFormInitialValues } from "../../trade/hooks/useOptionsForm";
import { StockFormInitialValues } from "../../trade/hooks/useStocksForm";
import { PlaceOrderFormInitialValues } from "../../trade/types/place-order-form-initial-values";

export const getFormInitialValuesFromAlert = (alert: BotAlertWithOrders): PlaceOrderFormInitialValues | null => {
  /*
  * Helps prefill place order form using parameters of autotrade alert.
  * 
  * When calculating values 
  * it tries to get value from calculated parameter of the alert.
  * If this fails it falls back to getting value from alert.
  */

  if (isStockBotAlert(alert)) {
    return getStocksFormInitialValues(alert);
  }

  if (isOptionsBotAlert(alert)) {
    return getOptionsFormInitialValues(alert);
  }

  if (isCryptoBotAlert(alert)) {
    return getCryptoFormInitialValues(alert);
  }

  return null;
};

function getCryptoFormInitialValues(alert: CryptoBotAlertWithOrders) {
  /*
  * Helps prefill crypto form from crypto alert.
  * 
  * When calculating values 
  * it tries to get value from calculated parameter of the alert.
  * If this fails it falls back to getting value from alert.
  */
  const params = alert?.parameters?.[0] || null;

  const amount = params?.amount || alert?.crypto_amount;

  const amountUnit = params?.amount_unit || alert?.crypto_amount_unit;

  const limitPrice =  params?.limit_price || alert?.crypto_price;

  const orderType = params?.order_type || (
    alert?.order_type 
    ? botOrderTypeToOrderType(alert.order_type) 
    : null
  );

  const side = params?.side || botActionToSide(alert?.action);

  const symbol = params?.ticker_symbol || alert.asset_name;

  const stopPrice = params?.stop_price || alert?.crypto_stop_price;

  const tif = params?.tif || (
    alert?.enforce 
      ? botEnforceToTif(alert.enforce)
      : null
  )

  return {
    asset_type: AssetType.Crypto,
    symbol: symbol,
    amount: amount || undefined,
    amountUnit: amountUnit || undefined,
    limitPrice: limitPrice || undefined,
    orderType: orderType || undefined,
    stopPrice: stopPrice || undefined,
    side: side || undefined,
    tif: tif || undefined,
  } satisfies CryptoFormInitialValues;
}

function getStocksFormInitialValues(alert: StockBotAlertWithOrders) {
  /*
  * Helps prefill stocks form from stocks alert.
  * 
  * When calculating values 
  * it tries to get value from calculated parameter of the alert.
  * If this fails it falls back to getting value from alert.
  */
  const params = alert?.parameters?.[0] || null;

  const orderType = params?.order_type || (
    alert?.order_type 
      ? botOrderTypeToOrderType(alert.order_type)
      : null
  );

  const side = params?.side || 
    (alert?.action ? botActionToSide(alert.action) : null);

  const tif = params?.time_in_force
    || (
      alert?.enforce 
      ? botEnforceToTif(alert.enforce) 
      : null
  );

  const limitPrice = params?.limit_price || alert.price;

  const quantity = params?.quantity || alert.quantity;

  const stopPrice = params?.stop_price || alert?.stop_price;

  return {
    asset_type: AssetType.Stocks,
    stock: alert.asset_name,
    side: side || undefined,
    orderType: orderType || undefined,
    tif: tif || undefined,
    limitPrice: limitPrice || undefined,
    quantity: quantity || undefined,
    stopPrice: stopPrice || undefined,
  } satisfies StockFormInitialValues;
}

function getOptionsFormInitialValues(alert: OptionsBotAlertWithOrders) {
  /*
  * Helps prefill options form from options alert.
  * 
  * When calculating values 
  * it tries to get value from calculated parameter of the alert.
  * If this fails it falls back to getting value from alert.
  */
  const params = alert?.parameters?.[0] || null;
  
  const side = alert?.action ? botActionToSide(alert.action) : null;

  const orderType = params?.order_type;
  const tif = params?.time_in_force 
    || (
      alert?.enforce 
        ? botEnforceToTif(alert.enforce)
        : null
  );

  const limitPrice = params?.limit_price || alert.price;
  const quantity = params?.quantity || alert.quantity;
  const stopPrice = params?.stop_price || alert?.stop_price;
  const direction = botActionToDirection(alert.action);
  const strike = params?.strike_price || alert.strike_price;
  const expiration = params?.expiration_date || alert.expiration_date;

  return {
    asset_type: AssetType.Options,
    stock: alert.asset_name,
    side: side || undefined,
    orderType: orderType,
    limitPrice: limitPrice || undefined,
    stopPrice: stopPrice || undefined,
    tif: tif || undefined,
    quantity: quantity || undefined,
    direction: direction || undefined,
    strikePrice: strike || undefined,
    expirationDate: expiration || undefined,
  } satisfies OptionFormInitialValues;
}

function botEnforceToTif(enforce: BotEnforce) {
  if (enforce == BotEnforce.day) {
    return TimeInForce.Day;
  }
  if (enforce == BotEnforce.gtc) {
    return TimeInForce.GoodTillCancelled;
  }
  return null;
}

function botActionToSide(action: BotAction) {
  if (action === BotAction.buy) {
    return Side.Buy
  }
  if (action === BotAction.sell) {
    return Side.Sell;
  }
  return null;
}

function botActionToDirection(action: BotAction) {
  if (action === BotAction.call) {
    return Direction.Call;
  }
  if (action === BotAction.put) {
    return Direction.Put;
  }
  return null;
}

function botOrderTypeToOrderType(orderType: BotOrderType) {
  return {
    [BotOrderType.limit]: OrderType.Limit,
    [BotOrderType.market]: OrderType.Market,
    [BotOrderType.stop]: OrderType.Stop,
    [BotOrderType.stop_limit]: OrderType.StopLimit,
  }[orderType];
}