import { useState, useEffect } from 'react';

export function useNotificationPermission() {

  const [permission, setPermission] = useState(Notification.permission);

  const [asking, setAsking] = useState(false);
  const [error, setError] = useState<unknown>(null);

  // Check permission every second
  useEffect(() => {
    const recheckPermission = () => {
      setPermission(Notification.permission);
    }
    const timeout = setInterval(recheckPermission, 1000);
    return () => clearTimeout(timeout);
  }, []);

  const askPermission = async () => {

    try {

      const permission = await Notification.requestPermission() 
      setPermission(permission);

    } catch(e) {

      setError(e);

    } finally {

      setAsking(false);

    }
  }

  return {
    hasPermission: permission === 'granted',
    permanentlyDenied: permission === 'denied',
    asking,
    error,
    askPermission
  }
}