import { CSSProperties, HTMLAttributes, forwardRef } from 'react';
import { useEmotionCss } from '@ant-design/use-emotion-css';

// We are on old version of ant design so for now we've added this flex component

export type FlexCoreProps = {
  flexDirection?: CSSProperties['flexDirection'];
  alignItems?: CSSProperties['alignItems'];
  justifyContent?: CSSProperties['justifyContent'];
  flexGrow?: CSSProperties['flexGrow'],
  flexShrink?: CSSProperties['flexShrink'],
  flexBasis?: CSSProperties['flexBasis'],
  flexWrap?: CSSProperties['flexWrap'];
  gap?: CSSProperties['gap'],
  flex?: CSSProperties['flex'],
};

export type FlexProps = FlexCoreProps & {
  xs?: FlexCoreProps;
  sm?: FlexCoreProps;
  md?: FlexCoreProps;
  lg?: FlexCoreProps;
  xl?: FlexCoreProps;
} & HTMLAttributes<HTMLDivElement>;

const Flex = forwardRef<HTMLDivElement, FlexProps>(
  (
    {
      flexDirection = 'row',
      alignItems = 'normal',
      justifyContent = 'normal',
      flexWrap = 'nowrap',
      flex = 'normal',
      flexGrow,
      gap = 0,
      xs,
      sm,
      md,
      lg,
      xl,
      style,
      className,
      children,
      ...props 
    },
    ref 
  ) => {
    const baseFlexClass = useEmotionCss(({ token }) => ({
      display: 'flex',
      flexDirection,
      alignItems,
      justifyContent,
      flexWrap,
      flexGrow,
      gap,
      ...style,
      [`@media (min-width: ${token.screenXSMin}px)`]: {
        ...xs,
      },
      [`@media (min-width: ${token.screenSMMin}px)`]: {
        ...sm,
      },
      [`@media (min-width: ${token.screenMDMin}px)`]: {
        ...md,
      },
      [`@media (min-width: ${token.screenLGMin}px)`]: {
        ...lg,
      },
      [`@media (min-width: ${token.screenXLMin}px)`]: {
        ...xl,
      },
    }));

    return (
      <div ref={ref} className={`${baseFlexClass} ${className || ''}`} {...props}>
        {children}
      </div>
    );
  }
);

export default Flex;
