import { useEffect, useState } from "react";
import { Col, Row, Segmented, Space, Switch } from "antd";
import MyMembershipPlanCard from "./MyMembershipPlanCard";
import { BillingIntervalUnit } from "../../api/enums";
import { MembershipPlan, SignalProvider } from "../../api/signal-providers";
import Flex from "../../shared/components/Flex";

export function AnalystPlansList({ plans, refresh, provider }: { plans: MembershipPlan[]; refresh: () => void; provider: SignalProvider }) {

  const [selectedUnit, setSelectedUnit] = useState<BillingIntervalUnit>(BillingIntervalUnit.Year);
  const [showInactive, setShowInactive] = useState(false);

  const tabOptions = [
    { label: 'Weekly', value: BillingIntervalUnit.Week },
    { label: 'Monthly', value: BillingIntervalUnit.Month },
    { label: 'Yearly', value: BillingIntervalUnit.Year },
  ].filter(option => {
    return plans.some(plan => plan.billing_interval_unit === option.value);
  });

  const filteredPlans = plans
    .filter(p => p.billing_interval_unit === selectedUnit)
    .filter(p => showInactive ? true : p.active);

  useEffect(() => {
    if (tabOptions && tabOptions.length > 0) {
      setSelectedUnit(tabOptions[tabOptions.length - 1].value);
    }
  }, [JSON.stringify(tabOptions)]);

  return <Flex
    flexDirection="column"
    gap={32}
  >
    <Flex gap={32} justifyContent='space-between' flexWrap="wrap" alignItems='center'>
      <Segmented
        size="large"
        options={tabOptions}
        value={selectedUnit}
        onChange={(e) => {
          setSelectedUnit(e as BillingIntervalUnit);
        }} />
      <Space>
        <div>
          Show Inactive Plans
        </div>
        <Switch
          checked={showInactive}
          onChange={(checked) => setShowInactive(checked)} />
      </Space>
    </Flex>
    <Row gutter={[10, 10]}>
      {filteredPlans?.map((plan) => (
        <Col span={24} md={{ span: 12 }} key={plan.id}>
          <MyMembershipPlanCard
            provider={provider}
            style={{ height: '100%' }}
            membershipPlan={plan}
            onDelete={refresh}
            onEditPrice={refresh} />
        </Col>
      ))}
    </Row>
  </Flex>;
}
