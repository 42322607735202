import { Card, Space, notification, Button, Typography } from "antd"
import { SignalProvider, SignalProviderFull } from "../../api/signal-providers"
import { EyeOutlined, EditOutlined } from '@ant-design/icons';
import { Link } from "react-router-dom";
import { SignalProviderTypeTag } from "../../signal-providers/components/SignalProviderTypeTag";
import { useState } from "react";
import { EditSignalProviderPopup } from "../../signal-providers/components/analyst-dashboard/EditSignalProviderPopup";
import { SignalProviderProfileImageEdit } from "../../signal-providers/components/analyst-dashboard/SignalProviderProfileImageEdit";
import Flex from "../../shared/components/Flex";

export const SignalProviderHeaderCard = ({
  signalProvider,
  onEditSuccess,
}: {
  signalProvider: SignalProviderFull,
  onEditSuccess: () => void,
}) => {

  const [notificationApi, contextHolder] = notification.useNotification();
  const [editFormOpen, setEditFormOpen] = useState(false);

  const onEditClick = () => {
    setEditFormOpen(true);
  }

  const onEditSuccessHandler = () => {
    onEditSuccess();
    setEditFormOpen(false);
    notificationApi.success({
      message: 'Edited Account'
    });
  }

  return <Card bordered={false}>
    {contextHolder}
    <Flex justifyContent={'space-between'} flexWrap='wrap' alignItems='center' gap={16}>
      <Card.Meta
        avatar={
          <SignalProviderProfileImageEdit
            provider={signalProvider}
            onSuccess={onEditSuccess}
          />
        }
        title={(
          <>
            <Link to={`/app/signal-providers/${signalProvider.username}`}>
              <Typography.Title level={2} style={{ marginBottom: 0 }}>
                { signalProvider?.name }
              </Typography.Title>
            </Link>
          </>
        )}
        description={
          <Space direction="vertical">
            <span>@{ signalProvider.username }</span>
            <SignalProviderTypeTag type={signalProvider.type} />
          </Space>
        }
      />
      <Space>
        <Button icon={<EditOutlined/>} onClick={onEditClick}>
          Edit Profile
        </Button>
        <Link to={`/app/signal-providers/${signalProvider.username}`}>
          <Button icon={<EyeOutlined/>} title="View Profile">
            View Profile
          </Button>
        </Link>
      </Space>
    </Flex>
    <EditSignalProviderPopup
      providerId={signalProvider.id}
      providerType={signalProvider.type}
      name={signalProvider.name}
      bio={signalProvider.bio}
      intro={signalProvider.intro}
      open={editFormOpen}
      onClose={() => {
        setEditFormOpen(false);
      }}
      onSuccess={onEditSuccessHandler}
    />
  </Card>
}