import { InputNumber } from "antd";
import { CryptoAmountUnit } from "../../../api/enums";
import { displayCryptoSymbol } from "../../../trade/utils/crypto";

export const CryptoQuantityInput = ({
  value,
  setValue,
  defaultValue,
  unit,
  symbol,
}: {
  value: number | null;
  setValue: (value: number | null) => void;
  defaultValue?: number;
  unit: CryptoAmountUnit,
  symbol: string,
}) => {
  return (
    <InputNumber
      style={{ display: "block", width: "100%" , minWidth: 100}}
      placeholder="Quantity"
      defaultValue={defaultValue}
      value={value}
      onChange={(value) => setValue(value)}
      addonAfter={
        {
          [CryptoAmountUnit.Coin]: displayCryptoSymbol(symbol),
          [CryptoAmountUnit.USD]: '$',
        }[unit]
      }
    />
  );
};
