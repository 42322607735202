import { useEffect, useState } from "react";
import { AssetType, CryptoAmountUnit, OrderType, Side, TimeInForce } from "../../api/enums";
import { useShareTradeContext } from "../context/share-trade-context";
import { cryptoGetSupportedAmountUnits, cryptoGetSupportedOrderTypes, cryptoGetSupportedSides, cryptoGetSupportedTIFS, cryptoLimitPriceRequired, cryptoStopPriceRequired, getCryptoMidOrLastPrice } from "../utils/crypto";
import useSWR from 'swr';
import api from "../../api";
import { useSelectedBroker } from "../../brokers/context/SelectedBrokerContext";
import { useAuthUser } from "../../auth/hooks/useAuthUser";
import { PlaceCryptoOrderRequest } from "../../api/orders";

export type CryptoFormInitialValues = {
  asset_type: AssetType.Crypto
  symbol?: string
  side?: Side
  amountUnit?: CryptoAmountUnit
  amount?: number
  limitPrice?: number
  stopPrice?: number
  orderType?: OrderType
  tif?: TimeInForce
}

export const useCryptoForm = (initialValues?: CryptoFormInitialValues) => {
  const { broker } = useSelectedBroker();

  const { user } = useAuthUser();

  const { canShare, share } = useShareTradeContext();

  const [symbol, setSymbol] = useState<string | null>(null);
  
  const [orderType, setOrderType] = useState<OrderType>(OrderType.Limit);
  
  const [side, setSide] = useState<Side>(Side.Buy);
  
  const [tif, setTif] = useState<TimeInForce>(TimeInForce.Day);
  
  const [limitPrice, setLimitPrice] = useState<number | null>(null);
  
  const [stopPrice, setStopPrice] = useState<number | null>(null);
  
  // Unit of the amount below (usd or coin)
  const [amountUnit, setAmountUnit] = useState<CryptoAmountUnit>(CryptoAmountUnit.USD);
  
  // Either amount in dollars
  // or quantity in coins
  // depending on the crypto_amount_unit columns value
  const [amount, setAmount] = useState<number | null>(null);
  
  const [showConfirmation, setShowConfirmation] = useState<boolean>(false);
  
  const [submitting, setSubmitting] = useState<boolean>(false);
  
  const [error, setError] = useState<unknown>(null);

  const limitRequired = cryptoLimitPriceRequired(orderType);

  const stopRequired = cryptoStopPriceRequired(orderType);

  const {
    data: quote,
    isLoading: quoteLoading,
    isValidating: quoteValidating,
  } = useSWR(
    (symbol !== null) && limitRequired
      ? ['crypto-quote', symbol]
      : null,
    (key) => {
      return api.cryptoQuote.getQuote({ ticker_symbol: key[1] })
    }
  )

  const limitPriceLoading = quoteLoading || quoteValidating;

  const orderTypesOptions = cryptoGetSupportedOrderTypes(broker!.account.type);

  const tifOptions = cryptoGetSupportedTIFS(broker!.account.type, orderType);

  const amountUnitOptions = cryptoGetSupportedAmountUnits(broker!.account.type, side);

  const sideOptions = cryptoGetSupportedSides(broker!.account.type);
  
  // prefill initial values
  useEffect(() => {
    if (!initialValues) {
      return;
    }

    if (initialValues.symbol) {
      setSymbol(initialValues.symbol);
    }

    if (initialValues.side) {
      setSide(initialValues.side);
    }

    if (initialValues.amountUnit) {
      setAmountUnit(initialValues.amountUnit);
    }

    if (initialValues.amount) {
      setAmount(initialValues.amount);
    }

    if (initialValues.tif) {
      setTif(initialValues.tif);
    }

    if (initialValues.limitPrice) {
      setLimitPrice(initialValues.limitPrice);
    }

    if (initialValues.orderType) {
      setOrderType(initialValues.orderType);
    }

    if (initialValues.stopPrice) {
      setStopPrice(initialValues.stopPrice);
    }

  }, [initialValues]);

  // set order type from preference
  useEffect(() => {
    if (
        !orderType && 
        initialValues?.orderType === undefined &&
        user?.trade_preference?.order_type_preference !== undefined &&
        user?.trade_preference?.order_type_preference !== null &&
        orderTypesOptions.includes(user.trade_preference.order_type_preference)
      ) {
        setOrderType(user.trade_preference.order_type_preference);
      }
  }, [
    orderType,
    user?.trade_preference?.order_type_preference, 
    initialValues?.orderType,
    orderTypesOptions
  ]);

  // set unit / amount from preference
  useEffect(() => {
    if (!initialValues?.amountUnit &&
      user?.trade_preference?.crypto_unit) {
      setAmountUnit(user?.trade_preference?.crypto_unit);   
    }
    if (!initialValues?.amount &&
      user?.trade_preference?.crypto_quantity) {
      setAmount(user?.trade_preference?.crypto_quantity);   
    }
  }, [
    user?.trade_preference?.crypto_unit,
    user?.trade_preference?.crypto_quantity,
    initialValues?.amountUnit,
    initialValues?.amount,
  ]);

  // set tif from preference
  useEffect(() => {
    if (!initialValues?.tif &&
      !tif &&
      user?.trade_preference?.time_in_force &&
      tifOptions.includes(user.trade_preference.time_in_force)) {
      setTif(user.trade_preference.time_in_force);
    }
  }, [
    user?.trade_preference?.time_in_force,
    initialValues?.tif,
    tifOptions,
    tif
  ]);
  

  // prefill limit price with mid price or last price
  useEffect(() => {
    // quote not available
    if (quote === null || quote === undefined) {
      return
    }

    // is placing order (so avoid changing price)
    if (showConfirmation || submitting) {
      return
    }

    // form was opened with prefilled limitPrice so dont update limit price
    const prefilledLimitPrice = initialValues?.limitPrice !== undefined;
    if (prefilledLimitPrice) {
      return
    }

    // update limit price with mid price
    let price = getCryptoMidOrLastPrice(quote);
    
    // quote does not include price
    if (price === null) {
      return
    }
  
    setLimitPrice(price);

  }, [ quote, initialValues?.limitPrice, showConfirmation, submitting ]);


  // reset limit price
  useEffect(() => {
    if (!limitRequired) {
      setLimitPrice(null);
    }
  }, [limitRequired]);

  // reset stop price
  useEffect(() => {
    if (!stopRequired) {
      setStopPrice(null);
    }
  }, [stopRequired]);

  // default tif
  useEffect(() => {
    if (tifOptions.length === 1 && tif !== tifOptions[0]) {
      setTif(tifOptions[0]);
    } 
  }, [tifOptions, tif]);

  // default order type
  useEffect(() => {
      if (orderTypesOptions.length === 1 && orderType !== orderTypesOptions[0]) {
        setOrderType(orderTypesOptions[0]);
      } 
  }, [orderTypesOptions, orderType]);

  // default amount unit
  useEffect(() => {
      if (amountUnitOptions.length === 1 && amountUnit !== amountUnitOptions[0]) {
        setAmountUnit(amountUnitOptions[0]);
      } 
  }, [amountUnitOptions, amountUnit]);


  const validate = () => {
    setError(null);
    if (!side) {
      setError("Side is required");
      return false;
    }
    if (!symbol) {
      setError("Symbol is required");
      return false;
    }
    if (!orderType) {
      setError("Order Type is required");
      return false;
    }
    if (!amountUnit) {
      setError("amount unit is required");
      return false;
    }
    if (!amount) {
      setError("amount unit is required");
      return false;
    }
    if (limitRequired && !limitPrice) {
      setError("Limit Price is required");
      return false;
    }
    if (stopRequired && !stopPrice) {
      setError("Stop Price is required");
      return false;
    }
    if (!tif) {
      setError("Time in force is required");
      return false;
    }
    return true;
  };

  // show confirmation dialog when submit button is clicked
  const handleSubmitClick = () => {
    const valid = validate();
    if (!valid) {
      return;
    }
    setShowConfirmation(true);
  };


  // submit the form when confirm button is clicked
  const handleConfirmClick = async () => {
    // hide confirmation dialog
    setShowConfirmation(false);

    const valid = validate();
    if (!valid) {
      return;
    }

    setSubmitting(true);

    try {
      const requestPayload: PlaceCryptoOrderRequest = {
        order_type: orderType,
        amount_unit: amountUnit,
        amount: amount!,
        side: side!,
        symbol: symbol!,
        tif: tif,
        limit_price: limitPrice  || null,
        stop_price: stopPrice  || null,
        share: canShare && share,
      };

      return await api.orders.placeCryptoOrder(requestPayload);

    } catch (e) {

      setError(e);

    } finally {

      setSubmitting(false);

    }
  };

  return {
    // form fields
    symbol,
    setSymbol,
    orderType,
    setOrderType,
    side,
    setSide,
    tif,
    setTif,
    limitPrice,
    setLimitPrice,
    stopPrice,
    setStopPrice,
    amountUnit,
    setAmountUnit,
    amount,
    setAmount,

    limitRequired,
    stopRequired,


    // form related state
    showConfirmation,
    setShowConfirmation,
    handleSubmitClick,
    handleConfirmClick,
    submitting,
    error,

    limitPriceLoading,
    quote: quote || null,

    // options for select
    options: {
      orderTypes: {
        data: orderTypesOptions,
        loading: false,
      },
      sides: {
        data: sideOptions,
        loading: false,
      },
      timeInForces: {
        data: tifOptions,
        loading: false,
      },
      amountUnit: {
        data: amountUnitOptions,
        loading: false,
      },
    },
  }
}