import { useEffect } from "react";
import api from "../../api";
import { useAuthUser } from "../../auth/hooks/useAuthUser";
import { getFCMToken } from "../../firebase/messaging-utils";
import { useNotificationPermission } from "./use-notification-permission";

export function useSyncFCMToken() {
  const { initialized, user } = useAuthUser();
  const { hasPermission } = useNotificationPermission();

  useEffect(() => {
    console.log("sync start", {initialized, hasPermission, user})

    if (!initialized || !hasPermission || !user) {
      console.log("sync early return", {initialized, hasPermission, user})
      return
    }

    const controller = new AbortController()

    const sync = async () => {
      console.log("sync");
      if (controller.signal.aborted) {
        return
      }
      console.log("sync2");
      try {
        const token = await getFCMToken();
        await api.fcmTokens.addFCMToken(token, controller.signal);
      } catch(e) {
        // retry on error
        setTimeout(sync, 1000);
      }
    }

    sync();

    return () => {
      controller.abort();
    };

  }, [initialized, user, hasPermission]);

  return null;
}