import axios from './axios';
import { AssetType, Direction, OrderType, Side } from './enums';

export type ParseSignalRequest = {
  signal: string
  asset_type: AssetType
}

export type ParsedOptionsSignal = {
  // don't change this line, type inference would break
  asset_type: AssetType.Options;
  order_type: OrderType;
  symbol: string;
  direction: Direction | null,
  price: number | null;
  strike: number | null;
  exp_date: string | null; 
  side: Side | null;
  stop: number | null;
  quantity: number | null;
};

export type ParsedStocksSignal = {
  // don't change this line, type inference would break
  asset_type: AssetType.Stocks;
  order_type: OrderType;
  symbol: string;
  price: number | null;
  side: Side | null;
  stop: number | null;
  quantity: number | null;
};

export type ParsedCryptoSignal = {
  // don't change this line, type inference would break
  asset_type: AssetType.Crypto;
  order_type: OrderType;
  symbol: string;
  price: number | null;
  side: Side | null;
  stop: number | null;
  quantity: number | null;
};

export type ParseSignalResponse = ParsedOptionsSignal | ParsedStocksSignal | ParsedCryptoSignal;

export const isStocksSignalResponse = (response: ParseSignalResponse) : response is ParsedStocksSignal => {
  return response?.asset_type === AssetType.Stocks;
}

export const isOptionsSignalResponse = (response: ParseSignalResponse) : response is ParsedOptionsSignal => {
  return response?.asset_type === AssetType.Options;
}

export const isCryptoSignalResponse = (response: ParseSignalResponse) : response is ParsedCryptoSignal => {
  return response?.asset_type === AssetType.Crypto;
}

const discordSignalParserApi = {
  async parseDiscordSignal (request: ParseSignalRequest) {
    const {
      data
    } = await axios.post<ParseSignalResponse | null>(
      '/parse-discord-signal',
      request,
      { needsBrokerId: true }
    );
    return data;
  }
}

export default discordSignalParserApi;