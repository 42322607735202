import { Typography } from 'antd';
import React, { useState, useRef, useEffect } from 'react';

type EllipsisTextProps = {
  children: React.ReactNode;
  maxLines?: number; // Optional, defaults to 3
  collapseText?: string;
  expandText?: string;
}

const EllipsisText: React.FC<EllipsisTextProps> = ({ children, maxLines = 3, collapseText = 'Read Less', expandText = 'Read More' }) => {
  const [isTruncated, setIsTruncated] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const textRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const textElement = textRef.current;
    if (textElement) {
      // Measure text content and determine if truncation is necessary
      const { scrollHeight, offsetHeight } = textElement;
      if (scrollHeight > offsetHeight) {
        setIsTruncated(true);
      }
    }
  }, [children]);

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div>
      <div
        ref={textRef}
        style={{
          maxHeight: isExpanded ? 'none' : `${maxLines}em`, // Calculate based on line height
          overflow: 'hidden',
          WebkitLineClamp: isExpanded ? 'none' : maxLines,
          WebkitBoxOrient: 'vertical',
        }}
      >
        {children}
      </div>
      {isTruncated && (
        <Typography.Link onClick={toggleExpand}>
          {isExpanded ? collapseText : expandText}
        </Typography.Link>
      )}
    </div>
  );
};

export default EllipsisText;
