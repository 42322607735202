import {
  AppstoreOutlined,
  DashboardOutlined,
  SettingOutlined,
  StockOutlined,
  RobotOutlined,
  LinkOutlined,
  DollarOutlined
} from "@ant-design/icons";
import { Menu } from "antd";
import Sider from "antd/es/layout/Sider";
import { Link, useLocation } from "react-router-dom";
import styles from './sidebar.module.scss';

import blackLogoIcon from '../images/black-logo-icon.png';
import blackLogoText from '../images/black-logo-text.png';
import whiteLogoIcon from '../images/white-logo-icon.png';
import whiteLogoText from '../images/white-logo-text.png';

import { useThemeContext } from "../context/ThemeContext";
import { useAuthUser } from "../../auth/hooks/useAuthUser";
import { UserRole } from "../../api/enums";

export const SideMenu = ({
  collapsed,
  setCollapsed,
}: {
  collapsed: boolean;
  setCollapsed: (collapsed: boolean) => void;
}) => {

  const { isDark } = useThemeContext();
  const { initialized, user } = useAuthUser();
  const isAnalyst = initialized && user !== null && user.role === UserRole.analyst;

  const location = useLocation();
  const menuItems = getMenuItems(isAnalyst);

  const selectedKeys = menuItems.map(item => ({
    ...item,
    // analysts page is inside /app/account
    // so both /app/account (account) and /app/account/analyst (analyst) are highlighted
    // so for now we have this hack
    // we could fix this by changing url of analyst to /app/analyst
    // but we are not doing this right now to prevent breaking existing code
    
    selected: item.key !== 'account'
      ? location.pathname.startsWith(item.href)
      : location.pathname.startsWith(item.href) && !location.pathname.startsWith('/app/account/analyst'),
  })).filter(item => item.selected).map(item => item.key);

  const logoIcon = isDark ? whiteLogoIcon : blackLogoIcon;

  const logoText = isDark ? whiteLogoText : blackLogoText;

  return <Sider
    collapsible
    collapsed={collapsed}
    onCollapse={(value, type) => {
      if (type === 'clickTrigger') {
        setCollapsed(value);
      }
    }}
    breakpoint="lg"
    width={250}
    className={styles.sidebar}
    defaultCollapsed={true}
  >
    <div className={styles.logo}>
      <div className={styles.logoIcon}>
        <img
          width={35}
          src={logoIcon}
        />
      </div>
      <div className={styles.logoText}>
        <img
          style={{
            width: 100,
          }}
          src={logoText}
        />
      </div>
    </div>
    <Menu
      style={{ background: 'none' }}
      theme={ isDark ? 'dark' : 'light' }
      mode="vertical"
      selectedKeys={selectedKeys}
      items={menuItems.map((item) => ({
        key: item.key,
        icon: item.icon,
        label: <Link to={item.href}>
          { item.label }
        </Link>
      }))}
    />
  </Sider>
}

function getMenuItems(isAnalyst : boolean) {
  let menuItems = [
    { 
      key: 'dashboard', 
      label: "SocialTrade Dashboard",
      icon: <DashboardOutlined/>,
      href: '/app/dashboard',
    },
    {
      key: 'autotrade',
      label: 'AutoTrade Dashboard',
      icon: <RobotOutlined />,
      href: '/app/autotrade',
    },
    {
      key: 'brokers-connections',
      label: 'Broker Connections',
      icon: <LinkOutlined />,
      href: '/app/broker-connections',
    },
    { 
      key: 'marketplace', 
      label: "Marketplace",
      icon: <AppstoreOutlined/>,
      href: '/app/marketplace',
    },
    { 
      key: 'buy-coins', 
      label: "Buy Coins", 
      icon: <DollarOutlined />,
      href: '/app/buy-coins',
    },
    { 
      key: 'account', 
      label: "Account", 
      icon: <SettingOutlined />,
      href: '/app/account',
    },
  ];

  if (isAnalyst) {
    menuItems.push({
      key: 'analyst', 
      label: "Signal Provider",
      icon: <StockOutlined />,
      href: '/app/account/analyst',
    });
  }

  return menuItems;
}