import { Avatar, Button, Card, Space, Typography } from "antd";
import { SignalProviderWithMembershipPlans } from "../../../api/signal-providers";
import { EyeOutlined, SettingOutlined } from '@ant-design/icons';
import { SignalProviderTypeTag } from "../SignalProviderTypeTag";
import { getImageUrl } from "../../../shared/image-utils";
import Flex from "../../../shared/components/Flex";
import { PatternImage } from "../../../shared/components/PatternImage";
import { getProfileImage } from "../../utils/get-profile-image";
import { Link } from "react-router-dom";
import { CSSProperties } from "react";

export function AnalystSignalProviderListItem({
  provider,
}: {
  provider: SignalProviderWithMembershipPlans;
}) {

  const coverImage = provider.cover_image
    ? <img style={{ aspectRatio: `${16/9}` }} src={getImageUrl(provider.cover_image, { variant: '464x261' })}/>
    : <PatternImage aspect={16/9} />

  const actionStyle : CSSProperties = { flex: 1, display: 'flex' }
  const actionButtonStyle : CSSProperties = { flex: 1 }

  return <Card
    style={{ overflow: 'clip', flex: 1 }}
    cover={coverImage}
  >
    <Flex gap={16}>
      <Flex flexShrink={'0'}>
        <Avatar
          size={'large'}
          src={getProfileImage(provider, { variant: '100x100' })}
        >
          { !provider.profile_image && provider.name }
        </Avatar>
      </Flex>
      <Flex flexDirection="column" gap={8}>
        <Typography.Title level={4} style={{ margin: 0 }}>
          { provider.name }
        </Typography.Title>
        <Space>
          <SignalProviderTypeTag type={provider.type} />
        </Space>
        <Typography.Paragraph type="secondary">
          { provider.intro }
        </Typography.Paragraph>
      </Flex>
    </Flex>
    <Flex gap={8} style={{ marginTop: 'auto' }}>
      <Link style={actionStyle} to={`/app/account/analyst/${provider.id}`}>
        <Button size="large" style={actionButtonStyle}>
          <SettingOutlined />
        </Button>
      </Link>
      <Link style={actionStyle} to={`/app/signal-providers/${provider.username}`}>
        <Button size="large" style={actionButtonStyle}>
          <EyeOutlined />
        </Button>
      </Link>
    </Flex>
  </Card>
}
