import { Spin } from "antd";
import { Navigate, useSearchParams } from "react-router-dom"
import useSWR from 'swr';
import api from "../../api";
import { ErrorMessage } from "../../shared/components/ErrorMessage";
import { InviteUi } from "../components/analyst-dashboard/InviteUi";

export function AcceptOrRejectCommunityInvitePage() {
  const [queryParams, _] = useSearchParams();
  const token = queryParams.get("token");

  if (!token) {
    return <Navigate to='/app/dashboard' />
  }

  return <Page token={token} />
}

function Page({ token } : { token: string }) {

  const invite = useSWR(['invites', token], (key) => {
    const token = key[1];
    return api.signalProviders.findInviteByToken(token);
  });

  return <div>
    <div style={{ 
      display: 'flex', 
      flexDirection: 'row',
      justifyContent: 'center',
      marginTop: 32,
    }}>
      <div>
        {
          invite.isLoading && (
            <div style={{ textAlign: 'center' }}>
              <Spin />
            </div>
          )
        }
        {
          !!invite.error && (
            <ErrorMessage error={invite.error} />
          )
        }
        {
          !!invite.data && (
            <InviteUi
              invite={invite.data}
              token={token}
            />
          )
        }
      </div>
    </div>
  </div>
}
