import React from "react";
import { Card, Tag } from "antd";
import api from "../../api";
import useSWR from "swr";
import { formatBillingCycles, formatBillingInterval, formatMoney } from "../../shared/utils";
import { Typography } from "antd";
const { Title, Text } = Typography;

export const PlanInfo = ({
  planId,
}: {
  planId: number;
}) => {

  const {
    data : plan,
    isLoading,
  } = useSWR(`/api/membership-plans/${planId}`, () => (
    api.membershipPlans.findById(planId)
  ));

  return <Card style={{
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100%"
  }}
    loading={isLoading}
  >
    {plan && <Card
      title={plan.name}
      style={{ textAlign: 'center' }}
    >
      <div style={{ textAlign: "center" }}>
        <div style={{ marginBottom: '16px', display: 'flex', justifyContent: 'center', flexWrap: 'wrap' }}>
          {
            plan.stocks && <Tag color="success">
              Stocks
            </Tag>
          }
          {
            plan.options && <Tag color="success">
              Options
            </Tag>
          }
          {
            plan.crypto && <Tag color="success">
              Crypto
            </Tag>
          }
        </div>
        <Title style={{ marginBottom: 0 }}>
          { formatMoney(plan?.amount) }
        </Title>
        <Text>
          {formatBillingInterval(plan.billing_interval, plan.billing_interval_unit)}
        </Text>
        <Text type='secondary' style={{ display: 'block', textTransform: 'capitalize' }}>
          {
            formatBillingCycles(plan.billing_interval, plan.billing_interval_unit, plan.billing_cycles)
          }
        </Text>
      </div>
    </Card>}
  </Card>;
};
