import { SendOutlined } from '@ant-design/icons';
import { Button, Checkbox, Col, message, Row, Typography } from "antd";
import { useEffect } from "react";
import api from "../../api";
import { sideLabels } from '../../api/enum-labels';
import { Side, UserRole } from "../../api/enums";
import { useAuthUser } from "../../auth/hooks/useAuthUser";
import { ErrorMessage } from '../../shared/components/ErrorMessage';
import { useWatchListItem } from "../../watchlist/context/watchlist-item-context";
import { useShareTradeContext } from "../context/share-trade-context";
import { useCryptoEstimate } from "../hooks/useCryptoEstimate";
import { CryptoFormInitialValues, useCryptoForm } from "../hooks/useCryptoForm";
import { CryptoOrderConfirmationDialog } from "./CryptoOrderConfirmationDialog";
import { FormLabel } from "./FormLabel";
import { CryptoAmountInput } from "./inputs/CryptoAmountInput";
import { CryptoAmountUnitInput } from "./inputs/CryptoAmountUnitInput";
import { LimitInput } from "./inputs/LimitInput";
import { OrdertypeInput } from "./inputs/OrderTypeInput";
import { SideInput } from "./inputs/SideInput";
import { StockTickerAutocomplete } from "./inputs/StockTickerAutocomplete";
import { StopInput } from "./inputs/StopInput";
import { TIFInput } from "./inputs/TIFInput";

type TradeCryptoProps = {
  initialValues?: CryptoFormInitialValues;
  onSuccess?: () => void;
  syncWithWatchlist?: boolean;
  positionCloseMode?: boolean;
  onConfirmationModalVisibilityToggle?: (value: boolean) => void,
}

export function TradeCrypto({
  initialValues,
  onSuccess,
  syncWithWatchlist = false,
  positionCloseMode = false,
  onConfirmationModalVisibilityToggle,
}: TradeCryptoProps) {

  const [messageApi, contextHolder] = message.useMessage();
  const { user } = useAuthUser();
  const isAnalyst = user && user.role === UserRole.analyst;
  const { share, canShare, setShare } = useShareTradeContext()

  const {
    symbol,
    setSymbol,
    showConfirmation,
    handleConfirmClick,
    setShowConfirmation,
    options,
    side,
    setSide,
    amountUnit,
    setAmountUnit,
    limitPrice,
    setLimitPrice,
    limitPriceLoading,
    limitRequired,
    stopPrice,
    setStopPrice,
    stopRequired,
    orderType,
    setOrderType,
    amount,
    setAmount,
    submitting,
    handleSubmitClick,
    tif,
    setTif,
    quote,
    error,
  } = useCryptoForm(initialValues);

  const estimate = useCryptoEstimate({
    amount: amount || null,
    limitPrice: limitPrice || null,
    quote: quote || null,
    unit: amountUnit || null,
  })

  const { item: selectedWatchlistItem } = useWatchListItem();

  useEffect(() => {
    if (!syncWithWatchlist || selectedWatchlistItem == null) {
      return
    }
    setSymbol(selectedWatchlistItem?.symbol)
  }, [selectedWatchlistItem, syncWithWatchlist]);

  useEffect(() => {
    if (onConfirmationModalVisibilityToggle) {
      onConfirmationModalVisibilityToggle(showConfirmation);
    }
  }, [onConfirmationModalVisibilityToggle, showConfirmation]);

  const onConfirm = async () => {
    const response = await handleConfirmClick();
    // return if response is empty
    if (!response || !response.id) {
      return;
    }
    messageApi.success("Order Placed");
    if (onSuccess !== undefined) {
      onSuccess();
    }
    if (canShare && share) {
      await api.signals.share({ order_id: response.id });
    }
  };

  return <div>
    {contextHolder}
    <Row gutter={[12, 12]}>

      <Col span={24}>
        {/* side  */}
        <FormLabel>Action</FormLabel>
        <SideInput
          options={options.sides.data}
          value={side}
          setValue={setSide}
        />
      </Col>

      <Col span={24}>
        {/* UNIT */}
        {
          options.amountUnit.data.length > 1 && (
            <FormLabel>{ sideLabels[side] } In</FormLabel>
          )
        }
        <CryptoAmountUnitInput
          hidden={options.amountUnit.data.length <= 1}
          value={amountUnit}
          symbol={symbol}
          options={options.amountUnit.data}
          setValue={setAmountUnit}
        />
      </Col>


      {!!error && (
        <Col span={24}>
          <ErrorMessage style={{ margin: 0, marginTop: 8 }} error={error} />
        </Col>
      )}

      <Col span={24}>
        {/* stock */}
        <FormLabel>Coin</FormLabel>
        <StockTickerAutocomplete
          searchCrypto={true}
          style={{ width: "100%" }}
          value={symbol}
          placeholder="Coin"
          setValue={setSymbol}
          disabled={positionCloseMode}
        />
      </Col>


      <Col span={24}>
        <FormLabel>Order Type</FormLabel>
        <OrdertypeInput
          options={options.orderTypes.data}
          value={orderType}
          setValue={setOrderType}
        />
      </Col>

      <Col span={24}>
        <FormLabel>Amount</FormLabel>
        <CryptoAmountInput
          value={amount}
          setValue={setAmount}
          symbol={symbol}
          unit={amountUnit}
        // disabled={limitPriceLoading}
        // loading={limitPriceLoading} 
        />
      </Col>

      {/* limit required */}
      {limitRequired && (
        <Col span={stopRequired ? 12 : 24}>
          <FormLabel>Limit Price</FormLabel>
          <LimitInput
            disabled={limitPriceLoading}
            loading={limitPriceLoading}
            value={limitPrice}
            setValue={setLimitPrice} />
        </Col>
      )}

      {/* stop required */}
      {stopRequired && (
        <Col span={limitRequired ? 12 : 24}>
          <FormLabel>Stop Price</FormLabel>
          <StopInput value={stopPrice} setValue={setStopPrice} />
        </Col>
      )}

      {

        <Col span={24}>
          {/* tif */}
          <FormLabel>Time In Force</FormLabel>
          <TIFInput
            options={options.timeInForces.data}
            value={tif}
            setValue={setTif}
          />
        </Col>
      }

      {!!estimate && (
        <Col span={24}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Typography.Text type="secondary">
              {estimate.label}
            </Typography.Text>
            <Typography.Text strong>
              {estimate.value}
            </Typography.Text>
          </div>
        </Col>
      )}

      {
        canShare && <Col span={24}>
          <Checkbox
            checked={share}
            onChange={(event) =>
              setShare(event.target.checked)
            }
          >
            Share
          </Checkbox>
        </Col>
      }



      <Col span={24}>
        {/* submit button  */}
        <Button
          disabled={submitting}
          loading={submitting}
          onClick={handleSubmitClick}
          style={{ marginTop: 8 }}
          block
          size="large"
          type="primary"
        >
          <SendOutlined /> Place Order
        </Button>
      </Col>
    </Row>

    <CryptoOrderConfirmationDialog
      onOk={onConfirm}
      open={showConfirmation}
      setOpen={setShowConfirmation}
      amount={amount}
      amountUnit={amountUnit}
      limitPrice={limitPrice}
      orderType={orderType}
      quote={quote}
      side={side}
      stopPrice={stopPrice}
      symbol={symbol}
      tif={tif}
    />
  </div>
}