import { useEffect, useMemo, useState } from "react";
import api from "../../api";
import { OrderType, Side, TimeInForce, Direction, AssetType } from "../../api/enums";
import {
  chooseStrikePrice,
  optionsGetQuantity,
  optionsGetSupportedDirections,
  optionsGetSupportedOrderTypes,
  optionsGetSupportedSides,
  optionsGetSupportedTIFS,
  optionsLimitPriceRequired,
  optionsStopPriceRequired,
} from "../utils/options";
import { useOptionsChain } from "./useOptionsChain";
import { useSelectedBroker } from "../../brokers/context/SelectedBrokerContext";
import { useAuthUser } from "../../auth/hooks/useAuthUser";
import { useShareTradeContext } from "../context/share-trade-context";
import { fixDecimalPlaces } from "../../shared/utils";
import { getPreferredExpirationDate } from "../../shared/get-preferred-expiration-date";

export interface OptionFormInitialValues {
  asset_type: AssetType.Options,
  stock?: string;
  direction?: Direction;
  side?: Side;
  quantity?: number;
  expirationDate?: string;
  strikePrice?: number
  limitPrice?: number
  orderType?: OrderType
  stopPrice?: number
  tif?: TimeInForce
}

// TODO: break this down into smaller hooks
// 1. useOptionsFormFieldState
// 2. useOptionsFormSubmit
// 3. useOptionsFormPrefillValues
// 4. useOptionsFormTradePreference
// 5. others hooks for sideeffects such as resetting / setting prices etc

export const useOptionsForm = (initialValues?: OptionFormInitialValues) => {
  // options form state
  const [stock, setStock] = useState<string | null>(null);
  const [expirationDate, setExpirationDate] = useState<string | null>(null);
  const [direction, setDirection] = useState<Direction | null>(Direction.Call);
  const [strikePrice, setStrikePrice] = useState<number | null>(null);
  const [orderType, setOrderType] = useState<OrderType>(OrderType.Limit);
  const [side, setSide] = useState<Side | null>(Side.Buy);
  const [tif, setTif] = useState<TimeInForce>(TimeInForce.GoodTillCancelled);
  const [limitPrice, setLimitPrice] = useState<number | null>(null);
  const [stopPrice, setStopPrice] = useState<number | null>(null);
  const [quantity, setQuantity] = useState<number | null>(null);

  // form submit state
  const [showConfirmation, setShowConfirmation] = useState<boolean>(false);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [error, setError] = useState<unknown>(null);

  // which prices are required
  const limitRequired = optionsLimitPriceRequired(orderType);
  const stopRequired = optionsStopPriceRequired(orderType);

  // options chain
  const chain = useOptionsChain({
    stock,
    expirationDate,
    direction,
    strikePrice,
    orderType,
  });

  const { user } = useAuthUser();

  const { broker } = useSelectedBroker();

  const { canShare, share } = useShareTradeContext()

  const tifOptions = useMemo(() => {
    return optionsGetSupportedTIFS(broker?.account?.type, orderType)
  }, [broker?.account?.type, orderType]);

  const showTif = orderType !== OrderType.Market;

  useEffect(() => {
    if (!initialValues) {
      return;
    }

    if (initialValues.orderType) {
      setOrderType(initialValues.orderType);
    }

    if (initialValues.stock) {
      setStock(initialValues.stock);
    }

    if (initialValues.direction) {
      setDirection(initialValues.direction);
    }

    if (initialValues.side) {
      setSide(initialValues.side);
    }

    if (initialValues.quantity) {
      setQuantity(initialValues.quantity);
    }

    if (initialValues.expirationDate) {
      setExpirationDate(initialValues.expirationDate);
    }

    if (initialValues.strikePrice) {
      setStrikePrice(initialValues.strikePrice);
    }

    if (initialValues.limitPrice) {
      setLimitPrice(fixDecimalPlaces(initialValues.limitPrice));
    }

    if (initialValues.stopPrice) {
      setStopPrice(initialValues.stopPrice);
    }

    if (initialValues.tif) {
      setTif(initialValues.tif);
    }

  
  }, [initialValues]);

  // set quantity and stop price according 
  // to trade preference 
  useEffect(() => {
    if (user?.trade_preference) {
      if (initialValues?.quantity === undefined) {
        setQuantity(user.trade_preference.default_quantity);
      }
      setStopPrice(user.trade_preference.stop_loss);
    }
  }, [user?.trade_preference, initialValues?.quantity]);

  // set order type according to trade preference
  useEffect(() => {
    if (
      initialValues?.orderType === undefined &&
      user?.trade_preference?.order_type_preference !== undefined &&
      user?.trade_preference?.order_type_preference !== null
    ) {
      setOrderType(user.trade_preference.order_type_preference);
    }
  }, [user?.trade_preference?.order_type_preference, initialValues?.orderType]);

  // set direction according to trade preference
  useEffect(() => {
    if (initialValues?.direction === undefined  &&
        !!(user?.trade_preference?.options_direction_preference)
    ) {
      setDirection(user.trade_preference.options_direction_preference);
    }
  }, [user?.trade_preference?.options_direction_preference, initialValues?.direction]);

  // set expiry date according to trade preference
  useEffect(() => {
    if (
      initialValues?.expirationDate === undefined &&
      (chain.expirationDates.data?.length || 0) > 0
    ) {
      const preferedDate = getPreferredExpirationDate(
        chain.expirationDates.data || [],
        user?.trade_preference?.expiry_preference || null,
        user?.trade_preference?.manual_expiry_date || null,
      )
      console.log(preferedDate);
      setExpirationDate(preferedDate);
    }
  }, [
    user?.trade_preference?.expiry_preference,
    user?.trade_preference?.manual_expiry_date,
    initialValues?.expirationDate,
    chain.expirationDates.data
  ]);

  // set quantity according to budget set in trade preference
  useEffect(()=>{
    const budget = user?.trade_preference?.max_amount || null;
    const prefilledQuantity = initialValues?.quantity || null;

    if (budget !== null
      && prefilledQuantity === null
      && limitPrice !== null
      && limitPrice > 0
    ) {
      const quantity = optionsGetQuantity(limitPrice, budget);
      setQuantity(quantity);
    }
  }, [user?.trade_preference, limitPrice, initialValues]);

  // perfill time in force from trade preference
  useEffect(() => {
    if (user?.trade_preference?.time_in_force !== undefined
      && tifOptions.includes(user?.trade_preference?.time_in_force)
    ) {
      setTif(user?.trade_preference?.time_in_force);
    }
  }, [user?.trade_preference, tifOptions, initialValues]);

  // // select first expiration date 
  // useEffect(() => {
  //   if (chain.expirationDates.data 
  //     && chain.expirationDates.data.length > 0
  //   ) {
  //     if (expirationDate && chain.expirationDates.data.includes(expirationDate)) {
  //       return;
  //     }
  //     setExpirationDate(chain.expirationDates.data[0]);
  //   }

  // }, [chain.expirationDates.data, expirationDate]);

  // select first strike price
  useEffect(() => {
    if (
      strikePrice == null &&
      chain.strikePrices.data &&
      chain.strikePrices.data.length > 0 &&
      initialValues?.strikePrice === undefined
    ) {
      const chosenStrikePrice = chooseStrikePrice(
        chain.strikePrices.data,
        chain.assetLastPriceQuote || null,
        user?.trade_preference?.strike_preference || null,
      )
      if (chosenStrikePrice !== null) {
        setStrikePrice(chosenStrikePrice);
      }
    }
  }, [chain.assetLastPriceQuote, chain.strikePrices.data, strikePrice, user?.trade_preference?.strike_preference, initialValues?.strikePrice]);

  // reset strike price
  useEffect(() => {
    if (
      strikePrice &&
      chain.strikePrices.data &&
      chain.strikePrices.data.length > 0 &&
      !(chain.strikePrices.data.includes(strikePrice))  
    ) {
      setStrikePrice(null);
    }
  }, [chain.strikePrices.data, strikePrice]);

  // prefill limit price with mid price
  useEffect(() => {
    if (initialValues?.limitPrice !== undefined) {
      return
    }
    let price = chain.optionsQuote.data?.price !== undefined
      ? fixDecimalPlaces(chain.optionsQuote.data.price)
      : null
    setLimitPrice(price);
  }, [chain.optionsQuote.data, initialValues?.limitPrice]);

  // reset limit price
  useEffect(() => {
    if (!limitRequired) {
      setLimitPrice(null);
    }
  }, [limitRequired]);

  // reset stop price
  useEffect(() => {
    if (!stopRequired) {
      setStopPrice(null);
    }
  }, [stopRequired]);

  useEffect(() => {
    if (tifOptions.length === 1 && tif !== tifOptions[0]) {
      setTif(tifOptions[0]);
    } 
  }, [tifOptions, tif]);

  const notOptionable = !chain.optionable.loading && !chain.optionable;

  const validate = () => {
    setError(null);
    if (!side) {
      setError("Side is required");
      return false;
    }
    if (!stock) {
      setError("Stock is required");
      return false;
    }
    if (chain.optionable.data !== true) {
      setError("Stock is not optionable");
      return false;
    }
    if (!direction) {
      setError("Direction is required");
      return false;
    }
    if (!expirationDate) {
      setError("Expiration Date is required");
      return false;
    }
    if (!strikePrice) {
      setError("Strike Price is required");
      return false;
    }
    if (!orderType) {
      setError("Order Type is required");
      return false;
    }
    if (!quantity) {
      setError("Quantity is required");
      return false;
    }
    if (limitRequired && !limitPrice) {
      setError("Limit Price is required");
      return false;
    }
    if (stopRequired && !stopPrice) {
      setError("Stop Price is required");
      return false;
    }
    if (!tif) {
      setError("Time in force is required");
      return false;
    }
    return true;
  };

  // show confimration dialog when submit button is clicked
  const handleSubmitClick = () => {
    const valid = validate();
    if (!valid) {
      return;
    }
    setShowConfirmation(true);
  };

  // submit the form when confirm button is clicked
  const handleConfirmClick = async () => {
    // hide confirmation dialog
    setShowConfirmation(false);

    const valid = validate();
    if (!valid) {
      return;
    }

    setSubmitting(true);
    try {
      const requestPayload = {
        direction: direction!,
        expiration_date: expirationDate!,
        order_type: orderType,
        quantity: quantity!,
        side: side!,
        strike_price: strikePrice!.toString(),
        ticker_symbol: stock!,
        time_in_force: tif,
        limit_price: limitPrice ? limitPrice.toString() : undefined,
        stop_price: stopPrice ? stopPrice.toString() : undefined,
        share: canShare && share,
      };
      return await api.orders.placeOptionsOrder(requestPayload);
    } catch (e) {
      setError(e);
    } finally {
      setSubmitting(false);
    }
  };

  return {
    // getters and setters for form fields
    stock,
    setStock,
    expirationDate,
    setExpirationDate,
    direction,
    setDirection,
    strikePrice,
    setStrikePrice,
    orderType,
    setOrderType,
    side,
    setSide,
    tif,
    setTif,
    limitPrice,
    setLimitPrice,
    stopPrice,
    setStopPrice,
    quantity,
    setQuantity,

    // rules for optional fields
    limitRequired,
    stopRequired,
    showTif,

    // options for select
    // not all values of enums are selectable
    options: {
      strikePrices: chain.strikePrices,
      expirationDates: chain.expirationDates,
      orderTypes: {
        data: optionsGetSupportedOrderTypes(broker?.account?.type),
        loading: false,
      },
      directions: {
        data: optionsGetSupportedDirections(broker?.account?.type),
        loading: false,
      },
      sides: {
        data: optionsGetSupportedSides(broker?.account?.type),
        loading: false,
      },
      timeInForces: {
        data: optionsGetSupportedTIFS(broker?.account?.type, orderType),
        loading: false,
      },
    },

    optionsQuote: chain.optionsQuote,

    // stock does not support options trading
    notOptionable,

    // form submission & confirmation state
    showConfirmation,
    setShowConfirmation,
    handleSubmitClick,
    handleConfirmClick,
    submitting,
    error,
  };
};
