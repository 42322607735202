export enum AssetType {
  Stocks = 'stocks',
  Options = 'options',
  Crypto = 'crypto'
}

export enum Direction {
  Call = 'call',
  Put = 'put'
}

export enum CryptoAmountUnit {
  USD = 'usd',
  Coin = 'coin'
}

export enum Side {
  Buy = 'BUY',
  Sell = 'SELL',
  // Short = 'SHORT'
}

export enum OrderType {
  Market = 'MKT',
  Limit = 'LMT',
  Stop = 'STP',
  StopLimit = 'STP LMT',
  StopTrail = 'STP TRAIL',
}

export enum TimeInForce {
  Day = 'DAY',
  GoodTillCancelled = 'GTC',
  ImmediateOrCancelled = 'IOC',
}

export enum TrailType {
  Dollar = 'DOLLAR',
  Percentage = 'PERCENTAGE'
}

export enum BillingIntervalUnit {
  Week = 'week',
  Month = 'month',
  Year = 'year',
}

export enum SortOrder {
  Asc = 'asc',
  Desc = 'desc',
}

export enum MembershipStatus {
  Draft = 'draft',
  Active = 'active',
  Completed = 'completed',
  Cancelled = 'cancelled',
}

export enum UserRole {
  trader = "trader",
  admin = "admin",
  analyst = "analyst",
}

export enum ChatroomMemberRole {
  user = "user",
  admin = "admin",
  analyst = "analyst"
}

export enum ContactReason {
  BugReport = 'bug_report',
  FeatureRequest = 'feature_request',
  Feedback = 'feedback',
  Support = 'support',
  Other = 'other',
}

export enum SignalSource {
  Order = 'order',
  Chat = 'chat',
}

export enum AnalystSignupRequestStatus {
  Pending = 'pending',
  Approved = 'approved',
  Rejected = 'rejected'
}

export enum ReferralStatus {
  Pending = 'pending',
  Completed = 'completed',
  Rewarded = 'rewarded'
}

export enum AutotradeOptionsExpiryType {
  Closest = 'closest_date',
  FridayExpiry = 'friday',
  NextWeekFriday = 'next_week_friday',
  EomFriday = 'eom_friday',
  Manual = 'manual',
}

export enum AutotradeStrikePreference {
  InTheMoney = 'in_the_money',
  OutTheMoney = 'out_the_money',
  Closest = 'closest'
}

export enum BotAssetType {
  stocks = 'stocks',
  options = 'options',
  crypto = 'crypto',
}

export enum BotAction {
  call = 'CALL',
  put = 'PUT',
  close = 'CLOSE',
  buy = 'BUY',
  sell = 'SELL',
}

export enum BotOrderType {
  limit = 'LMT',
  market = 'MKT',
  stop = 'STP',
  stop_limit = 'STP LMT',
}

export enum BotEnforce {
  day = 'DAY',
  gtc = 'GTC',
}

export enum StrikePreference {
  InTheMoney = 'in_the_money',
  OutTheMoney = 'out_the_money',
  Closest = 'closest'
}

export enum OptionsExpiryPreference {
  Closest = 'closest_date',
  FridayExpiry = 'friday',
  NextWeekFriday = 'next_week_friday',
  EomFriday = 'eom_friday',
  Manual = 'manual',
}

export enum CandeFiMembershipStatus {
  Active = 'active',
  PastDue = 'past_due',
}

export enum SignalProviderType {
  Individual = 'individual',
  Community = 'community'
}

export enum CommunityInviteStatus {
  pending = 'pending',
  accepted = 'accepted',
  rejected = 'rejected'
}