import { Button, Result } from "antd";
import { useState } from "react";
import { useAuthUserSignalProviders } from "../../hooks/useAuthUserSignalProviders";
import setupWizard from '../../../shared/images/setup_wizard.svg';
import { CreateSignalProviderModal } from "./CreateSignalProviderModal";

export function SignalProviderIntro() {

  const [createFormOpen, setCreateFormOpen] = useState(false);

  const { refresh } = useAuthUserSignalProviders();

  return <div>
    <Result
      style={{ maxWidth: 600, margin: '0 auto' }}
      title="Create Your Signal Provider Profile"
      icon={<img style={{ maxWidth: 300 }} src={setupWizard} />}
      subTitle="
      Setup your Analyst profile and create subscription plans. 
      Become an independent business owner supported by our cutting-edge technology and innovative.
      "
      extra={<Button onClick={() => setCreateFormOpen(true)} size="large" type="primary">
        Get Started
      </Button>} />
    <CreateSignalProviderModal
      open={createFormOpen}
      onClose={() => setCreateFormOpen(false)}
      onSuccess={refresh} />
  </div>;
}
