import { InputNumber } from "antd";

export const CryptoPriceInput = ({
  value,
  setValue,
  defaultValue,
}: {
  value: number | null;
  setValue: (value: number | null) => void;
  defaultValue?: number
}) => {
  return (
    <InputNumber
      defaultValue={defaultValue}
      style={{ display: "block", width: "100%", minWidth: 100 }}
      placeholder="Price"
      value={value}
      onChange={(value) => setValue(value)}
      min={0}
    />
  );
};
