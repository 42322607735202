import useSWR from "swr";
import api from "../../api";
import { Card } from "antd";
import { BrokerType } from "../entities/broker-account.entity";
import WebullAccountDetails from "./WebullAccountDetails";
import RobinhoodAccountDetails from "./RobinhoodAccountDetails";
import { ErrorMessage } from "../../shared/components/ErrorMessage";
import { WebullPaperAccountDetails } from "./WebullPaperAccountDetails";
import WebullPayAccountDetails from "./WebullPayAccountDetails";

const AccountDetails = () => {
  const { data, isLoading, error } = useSWR("/account-info", () =>
    api.account.getAccountInfo()
  );

  return (
    <Card loading={isLoading} size="small" bodyStyle={{ paddingRight: 0, paddingLeft: 0 }}>
      <div style={{ paddingRight: 8, paddingLeft: 8 }}>
      {
        error && <ErrorMessage error={error}/>
      }
      {
        data && data.type === BrokerType.webull && data.info !== null && (
          <WebullAccountDetails account={data.info} />
        )
      }
      {
        data && data.type === BrokerType.webull && data.paper_info !== null && (
          <WebullPaperAccountDetails info={data.paper_info} />
        )
      }
      {
        data && data.type === BrokerType.robinhood && (
          <RobinhoodAccountDetails info={data.info} />
        )
      }
      {
        data && data.type === BrokerType.webull_pay && (
          <WebullPayAccountDetails info={data.info} />
        )
      }
      </div>
    </Card>
  );
};

export default AccountDetails;
