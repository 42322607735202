import { Button, message, Spin } from "antd";
import { useCallback, useEffect, useLayoutEffect, useRef, useState } from "react";
import { ErrorMessage } from "../../../shared/components/ErrorMessage";
import { MFAImagePuzzle } from "../../../webull_connect/components/MFAImagePuzzle";
import { useWebullPayConnectContext } from "../../contexts/WebullPayConnectContext"

export const MFAImage = () => {
  const { mfaImage, checkMFAImage } = useWebullPayConnectContext();
  const containerRef = useRef<HTMLDivElement>(null);
  const [xPos, setXPos] = useState(0);
  const [containerWidth, setContainerWidth] = useState(0);
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState<unknown>(null);
  const [messageApi, contextHolder] = message.useMessage();

  const calculteContainerWidth = useCallback(() => {
    if (containerRef.current !== null) {
      setContainerWidth(containerRef.current.offsetWidth);
    }
  }, [])

  const submit = async () => {
    setSubmitting(true);
    try {
      const valid = await checkMFAImage(xPos);
      if (!valid) {
        messageApi.open({
          type: "error",
          content: 'Try again'
        });
      }
    } catch (e) {
      setError(e);
    } finally {
      setSubmitting(false);
    }
  }

  useLayoutEffect(() => {
    calculteContainerWidth();
  }, []);

  useEffect(() => {
    window.addEventListener('resize', calculteContainerWidth);
    return () => window.removeEventListener('resize', calculteContainerWidth);
  }, [])

  return <div
    ref={containerRef} 
    style={{ minWidth: '100%', maxWidth: '100%' }}
  >
    { contextHolder }
    {
      mfaImage === null && <div style={{ margin: '1rem 0', textAlign: 'center' }}>
        <Spin size="large" />
      </div>
    }
    {
      mfaImage !== null && <>
        <ErrorMessage error={error} />
        <MFAImagePuzzle 
          image={mfaImage}
          setXPosPerc={setXPos}
          containerWidth={containerWidth} />
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <span></span>
          <Button 
            onClick={submit}
            loading={submitting} size="large" type="primary">
            Next
          </Button>
        </div>
      </>
    }
  </div>
}