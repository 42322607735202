import useSWR from 'swr';
import api from '../../api';

export function useSignalProviderOwnerReferralCode(signalProviderUsername: string) {

  const {
    data,
    isLoading
  } = useSWR(
    ['/api/referrals/signal-provider', signalProviderUsername],
    () => {
      return api.referrals.getSignalProviderOwnerReferralCode(signalProviderUsername)
    }
  )

  return {
    data: data || null,
    isLoading,
  }
}