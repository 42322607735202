import { Modal } from "antd";
import { useEffect, useState } from "react";
import { InviteForm } from "./InviteForm";

export function InviteModal({
  open, onClose, onSuccess, signalProviderId,
}: {
  open: boolean;
  onClose: () => void;
  onSuccess: () => void | Promise<void>;
  signalProviderId: number;
}) {
  // key resets the form state
  const [key, setKey] = useState(1);

  useEffect(() => {
    if (open) {
      setKey(k => k + 1);
    }
  }, [open]);

  return <Modal
    footer={null}
    title="Invite Analyst"
    open={open}
    onCancel={onClose}
  >
    <InviteForm
      key={key}
      onSuccess={onSuccess}
      signalProviderId={signalProviderId} />
  </Modal>;
}
