import { useState } from "react";
import api from "../../api";
import { OrderType, TimeInForce } from "../../api/enums";
import { BrokerOrder, ReplaceStocksOrderRequest } from "../../api/orders";

export const useEditStockOrderForm = ({
  order,
  onSuccess,
  onError,
} : {
  order: BrokerOrder,
  onSuccess?: () => void,
  onError?: (e: any) => void,
}) => {

  const limitEditable = order.order_type !== null && [
    OrderType.Limit, OrderType.StopLimit
  ].includes(order.order_type);

  const [tif, setTif] = useState<TimeInForce>(order.time_in_force || TimeInForce.GoodTillCancelled);
  const [quantity, setQuantity] = useState<number>(order.quantity || 0);
  const [limitPrice, setLimitPrice] = useState<number>(order.limit_price || 0);

  const [error, setError] = useState<any>(null);
  const [submitting, setSubmitting] = useState<boolean>(false);

  const submit = async () => {

    try {

      setError(null);
      setSubmitting(true);
      const request : ReplaceStocksOrderRequest = {
        time_in_force: tif,
        quantity: quantity,
        limit_price: limitEditable ? limitPrice : undefined,
        order_type: order.order_type!,
      }
      await api.orders.replaceStocksOrder(order.broker_order_id, request);
      if (onSuccess) {
        onSuccess();
      }

    } catch (e) {

      setError(e);
      if (onError) {
        onError(e);
      }

    } finally {

      setSubmitting(false);

    }
  }

  return {
    limitEditable,
    tif, setTif,
    limitPrice, setLimitPrice,
    quantity, setQuantity,
    error, submitting,
    submit,
  }
}