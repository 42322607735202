import { Modal, List, Avatar } from "antd";
import { Link } from "react-router-dom";
import webullIcon from '../images/webull_icon.png';
import robinhoodIcon from '../images/robinhood_icon.png';

export const ConnectBrokerModal = ({
  open,
  setOpen
} : {
  open: boolean;
  setOpen: (open : boolean) => void
}) => {
  return <Modal 
    open={open} 
    footer={null} 
    title="Add Broker Account"
    onCancel={() => setOpen(false)}
    centered
  > 
    <List>
      <List.Item>
        <Link to={'/webull-connect'} style={{ width: '100%' }}>
          <List.Item.Meta
            avatar={
              <Avatar
                src={webullIcon}
                size='large'
              />
            }
            title='Webull'
            description='Connect webull account'
          />
        </Link>
      </List.Item>
      <List.Item>
        <Link to='/robinhood-connect' style={{ width: '100%' }}>
          <List.Item.Meta
            avatar={
              <Avatar
                src={robinhoodIcon}
                size='large'
              />
            }
            title='Robinhood'
            description='Connect robinhood account'
          />
        </Link>
      </List.Item>
      <List.Item>
        <Link to='/webull-pay-connect' style={{ width: '100%' }}>
          <List.Item.Meta
            avatar={
              <Avatar
                src={webullIcon}
                size='large'
              />
            }
            title='Webull Pay'
            description='Connect webull pay crypto account'
          />
        </Link>
      </List.Item>
    </List>
  </Modal>
}