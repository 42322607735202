/**
 * Generic event from service worker
 */

export type CandeFiSWEvent<T extends string, D> = {
  type: T;
  data: D;
  isCandefiServiceWorker: true;
};

export function isCandefiSWEvent(event: unknown): event is CandeFiSWEvent<any, any> {
  if (typeof event !== 'object') {
    return false;
  }
  return (event as Object).hasOwnProperty('isCandefiServiceWorker')
    && (event as CandeFiSWEvent<any, any>).isCandefiServiceWorker === true;
}
