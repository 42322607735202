import { Button, Input, Modal } from "antd"
import { useState } from "react"
import { ErrorMessage } from "../../shared/components/ErrorMessage"
import { StockTickerAutocomplete } from "../../trade/components/inputs/StockTickerAutocomplete"
import { useWatchListItem } from "../context/watchlist-item-context"
import { 
  PlusOutlined,
} from '@ant-design/icons';
import { useWatchList } from "../context/watchlist-context"
import api from "../../api"

export const CreateWatchlistModal = ({
  open,
  setOpen,
  onSuccess,
} : {
  open: boolean,
  setOpen: (open : boolean) => void,
  onSuccess?: () => void
}) => {
  const { lists } = useWatchList();

  const [name, setName] = useState<string>('');
  const [processing, setProcessing] = useState(false);
  const [error, setError] = useState<any>(null);

  const createWatchlist = async () => {

    try {

      setError(null);
      setProcessing(true);
      if (!name) {
        throw new Error('please enter name');
      }
      
      await api.watchlistV2.createWatchlist({ name });
      lists.reload();

      if (onSuccess) {
        onSuccess();
      }

    } catch (e) {

      setError(e);

    } finally {

      setProcessing(false);

    }
  }

  return <Modal
    title="Create New Watchlist"
    open={open}
    onCancel={() => setOpen(false)}
    footer={null}
  >
    <div style={{
        display: 'flex', 
        flexDirection: 'column', 
        gap: 16 
    }}>
      {
        error && <ErrorMessage error={error} />
      }
      <Input
        style={{ width: "100%" }}
        value={name}
        placeholder="Name"
        onChange={(e) => setName(e.target.value)}
      />
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button 
          onClick={createWatchlist} 
          type='primary'
          disabled={processing}
          icon={<PlusOutlined/>}
        >
          Create
        </Button>
      </div>
    </div>
  </Modal>
}