import React, { useRef, useEffect, useState, forwardRef, RefObject } from 'react';

interface ScrollableContainerProps extends  Omit<React.HTMLAttributes<HTMLDivElement>, 'onScroll' | 'ref'> {
  onBottomReached?: () => void;
  onTopReached?: () => void;
  children: React.ReactElement
  dataLength: number,
}

const ScrollableContainer = forwardRef<HTMLDivElement, ScrollableContainerProps>(({
    children, 
    onTopReached,
    onBottomReached,
    dataLength,
    style = { overflowY: 'auto' },
    ...props
  
}, ref) => {

  // internalRef in case parent doesn't provide ref
  const internalRef = useRef<HTMLDivElement>(null);

  // containerRef
  // either ref provided by parent or internal ref
  // we only use object refs callback refs are unsupported
  const containerRef = ref && typeof ref === 'object'
    ? ref
    : internalRef;

  const [previousScrollHeight, setPreviousScrollHeight] = useState(0);

  // reset scroll position when new elements are added to the list
  useEffect(() => {

    if (containerRef.current) {

      containerRef.current.scrollBy({
        top: containerRef.current.scrollHeight - previousScrollHeight,
      })

    }
  }, [dataLength]);
  
  // on mount
  // - scroll to bottom
  // - trigger handle scroll
  useEffect(() => {

    if (containerRef.current) {

      containerRef.current.scrollTop = containerRef.current.scrollHeight;
      handleScroll();

    };
  }, []);


  const handleScroll = () => {

    if (containerRef.current) {

      const { scrollTop, scrollHeight, clientHeight } = containerRef.current;

      setPreviousScrollHeight(containerRef.current.scrollHeight);

      // reached top
      if (scrollTop === 0) {

        if (onTopReached !== undefined) {
          onTopReached();
        }
  
      // reached bottom
      } else if (scrollTop + clientHeight === scrollHeight) {

        if (onBottomReached !== undefined) {
          onBottomReached();
        }

      }
    }
  };

  return (
    <div
      ref={containerRef}
      onScroll={handleScroll}
      style={style}
      {...props}
    >
      { children }
    </div>
  );
})


export default ScrollableContainer;
