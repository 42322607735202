import { Modal } from "antd";
import { EditSignalProviderForm, EditSignalProviderFormProps } from "./EditSignalProviderForm";

export type EditSignalProviderPopupProps = EditSignalProviderFormProps & {
  open: boolean
  onClose: () => void,
}

export function EditSignalProviderPopup({ open, onClose, ...props } : EditSignalProviderPopupProps) {
  return <Modal open={open} onCancel={onClose} footer={null} title={`Edit Signal Provider`}>
    <EditSignalProviderForm {...props} />
  </Modal>
}