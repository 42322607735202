import { Button, Card, Typography } from "antd";
import { Link } from "react-router-dom";
import useSWR from 'swr';
import api from "../../api";
import image from './undraw_pair_programming_re_or4x.svg';
import Flex from "../../shared/components/Flex";

const { Title, Paragraph } = Typography;

export const ReferAndEarnCard = () => {

  const {
    isLoading,
    data: info
  } = useSWR('/referrals/rewards-info/basic', async () => {
    const info = await api.referrals.getRewardsInfo();
    return info;
  });

  return <Card loading={isLoading}>
    <Flex flexDirection="column" md={{ flexDirection: 'row' }} gap={16}>
      <Flex>
        <img
          src={image}
          style={{ width: 300, height: 'auto' }}
        />
      </Flex>
      <Flex flexDirection="column">
        <Title level={3}>Refer and earn!</Title>
        {
          !!info && <>
            <Paragraph>
              Refer an Analyst, both you and Analyst will be rewarded with {info.analyst_free_coins} CandeFi Coins. If you refer a Trader, you each will receive {info.trader_free_coins} CandeFi Coins.
            </Paragraph>

          </>
        }
        <Link to='/app/account/referral'>
          <Button type="primary" size="large">
            Learn More
          </Button>
        </Link>
      </Flex>
    </Flex>
  </Card>
};
