import { ReactNode } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { UserRole } from "../../api/enums";
import { reactRouterLocationToString } from "../../shared/utils";
import { useAuthUser } from "../hooks/useAuthUser";

export const Guest = ({ children }: { children: ReactNode }) => {
  const location = useLocation();

  const { initialized, user } = useAuthUser();

  // when user info is being fetched
  // render nothing
  if (!initialized) {
    return null;
  }

  
  const isGuest = user === null;
  const userIsAnalystOrTrader = !isGuest && [
    UserRole.analyst, UserRole.trader
  ].includes(user.role);

  // redundant but easy to read
  if (!isGuest && userIsAnalystOrTrader) {

    const redirectUrl = location?.state?.from
      ? reactRouterLocationToString(location!.state!.from)
      : '/app/dashboard';

    return (
      <Navigate to={redirectUrl} />
    );
  }

  // show component
  return <>{children}</>;
};
