import { InputNumber, Spin } from "antd"
import { LoadingOutlined } from '@ant-design/icons';
import { CryptoAmountUnit } from "../../../api/enums";
import { displayCryptoSymbol } from "../../utils/crypto";

const antIcon = <LoadingOutlined style={{ fontSize: 12 }} spin />;


export const CryptoAmountInput = ({
  value,
  setValue,
  unit,
  symbol,
  disabled = false,
  loading = false,
} : {
  value: number | null,
  setValue: (value : number | null) => void,
  unit?: CryptoAmountUnit,
  disabled?: boolean,
  loading?: boolean
  symbol?: string | null,
}) => {
  
  const addonBefore = unit 
    ? {
      [CryptoAmountUnit.Coin]: symbol ? displayCryptoSymbol(symbol) : 'Coin',
      [CryptoAmountUnit.USD]: '$',
    }[unit] : undefined;
  
  return <InputNumber
    addonBefore={addonBefore}
    style={{ display: 'block', width: '100%' }}
    placeholder="Amount"
    value={value}
    onChange={(value) => setValue(value)}
    disabled={disabled}
    addonAfter={
      loading 
        ? <Spin indicator={antIcon} />
        : undefined
    }
  />
}