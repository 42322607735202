import { useSyncFCMToken } from "../hooks/use-sync-fcm-token";

/**
 * Syncs FCM token with server
 * 
 */
export function FCMTokenSyncer() {
    useSyncFCMToken();

    return null;
}