export const CANDEFI_SW_AUTH_USER_EVENT = 'AUTH_USER';

/**
 * UI sends this event to service worker to share current user id
 * 
 */
export type CandeFiSWAuthUserEvent = {
  type: typeof CANDEFI_SW_AUTH_USER_EVENT,
  userId: number | null
};

export function isCandefiSWAuthUserEvent(event: unknown): event is CandeFiSWAuthUserEvent {
  if (typeof event !== 'object') {
    return false;
  }
  return (event as Record<string, any>).type === CANDEFI_SW_AUTH_USER_EVENT;
}
