import { Button, Form } from "antd";
import { CreateCommunityInviteRequest } from "../../../api/signal-providers";
import api from "../../../api";
import { useState } from "react";
import { ErrorMessage } from "../../../shared/components/ErrorMessage";
import { UsernameAutocomplete } from "../../../trade/components/inputs/UsernameAutocomplete";
import { UserRole } from "../../../api/enums";

export function InviteForm({ onSuccess, signalProviderId }: { signalProviderId: number; onSuccess: () => void | Promise<void>; }) {
  const [error, setError] = useState<unknown>(null);
  const [submitting, setSubmitting] = useState(false);

  const onFinish = async (data: CreateCommunityInviteRequest) => {
    try {

      setError(null);
      setSubmitting(true);
      await api.signalProviders.sendInvite(signalProviderId, data);
      await onSuccess();

    } catch (e) {

      setError(e);

    } finally {

      setSubmitting(false);

    }
  };

  return <div>
    {/*
          For some reason react is complaining about multiple children even for div.
          This works everywhere else but not here, not sure why.
        */}
    <>
      {error && (
        <div style={{ marginTop: 16 }}>
          <ErrorMessage error={error} />
        </div>
      )}
      <Form<CreateCommunityInviteRequest>
        name="invite_team_member"
        style={{ width: '100%', marginTop: 16 }}
        layout="vertical"
        onFinish={onFinish}
        autoComplete="off"
      >
        <Form.Item
          label="Analyst Username"
          name="username"
          rules={[{ required: true, message: "username of the analyst you want to invite" }]}
        >
          {/* @ts-ignore */}
          <UsernameAutocomplete 
            placeholder="Start Typing To Search" 
            role={UserRole.analyst} 
          />
        </Form.Item>

        <Form.Item>
          <Button
            block
            size="large"
            loading={submitting}
            disabled={submitting}
            htmlType="submit"
            type="primary"
          >
            Send Invite
          </Button>
        </Form.Item>
      </Form>
    </>
  </div>;
}
