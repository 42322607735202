import { directionLabelsShort } from "../../api/enum-labels";
import { AssetType, CryptoAmountUnit } from "../../api/enums";
import { DBOrder, BrokerOrder } from "../../api/orders";
import { BrokerType } from "../../brokers/entities/broker-account.entity";
import { formatMoney, formatMoneyAuto, formatNumberAuto, formatUTCDate } from "../../shared/utils";
import { displayCryptoSymbol } from "../../trade/utils/crypto";

export const getFormattedQuantity = (order : Pick<BrokerOrder, 'asset_type' | 'quantity' | 'crypto_amount_unit' | 'crypto_amount' | 'side' | 'stock'>) => {
  if ([AssetType.Stocks, AssetType.Options].includes(order.asset_type)) {
    return order.quantity ? `${order.quantity}` : '';
  }
  if (order.asset_type === AssetType.Crypto) {
    if (order.crypto_amount_unit === CryptoAmountUnit.Coin) {
      return `${order.crypto_amount} ${displayCryptoSymbol(order.stock || '')}`;
    }
    if (order.crypto_amount_unit === CryptoAmountUnit.USD) {
      return `${formatMoneyAuto(order.crypto_amount || 0)}`;
    }
  }
  return '';
}

export const getFormattedFilledQuantity = (order : Pick<BrokerOrder, 'crypto_filled_quantity' | 'asset_type' | 'crypto_amount_unit' | 'side' | 'stock' | 'filled_quantity'>) => {
  if ([AssetType.Stocks, AssetType.Options].includes(order.asset_type)) {
    return order.filled_quantity ? `${order.filled_quantity}` : '';
  }
  if (order.asset_type === AssetType.Crypto && order.crypto_filled_quantity) {
    return `${formatNumberAuto(order.crypto_filled_quantity)} ${displayCryptoSymbol(order.stock || '')}`;
  }
  return '';
}

export const getFormattedAverageFilledPrice = (order : Pick<BrokerOrder, 'average_price' | 'asset_type'>) => {
  if ([AssetType.Stocks, AssetType.Options].includes(order.asset_type)) {
    return order.average_price ? formatMoney(order.average_price, 3) : '';
  }
  if (order.asset_type === AssetType.Crypto && order.average_price) {
    return formatMoneyAuto(order.average_price);
  }
  return '';
}


export const getLimitPrice = (order: BrokerOrder | DBOrder) => {
  if (order.asset_type === AssetType.Crypto) {
    return order.crypto_limit_price;
  }
  return order.limit_price;
}

export const getStopPrice = (order: BrokerOrder | DBOrder) => {
  if (order.asset_type === AssetType.Crypto) {
    return order.crypto_stop_price;
  }
  return order.stop_price;
}

export const getFormattedSymbol = (order: BrokerOrder | DBOrder) => {
  if (order.asset_type === AssetType.Stocks) {
    return order.stock;
  }
  if (order.asset_type === AssetType.Options) {
    const stock = order.stock;
    if (!order.strike_price || 
        !order.expiration_date || 
        !order.direction) {
      return '';
    }
    const strike = formatNumberAuto(order.strike_price);
    const expiration = formatUTCDate(order.expiration_date, 'MM/dd');
    const direction = directionLabelsShort[order.direction];
    return `${stock} ${strike}${direction} ${expiration}`
  }
  if (order.asset_type === AssetType.Crypto) {
    return displayCryptoSymbol(order.stock || '');
  }
  return '';
}

export const getFormattedApproxTotal = (order: BrokerOrder) => {
  if (order.asset_type === AssetType.Stocks) {
    if (typeof order.filled_quantity === 'number' && typeof order.average_price === 'number') {
      return formatMoney(order.filled_quantity * order.average_price, 3);
    }
    return null;
  }

  if (order.asset_type === AssetType.Options) {
    if (typeof order.filled_quantity === 'number' && typeof order.average_price === 'number') {
      return formatMoney(order.filled_quantity * order.average_price * 100, 3);
    }
    return null;
  }

  if (order.asset_type === AssetType.Crypto && order.crypto_filled_amount) {
    return formatMoneyAuto(order.crypto_filled_amount);
  }

  return null;
}

export const isReplaceOrderSupported = (brokerType: BrokerType | null, paper: boolean) => {
  if (!brokerType) {
    return false;
  }
  const supportList :  Record<BrokerType, boolean> = {
    [BrokerType.robinhood]: false,
    [BrokerType.tda]: false,
    [BrokerType.webull]: true,
    [BrokerType.webull_pay]: false,
  }
  const supports = supportList[brokerType];
  // paper accounts do support replace but it does not work at the moment
  if (brokerType === BrokerType.webull && paper) {
    return false;
  }
  return supports;
}