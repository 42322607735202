import { Button, Card, Typography } from "antd";
import { Link } from "react-router-dom";
import Flex from "../../shared/components/Flex";
import image from './undraw_printing_invoices_-5-r4r.svg';

const { Title, Paragraph } = Typography;

export const BecomeAnalystCallout = () => {
  return <Card>
    <Flex flexDirection="column-reverse" md={{ flexDirection: 'row' }} gap={16}>
      <Flex flexDirection="column">
        <Title level={3}>Monetize Your Trading Expertise</Title>
        <Paragraph>
          Turn your trading skills into income. Become an analyst, offer subscription plans, and let others follow your trades to earn while you trade      
        </Paragraph>
        <Link to='/app/account/analyst-application'>
          <Button type="primary" size="large">
            Learn More
          </Button>
        </Link>      
      </Flex>
      <Flex>
        <img 
          src={image}
          style={{ width: 300, height: 'auto' }}
        />
      </Flex>
    </Flex>
  </Card>
};