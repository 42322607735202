import { useEffect } from "react"
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { ChatroomSubscription } from "../chatroom-subscription";
import { ChatroomDetailPage } from "./chatroom-detail-page";
import { MyChatroomsPage } from "./my-chatrooms-page";
import { initializeChatState } from "../redux/thunks/initialize-chat-state";
import { CopyTradePopup } from "./popups/copy-trade-popup";
import { CreateDMChatroomPopup } from "./popups/create-dm-chatroom-popup";
import { DMPrefsPopup } from "./popups/dm-prefs-popup";
import { GroupPrivacySettingsPopup } from "./popups/group-privacy-settings-popup";
import { chatroomsSelector, currentChatroomSelector } from "../redux/chat.selectors";
import { ChatroomMembersPopup } from "./popups/chatroom-members-popup";
import { ChatMessageImageUploadPopup } from "./popups/chat-message-image-upload-popup";
import { useAuthUser } from "../../auth/hooks/useAuthUser";
import { SignalsFormatPopup } from "./popups/signals-format-popup";
import api from "../../api";
import useSWR from 'swr';
import { setModerators, setMyChatrooms, setSelectedChatroomId } from "../redux/chat.slice";
import { useNotificationClickEvent } from "../../notifications/hooks/use-notification-click-event";
import { isFirebaseNewMessageNotificationData } from "../../api/extra-types/firebase-notification-data";

export const ChatWidgetNew = () => {

  const { initialized, user } = useAuthUser();
  const dispatch = useAppDispatch();
  const chatrooms = useAppSelector(chatroomsSelector)
  const selectedChatroom = useAppSelector(currentChatroomSelector);

  useNotificationClickEvent((data) => {
    // User clicked on notification

    // User clicked on new message notification
    // So we open that chatroom.
    if (isFirebaseNewMessageNotificationData(data)) {
      const newChatroomId = data.chatroom_id;
      dispatch(setSelectedChatroomId(newChatroomId));
    }

  });

  useSWR('/my-chatrooms', () => api.chat.myChatrooms(), {
    onSuccess(data, key, config) {
      dispatch(setMyChatrooms(data));
    },
  });

  useSWR('/chat/moderators', () => api.chat.moderators(), {
    onSuccess(data) {
      dispatch(setModerators(data))
    }
  })

  useEffect(() => {
    dispatch(initializeChatState());
  }, [initialized, user, dispatch]);

  if (!initialized || !user) {
    return null;
  }

  return <div>
    <CopyTradePopup />
    <CreateDMChatroomPopup />
    <DMPrefsPopup />
    <GroupPrivacySettingsPopup />
    <ChatroomMembersPopup />
    <ChatMessageImageUploadPopup />
    <SignalsFormatPopup />
    {/* websocket subscriptions to each chatroom */}
    {
      chatrooms 
        && chatrooms.map(chatroom => (
          <ChatroomSubscription 
            key={chatroom.id} 
            chatroom={chatroom}
          />
        ))
    }

    {/* list of chatrooms */}
    {
      selectedChatroom === null && <div style={{ height: 500 }}>
        <MyChatroomsPage />
      </div>  
    }

    {
      selectedChatroom !== null && <div style={{ height: 500 }}>
        <ChatroomDetailPage />
      </div>
    }

  </div>
}


