import { BotAlertWithOrders, isCryptoBotAlert, isOptionsBotAlert, isStockBotAlert, OptionsAlertCalculatedParams } from "../../api/autotrade-alerts";
import { directionLabelsShort } from "../../api/enum-labels";
import { BotAction, CryptoAmountUnit, Direction } from "../../api/enums";
import { formatMoneyAuto, formatNumberAuto, formatUTCDate } from "../../shared/utils";
import { displayCryptoSymbol } from "../../trade/utils/crypto";

export function getFormattedBotAction(action : BotAction) {
  switch (action) {
    case BotAction.buy:
      return "Buy";
    case BotAction.sell:
      return "Sell";
    case BotAction.call:
      return "Buy";
    case BotAction.put:
      return "Buy";
    case BotAction.close:
      return "Close";
    default:
      const a : never = action;
  }
}

export function getFormattedBotAlertSymbol(alert : BotAlertWithOrders) {
  if (isStockBotAlert(alert)) {
    return alert.asset_name;
  }
  if (isOptionsBotAlert(alert)) {
    const param = alert?.parameters?.[0] || null;

    const stock = alert.asset_name;
    const strike = param?.strike_price || alert?.strike_price;

    let direction = null; 
    if (alert.action == BotAction.call) {
      direction = directionLabelsShort[Direction.Call];
    }
    if (alert.action == BotAction.put) {
      direction = directionLabelsShort[Direction.Put];
    }
    
    let expiration = alert.expiration_date;
    if (alert.action != BotAction.close && param?.expiration_date !== undefined) {
      expiration = param.expiration_date;
    }
    if (expiration !== null) {
      expiration = formatUTCDate(expiration, 'MM/dd');
    }

    return `${stock} ${strike || ''}${direction || ''} ${expiration || ''}`;
  }
  if (isCryptoBotAlert(alert)) {
    return alert.asset_name;
  }
  return '';
}

export function getFormattedBotAlertLimitPrice(alert: BotAlertWithOrders) {
  if (isStockBotAlert(alert)) {
    const params = alert?.parameters?.[0] || null;

    const price = params?.limit_price || alert?.price || null;

    return price
      ? formatMoneyAuto(price)
      : null
  }

  if (isOptionsBotAlert(alert)) {
    const params = alert?.parameters?.[0] || null;

    const price = params?.limit_price || alert?.price || null;

    return price
      ? formatMoneyAuto(price)
      : null
  }

  if (isCryptoBotAlert(alert)) {
    const params = alert?.parameters?.[0] || null;

    const price = params?.limit_price || alert?.crypto_price || null;

    return price
      ? formatMoneyAuto(price)
      : null
  }
}

export function getFormattedBotAlertQuantity(alert: BotAlertWithOrders) {
  if (isStockBotAlert(alert)) {
    const params = alert?.parameters?.[0] || null;
    const quantity = params?.quantity || alert?.quantity || null;
    return quantity || '';
  }
  if (isOptionsBotAlert(alert)) {
    const params = alert?.parameters?.[0] || null;
    const quantity = params?.quantity || alert?.quantity || null;
    return quantity || '';
  }
  if (isCryptoBotAlert(alert)) {
    const params = alert?.parameters?.[0] || null;
    const symbol = displayCryptoSymbol(params?.ticker_symbol || alert.asset_name);
    const unit = params?.amount_unit || alert?.crypto_amount_unit || null;
    const amount = params?.amount || alert?.crypto_amount || null;
    if (!unit || !amount) {
      return '';
    }
    if (unit == CryptoAmountUnit.USD) {
      return formatMoneyAuto(amount);
    }
    if (unit == CryptoAmountUnit.Coin) {
      return `${formatNumberAuto(amount)} ${symbol}`;
    }
  }
  return '';
}

export function wasAlertExecuted(alert : BotAlertWithOrders) {
  return alert.orders.length > 0;
}
