import { Button, Form, Input } from "antd"
import { useState } from "react"
import api from "../../../api"
import { SignalProviderType } from "../../../api/enums"
import { ErrorMessage } from "../../../shared/components/ErrorMessage"

export type EditSignalProviderFormProps = {
  providerId: number,
  providerType: SignalProviderType,
  name: string,
  bio: string | null,
  intro: string | null,
  onSuccess: () => void | Promise<void>,
}

export type FormData = {
  name: string
  bio: string | null
  intro: string | null
}

export const EditSignalProviderForm = ({
  providerId,
  providerType,
  name,
  bio,
  intro,
  onSuccess
} : EditSignalProviderFormProps) => {

  const [error, setError] = useState<unknown>(null);
  const [submiting, setSubmiting] = useState(false);

  const handleSubmit = async (data: FormData) => {
    try {

      setError(null);
      setSubmiting(true);

      await (
        providerType === SignalProviderType.Community
        ? api.signalProviders.editCommunitySignalProvider(providerId, data)
        : api.signalProviders.editIndividualSignalProvider(providerId, data)
      );

      await onSuccess();

    } catch(e) {

      setError(e);

    } finally {

      setSubmiting(false);

    }
  };

  
  return <Form<FormData>
    layout='vertical'
    initialValues={{ name, bio, intro }}
    onFinish={handleSubmit}
    >
      <ErrorMessage error={error} />
      <Form.Item
        label="Provider Name"
        name="name"
        rules={[{ required: true, message: 'Please enter the provider name' }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="Intro"
        name="intro"
        help="Short single line intro"
      >
        <Input />
      </Form.Item>


      <Form.Item
        label="About"
        name="bio"
      >
        <Input.TextArea />
      </Form.Item>

      <Button
        disabled={submiting}
        loading={submiting}
        type="primary"
        htmlType="submit"
      >
        Save
      </Button>
  </Form>
}