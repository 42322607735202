import { FirebaseOptions } from "firebase/app";

export const VAPID_KEY = 'BMPgQ_jTeeSb1pOZtJJedYH7fGCeRSTh-VZUARLxPqy0L6KyIwelckLO8Y1RzagLfi2GQoHNcsJ0YEvbqSike0k'

export const firebaseConfig = {
  apiKey: "AIzaSyDPvlqrculLdsvlGZC0c7IyeWSRIFVb250",
  authDomain: "candefi-dev.firebaseapp.com",
  projectId: "candefi-dev",
  storageBucket: "candefi-dev.firebasestorage.app",
  messagingSenderId: "1062718808887",
  appId: "1:1062718808887:web:78a4a927dc0db0c141f0b2"
} satisfies FirebaseOptions;
