import { Card, Col, Row } from 'antd';
import useSWR from 'swr';
import api from '../../../api';
import { ErrorMessage } from '../../../shared/components/ErrorMessage';
import { AnalystCommunityListItem } from './AnalystCommunityListItem';

export const MyCommunitiesCard = () => {
  const {
    data,
    error,
    isLoading
  } = useSWR('/signal-providers/community/my', () => {
    return api.signalProviders.listMyCommunities();
  });

  // no data
  if (data !== undefined && data.length <= 0) {
    return null;
  }

  return <Card title={'Your Communities'} loading={isLoading}>
    <ErrorMessage error={error} />
    {
      data && data.length > 0 && (
        <Row gutter={[16, 16]}>
        {
          data.map(provider => (
            <Col
              key={provider.id}
              span={24} md={{ span: 12 }} xxl={{ span: 8 }}
            >
              <AnalystCommunityListItem
                provider={provider}
              />
            </Col>
          ))
        }
      </Row>
      )
    }
  </Card>
}