import { useEffect, useState } from "react";
import { Button, Result } from "antd";
import { Link, useSearchParams } from "react-router-dom";
import { useStripe } from "@stripe/react-stripe-js";

const RechargePaymentStatus = () => {

  const stripe = useStripe();

  let [searchParams, _] = useSearchParams();

  const query = searchParams.get("payment_intent_client_secret");

  const [paymentStatus, setPaymentStatus] = useState<String>("succeeded");

  useEffect(() => {
    const fetchPaymentStatus = async () => {
      if (query && stripe) {
        const paymentIntent = await stripe.retrievePaymentIntent(query);

        if (paymentIntent.paymentIntent?.status) {
          setPaymentStatus(paymentIntent.paymentIntent?.status);
        }
      }
    };
    fetchPaymentStatus();
  }, [query, stripe]);

  return (
    <Result
      status={paymentStatus === "succeeded" ? "success" : "error"}
      title={paymentStatus === "succeeded" ? "Congratulations!" : "Sorry"}
      subTitle={
        paymentStatus === "succeeded"
          ? "Recharge successfull"
          : "something went wrong!"
      }
      extra={[
        <Link to="/app/dashboard" replace>
          <Button type="primary" key="console">
            Go Dashboard
          </Button>,
        </Link>
      ]}
    />
  );
};

export default RechargePaymentStatus;
