import { Button, Checkbox, Col, Divider, Form, Input, Row, Space } from 'antd'
import { SignupRequest } from '../../api/auth'
import api from '../../api'
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ErrorMessage } from '../../shared/components/ErrorMessage';
import { Link } from '../../shared/components/Link';
import { usernameRegex, usernameRegexExplanation } from '../../shared/utils';
import { UserRole } from '../../api/enums';
import { useReferralInfo } from '../hooks/useReferralInfo';

enum Interest {
  AutoTrading = 'auto_trading',
  CopyTrading = 'copy_trading',
  SharingSignals = 'sharing_signals',
  Community = 'community',
}

const interestLabels : Record<Interest, string> = {
  [Interest.AutoTrading] : 'Auto Trading (Trading View Indicators)',
  [Interest.CopyTrading]: 'Copy Trade Signals from other traders',
  [Interest.SharingSignals]: 'Share Trade Signals',
  [Interest.Community]: 'Create Community for Traders & Analyst'
}

type FormRequest = SignupRequest & {
  interests: Interest[],
}

const defaultInterests = Object.values(Interest);

function getRole(interests: Interest[]) {
  const analyst = interests.includes(Interest.SharingSignals) 
    || interests.includes(Interest.Community)

  return analyst ? UserRole.analyst : UserRole.trader;
}

export const SignupForm = () => {
  const { referralCode, data: referralInfo, destination, signalProviderUsername } = useReferralInfo();

  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState<unknown>(null);
  const navigate = useNavigate();

  const signup = async (data : FormRequest) => {
    try {

      const { interests, ...signupRequest } = data;

      signupRequest.role = getRole(interests);
      signupRequest.referring_signal_provider_username = signalProviderUsername || null;
      signupRequest.referral_code = data.referral_code || null;
    
      await api.auth.signup(signupRequest);

      navigate(`/auth/verify-email?email=${data.email}`, {
        state: destination !== null 
          ? {from: { pathname: destination } }
          : undefined,
        replace: true,
      });

    } catch (e) {
      setError(e)
    } finally {
      setSubmitting(false);
    }
  }

  return <div>
    <Form<FormRequest>
      layout='vertical'
      onFinish={signup}
      initialValues={{
        referral_code: referralCode,
        interests: defaultInterests,
      }}
    >
      <ErrorMessage error={error}/>
      <Row gutter={[8, 8]}>
        <Col span={24} md={{ span: 12 }}>
          <Form.Item
            label="Name"
            name="name"
            rules={[
              { required: true, message: 'name is required' },
            ]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col span={24} md={{ span: 12 }}>
          <Form.Item
            label="Email"
            name="email"
            rules={[
              { required: true, message: 'email is required' },
              { type: 'email', message: 'must be a valid email' }
            ]}
          >
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item
        label="Username"
        name="username"
        rules={[
          { required: true, message: 'username is required' },
          { pattern: usernameRegex, message: usernameRegexExplanation },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Password"
        name="password"
        rules={[
          { required: true, message: 'password is required' },
          { min: 8, message: 'password must be atleast 8 characters' },
        ]}
      >
        <Input.Password />
      </Form.Item>
      <Form.Item
        dependencies={['password']}
        label="Confirm Password"
        name="password_confirmation"
        rules={[
          { required: true, message: 'confirm your password' },
          ({ getFieldValue }) => ({
            validator: (_, value) => {
              if (!value || getFieldValue('password') === value) {
                return Promise.resolve();
              }
              return Promise.reject(new Error('passwords dont match'))
            }
          })
        ]}
      >
        <Input.Password />
      </Form.Item>

      <Form.Item
        label="Referral Code"
        name="referral_code"
      >
        <Input 
          disabled={referralInfo !== null}
        />
      </Form.Item>

      <Form.Item
        label="What are you interested in?"
        name="interests"
      >
        <Checkbox.Group>
          <Space direction='vertical' size='middle'>
            {
              Object.entries(interestLabels).map(([value, label]) => (
                <Checkbox value={value} key={value}>
                  { label }
                </Checkbox>
              ))
            }
          </Space>
        </Checkbox.Group>
      </Form.Item>

      <Divider />

      <Form.Item
        valuePropName='checked'
        name="agree"
        rules={[
          {
            validator: (_, value) =>
              value ? Promise.resolve() : Promise.reject(new Error('Must agree')),
          },
        ]}
      >
        <Checkbox>
          I agree to the &nbsp;
          {/* @ts-ignore */}
          <Link to='/privacy-policy' target="__blank">
            privacy policy
          </Link> and the &nbsp;
          {/* @ts-ignore */}
          <Link to='/terms-of-use' target="__blank">
            terms of use
          </Link>
        </Checkbox>
      </Form.Item>

      <Form.Item>
        <Button
          style={{ width: '100%' }}
          loading={submitting}
          disabled={submitting}
          type="primary"
          size='large'
          htmlType="submit"
        >
          Sign Up
        </Button>
      </Form.Item>

      <Form.Item>
        <div style={{ textAlign: 'center' }}>
          {/* @ts-ignore */}
          <Link to="/auth/login">
            Already Have An Account? Login.
          </Link>
        </div>
      </Form.Item>
    </Form>
  </div>
}