import { Alert, Button, Card } from "antd"
import { useState } from "react";
import OpenOrders from "../../orders/components/OpenOrders"
import OpenPositions from "../../positions/components/OpenPositions"
import { AssetType } from "../../api/enums";
import { useSupportedAssetTypes } from "../../trade/hooks/useSupportedAssetTypes";
import { assetTypeLabels } from "../../api/enum-labels";

export enum EntityTab {
  Positions = "positions",
  Orders = "orders",
}

export const entityTabLabels: Record<EntityTab, string> = {
  [EntityTab.Positions]: "Positions",
  [EntityTab.Orders]: "Orders"
}

export enum AssetTab {
  All = "all",
  Options = "options",
  Stocks = "stocks",
  Crypto = "crypto"
}

export const assetTabLabels: Record<AssetTab, string> = {
  [AssetTab.All]: "All",
  [AssetTab.Options]: "Options",
  [AssetTab.Stocks]: "Stocks",
  [AssetTab.Crypto]: "Crypto",
}


export const PositionsAndOrdersCard = () => {
  // Tab to choose "Position" or "Order"
  const [entityTab, setEntityTab] = useState<EntityTab>(
    EntityTab.Positions
  );

  // Tab to choose asset type
  const [assetTab, setAssetTab] = useState<AssetTab>(
    AssetTab.All
  );

  // Maps asset type tab to list of asset types
  const assetTypesMap: Record<AssetTab, AssetType[]> = {
    [AssetTab.All]: Object.values(AssetType),
    [AssetTab.Options]: [AssetType.Options],
    [AssetTab.Stocks]: [AssetType.Stocks],
    [AssetTab.Crypto]: [AssetType.Crypto],
  };

  // Asset types to display for the selected asset tab
  const assetTypes = assetTypesMap[assetTab];

  // List of asset type supported by the currently selected broker account
  const supportedAssetTypes = useSupportedAssetTypes();

  // Out of the asset types we need to display
  // which asset types are not supported
  const unsupportedAssetTypes = assetTypes.filter(assetType => !supportedAssetTypes.includes(assetType));

  // Message to display for unsupported asset types
  const unsupportedAssetTypeMessage = (unsupportedAssetTypes.length > 0)
    ? `Selected broker does not support ${unsupportedAssetTypes.map(type => assetTypeLabels[type]).join(",")}`
    : null;

  return <Card size="small">

    {/* Entity type tab */}
    <div style={{ marginBottom: 20, overflow: "auto", display: "flex", flexWrap: 'nowrap', gap: 8 }}>
      {
        Object.values(EntityTab).map(tab => (
          <Button
            type={entityTab === tab ? "primary" : "default"}
            onClick={() => setEntityTab(tab)}
            size="large"
            key={tab}
          >
            {entityTabLabels[tab]}
          </Button>
        ))
      }
    </div>

    {/* Asset type tab */}
    <div style={{ marginBottom: 20, overflow: "auto", display: "flex", flexWrap: 'nowrap', gap: 8 }}>
      {
        Object.values(AssetTab).map(t => (
          <Button
            key={t}
            type={assetTab === t ? "primary" : "default"}
            onClick={() => setAssetTab(t)}
            size="middle"
          >
            {assetTabLabels[t]}
          </Button>
        ))
      }
    </div>

    {/* Unsupported asset types message */}
    {/* Its Only shown if "all" tab is not selected */}
    {
      assetTab !== AssetTab.All && unsupportedAssetTypeMessage && (
        <Alert type="info" style={{ marginBottom: 16 }} message={unsupportedAssetTypeMessage} />
      )
    }

    {/* Orders table */}
    {entityTab === EntityTab.Orders && <OpenOrders assetTypes={assetTypes} />}

    {/* Positions table */}
    {entityTab === EntityTab.Positions && <OpenPositions assetTypes={assetTypes} />}
  </Card>
}