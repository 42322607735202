import { Button, Card, notification, Space, Typography } from "antd";
import { useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { useCardWithTopActions } from "../../../shared/styles/useCardWithTopActions";
import { useAuthUserSignalProviders } from "../../hooks/useAuthUserSignalProviders";
import { useStripeConnectAccount } from "../../../stripe-connect/hooks/useStripeConnectAccount";
import { LineChartOutlined, PlusOutlined } from '@ant-design/icons';
import { useAuthUser } from "../../../auth/hooks/useAuthUser";
import { SignalProviderWithMembershipPlans } from "../../../api/signal-providers";
import { AddPlanIntro } from "../../../membership-plans/components/AddPlanIntro";
import { AnalystPlansList } from "../../../membership-plans/components/AnalystPlansList";
import { CreateMembershipPlanModal } from "../../../membership-plans/components/CreateMembershipPlanModal";
import { SignalProviderLink } from "../../../account/components/SignalProviderLink";

export function AnalystSignalProviderPlansSection({ provider }: { provider: SignalProviderWithMembershipPlans; }) {

  const { refresh } = useAuthUserSignalProviders();
  const { initialized } = useAuthUser();
  const [showCreateModal, setShowCreateModal] = useState<boolean>(false);
  const { setupComplete: hasConnectedStripe } = useStripeConnectAccount();
  const [notificationApi, contextHolder] = notification.useNotification();

  const plans = provider.membership_plans || [];
  const cardClassName = useCardWithTopActions();
  const hasMembershipPlans = plans.length > 0;

  const openCreateModal = () => {
    setShowCreateModal(true);
  };

  const handlePlanCreated = () => {
    refresh();
    notificationApi.success({
      message: 'Created Plan'
    });
  };

  const planIds = useMemo(() => {
    return (provider.membership_plans || []).map(plan => plan.id);
  }, [provider.membership_plans]);

  const statsUrl = useMemo(() => {
    const searchParams = new URLSearchParams();
    planIds.forEach(planId => {
      searchParams.append('plan_ids', planId.toString());
    });
    return `/app/account/analyst/stats?${searchParams.toString()}`;
  }, [planIds]);

  if (!hasConnectedStripe) {
    return null;
  }

  return <Card
    className={cardClassName}
    extra={<>
      <Link to={statsUrl} relative="route">
        <Button
          title="Earning Stats"
          type="default"
          icon={<LineChartOutlined />}>
          Earning Stats
        </Button>
      </Link>
      <Button
        title="Create Plan"
        icon={<PlusOutlined />}
        onClick={openCreateModal}
        type="primary"
      >
        Create Plan
      </Button>
    </>}
    title="Plans"
    loading={!initialized}
    bordered={false}
  >
    {contextHolder}
    <Space direction="vertical" size='large' style={{ width: '100%' }}>
      {!hasMembershipPlans && <AddPlanIntro
        onClick={openCreateModal} />}
      {hasMembershipPlans && <AnalystPlansList
        plans={plans}
        refresh={refresh}
        provider={provider} />}
      {planIds.length > 0 && (
        <Card>
          <Typography.Title level={3}>Your Signal Provider Page</Typography.Title>
          <Typography.Paragraph type="secondary">
            Share this exclusive link to your membership plan with your followers:
          </Typography.Paragraph>
          <Card size="small">
            <SignalProviderLink
              provider={provider} />
          </Card>
        </Card>
      )}
      <CreateMembershipPlanModal
        signalProviderId={provider.id}
        open={showCreateModal}
        setOpen={setShowCreateModal}
        onSuccess={handlePlanCreated} />
    </Space>
  </Card>;
}
