import axios from "./axios";

export type FeeResponse = {
  subscription: number
}

const fcmTokensApi = {
  async addFCMToken(token: string, signal?: AbortSignal) {
    const { data } = await axios.post<null>('/fcm-tokens', { token }, {
      signal: signal
    });
    return data;
  },

  async deleteToken(token: string) {
    const { data } = await axios.delete<null>(`/fcm-tokens/${token}`);
    return data;
  }
}

export default fcmTokensApi;