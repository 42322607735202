export enum BrokerType {
  webull='webull',
  robinhood='robinhood',
  tda='tda',
  webull_pay = 'webull_pay'
}

export interface BrokerAccount {
  id: number
  type: BrokerType
  username: string
}