import { useSelectedBroker } from "../../brokers/context/SelectedBrokerContext";
import { getSupportedAssets } from "../utils/asset-utils";

export function useSupportedAssetTypes() {
  const { initialized, broker } = useSelectedBroker();

  if (!initialized || !broker) {
    return [];
  }

  return getSupportedAssets(broker.account.type, broker.paper);
}