import { removeSlash } from "./utils";

const ASSET_URL = process.env.REACT_APP_ASSET_URL || '';

export type ImageVariant = '1920x1080' | '464x261' | '50x50' | '80x80' | '100x100' |  '200x200'

export interface ImageUrlOptions {
  variant?: ImageVariant
}

const prefix = "uploads";

export function getImageUrl(path: string, options?: ImageUrlOptions) {
  const base = removeSlash(ASSET_URL);
  path = removeSlash(path);

  let url = `${base}/${path}`;

  if (options?.variant) {
    if (!path.startsWith(prefix)) {
      return url
    }
    let pathWithoutPrefix = removeSlash(path.substring(prefix.length));
    url = `${base}/${prefix}/${options.variant}/${pathWithoutPrefix}`
  }

  return url;
}