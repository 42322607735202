export type CheckVisibilityOptions = {
  opacityProperty?: boolean;
  checkOpacity?: boolean;
  visibilityProperty?: boolean;
  checkVisibilityCSS?: boolean;
  contentVisibilityAuto?: boolean;
}

// Browsers already have built-in support for element visibility checks, 
// but this function serves as a temporary solution until it is universally supported across all browsers 
// or a standardized polyfill is available. 
// This function was designed specifically for checking the visibility of a chat window 
// and works for that use case; 
// PLEASE VERIFY if it meets the requirements of your use case before using.
export function checkVisibility(element: HTMLElement, options: CheckVisibilityOptions = {}): boolean {
  // Helper function to get computed style of an element
  const getComputedStyleProperty = (el: HTMLElement, property: string): string => {
    return window.getComputedStyle(el).getPropertyValue(property);
  };

  // Step 1: Check if element has an associated box (e.g., display: none)
  if (!element || !(element instanceof HTMLElement) || element.getClientRects().length === 0) {
    return false;
  }

  // Step 2: Check if an ancestor has content-visibility: hidden
  let currentElement: HTMLElement | null = element;
  while (currentElement) {
    if (getComputedStyleProperty(currentElement, 'content-visibility') === 'hidden') {
      return false;
    }
    currentElement = currentElement.parentElement;
  }

  // Step 3: Check opacity, if required
  if (options.opacityProperty || options.checkOpacity) {
    currentElement = element;
    while (currentElement) {
      if (parseFloat(getComputedStyleProperty(currentElement, 'opacity')) === 0) {
        return false;
      }
      currentElement = currentElement.parentElement;
    }
  }

  // Step 4: Check visibility property, if required
  if (options.visibilityProperty || options.checkVisibilityCSS) {
    if (
      getComputedStyleProperty(element, 'visibility') === 'hidden' ||
      getComputedStyleProperty(element, 'visibility') === 'collapse'
    ) {
      return false;
    }
  }

  // Step 5: Check content-visibility: auto
  if (options.contentVisibilityAuto) {
    currentElement = element;
    while (currentElement) {
      if (
        getComputedStyleProperty(currentElement, 'content-visibility') === 'auto' &&
        currentElement.getClientRects().length === 0
      ) {
        return false;
      }
      currentElement = currentElement.parentElement;
    }
  }

  // If all checks pass, return true
  return true;
}