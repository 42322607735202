import { Card, Typography } from "antd";
import { SignalProvider, SignalProviderFull } from "../../api/signal-providers";
import EllipsisText from "../../shared/components/EllipsisText";

export function SignalProviderAboutCard({
  provider,
} : {
  provider: SignalProviderFull,
}) {
  return <Card title="About">
    <div style={{ whiteSpace: 'pre-line' }}>
      <EllipsisText maxLines={3}>
        { provider.bio }
      </EllipsisText>
    </div>
  </Card>
}