import { Typography } from "antd";
import Flex from "../../shared/components/Flex";
import background from './pexels-artempodrez-5716052.jpg';

export function MarketplaceBanner() {
  return <Flex 
    alignItems='center'
    justifyContent='center'
    style={{
      textAlign: 'center', 
      position: 'relative', 
      zIndex: '1', 
      width: '100%', 
      borderRadius: 8, 
      overflow: 'clip',
    }}
  >
    <div style={{ padding: 32, maxWidth: 600 }}>
      <Typography.Title
        style={{
          // @ts-ignore
          textWrap: 'balance',
          color: 'white',
        }}
      >
        Signal Provider Marketplace
      </Typography.Title>
      <Typography.Paragraph 
        style={{ 
          // @ts-ignore
          textWrap: 'balance',
          fontSize:'1.4rem',
          color: 'white',
        }}
      >
        {/* Find expert analysts to copy their trades. */}
        Discover Trade Insights From Top Analysts & Trading Communities
      </Typography.Paragraph>
    </div>
    <img 
      style={{ position: 'absolute', inset: 0, zIndex: -2, width: '100%', height: '100%', objectFit: 'cover' }}
      src={background}
      // src={'https://images.unsplash.com/photo-1605759062013-e69aeb188665?q=80&w=1928&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'} 
      // src={'https://images.unsplash.com/photo-1579225663317-c0251b4369bc?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1yZWxhdGVkfDF8fHxlbnwwfHx8fHw%3D'}
      // src={'https://images.pexels.com/photos/5716052/pexels-photo-5716052.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1'}
    />
    <div
      style={{ position: 'absolute', inset: 0,  zIndex: -1, width: '100%', height: '100%', objectFit: 'cover', background: 'rgba(0,0,0,0.6)' }}
    >
    </div>
  </Flex>
}