import { SortOrder } from 'antd/es/table/interface';
import { UserPublicRead } from './auth';
import axios from './axios';
import { BillingIntervalUnit, CommunityInviteStatus, SignalProviderType } from './enums';
import { GenericIDResponse, ListRequest, PaginatedResponse } from './types';

export interface SignalProviderListItem {
  signal_provider: SignalProvider;
  options: boolean;
  stocks: boolean;
  crypto: boolean;
  starting_price: number;
}

export interface SignalProvider {
  id: number;
  username: string;
  name: string;
  type: SignalProviderType;
  intro: string | null;
  cover_image: string | null
  profile_image: string | null
}

export type SignalProviderFull = SignalProvider & {
  bio: string | null;
}

export interface MembershipPlan {
  id: number;
  active: boolean;
  name: string;
  amount: number;
  billing_interval: number;
  billing_interval_unit: BillingIntervalUnit;
  billing_cycles: number;
  options: boolean;
  stocks: boolean;
  crypto: boolean
}

export interface SignalProviderWithMembershipPlans {
  id: number;
  name: string;
  type: SignalProviderType;
  bio: string | null;
  intro: string | null;
  membership_plans: MembershipPlan[];
  username: string
  cover_image: string | null
  profile_image: string | null
}

export interface CreateIndividualSignalProviderRequest {
  name: string;
  username: string;
  bio: string | null;
  intro: string |null;
}

export enum SignalProviderListSortField {
  Name = 'name',
  Price = 'price',
}

export class ListSignalProvidersRequest extends ListRequest {
  options?: boolean
  stocks?: boolean
  crypto?: boolean
  sort_field?: SignalProviderListSortField;
  sort_order?: SortOrder;
  q?: string
}


export type CreateCommunitySignalProviderRequest = {
  name: string,
  bio: string | null,
  intro: string | null,
  username: string,
}

export type CommunityAnalystBasic = {
  id: number
  signal_provider: SignalProvider
  analyst: UserPublicRead,
}

export type CommunityInviteBase = {
  id: number
  signal_provider_id: number
  user_id: number
  status: CommunityInviteStatus
  valid_upto: string
  has_expired: boolean
  created_at: string
  updated_at: string
}

export type CommunityInviteWithUserAndCommunity = CommunityInviteBase & {
  signal_provider: SignalProvider
  user: UserPublicRead
}

export type CreateCommunityInviteRequest = {
  username: string
}

export type CommunityInviteWithCommunity = CommunityInviteBase & {
  signal_provider: SignalProvider
}

export type  EditIndividualSignalProviderRequest = {
  name: string
  bio: string | null
  intro: string | null
}

export type  EditCommunitySignalProviderRequest = {
  name: string
  bio: string | null
  intro: string | null
}

export type  EditImageRequest = {
  image: string
}

const signalProvidersApi = {
  async index (request : ListSignalProvidersRequest) {
    const {data} = await axios.get<PaginatedResponse<SignalProviderListItem>>(
      "/signal-providers",
      { params: request }
    );
    return data;
  },

  async getSubscribedSignalProviders(){
    const {data} = await axios.get<SignalProvider[]>('/signal-providers/subscribed')
    return data
  },
  
  async getSignalProviderById(id: number){
    const {data} = await axios.get<SignalProviderWithMembershipPlans>(`/signal-providers/${id}`)
    return data
  },

  async findByUsername(username: string) {
    const { data } = await axios.get<SignalProviderWithMembershipPlans>(`/signal-providers/by-username/${username}`);
    return data;
  },

  async createIndividualSignalProvider(request: CreateIndividualSignalProviderRequest){
    const {data} = await axios.post<GenericIDResponse>('/signal-providers/individual', request)
    return data
  },

  async createCommunitySignalProvider(request: CreateCommunitySignalProviderRequest){
    const {data} = await axios.post<GenericIDResponse>('/signal-providers/community', request)
    return data
  },

  async getSignalProviderAnalysts(providerId: number) {
    const { data } = await axios.get<CommunityAnalystBasic[]>(`/signal-providers/community/${providerId}/analysts`);
    return data;
  },

  async getSignalProviderInvites(providerId: number) {
    const { data } = await axios.get<CommunityInviteWithUserAndCommunity[]>(`/signal-providers/community/${providerId}/invites`);
    return data;
  },

  async sendInvite(providerId: number, request: CreateCommunityInviteRequest) {
    const { data } = await axios.post<null>(`/signal-providers/community/${providerId}/invites`, request);
    return data;
  }, 
  
  async deleteInvite(providerId: number, inviteId: number) {
    const { data } = await axios.delete<null>(`/signal-providers/community/${providerId}/invites/${inviteId}`);
    return data;
  },

  async findInviteByToken(token: string) {
    const { data } = await axios.get<CommunityInviteWithCommunity>(`/signal-providers/community/invites/by-token/${token}`);
    return data;
  },

  async acceptInvite(token: string) {
    const { data } = await axios.post<null>(`/signal-providers/community/invites/by-token/${token}/accept`);
    return data;
  },

  async rejectInvite(token: string) {
    const { data } = await axios.post<null>(`/signal-providers/community/invites/by-token/${token}/reject`);
    return data;
  },

  async deleteAnalyst(providerId: number, communityAnalystId: number) {
    const { data } = await axios.delete<null>(`/signal-providers/community/${providerId}/analysts/${communityAnalystId}`);
    return data;
  },

  async editIndividualSignalProvider(providerId: number, request: EditIndividualSignalProviderRequest) {
    const { data } = await axios.patch<null>(`/signal-providers/individual/${providerId}`, request);
    return data;
  },

  async editCommunitySignalProvider(providerId: number, request: EditCommunitySignalProviderRequest) {
    const { data } = await axios.patch<null>(`/signal-providers/community/${providerId}`, request);
    return data;
  },

  async listMyCommunities() {
    const { data } = await axios.get<SignalProvider[]>("/signal-providers/community/my");
    return data;
  },

  async editCoverImage(providerId: number, request: EditImageRequest) {
    const { data } = await axios.patch<null>(`/signal-providers/${providerId}/cover-image`, request)
    return data;
  },

  async editProfileImage(providerId: number, request: EditImageRequest) {
    const { data } = await axios.patch<null>(`/signal-providers/${providerId}/profile-image`, request)
    return data;
  },

}

export default signalProvidersApi