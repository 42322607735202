import { Menu } from "antd";
import { Link, useLocation } from "react-router-dom";
import { UserRole } from "../../api/enums";
import { useThemeContext } from "../../shared/context/ThemeContext";
import { useAuthUser } from "../../auth/hooks/useAuthUser";

export const AccountMenu = () => {

  const { user } = useAuthUser();
  const isAnalyst = !!user && user.role === UserRole.analyst;
  const isTrader = !!user && user.role === UserRole.trader;
  const location = useLocation();
  const { isDark } = useThemeContext(); 

  // menu items 
  const menuItems = [
    // {
    //   key: "brokers",
    //   label: "Broker Connections",
    //   href: '/app/account/brokers',
    //   visible: true,
    // },
    // {
    //   key: "candeFiMembership",
    //   label: "AutoTrade Memberships",
    //   href: '/app/account/candefi-memberships',
    //   visible: true,
    // },
    {
      key: "memberships",
      label: "Memberships",
      href: '/app/account/candefi-memberships',
      subpages: ['/app/account/candefi-memberships', '/app/account/memberships'],
      visible: true,
    },
    {
      key: "trade-profiler",
      label: "Trade Profiler",
      href: '/app/account/trade-profiler',
      visible: true,
    },
    {
      key: "referral",
      label: "Referral Program",
      href: '/app/account/referral',
      visible: true,
    },
    {
      key: "account-info",
      label: "Account Info",
      href: '/app/account/info',
      visible: true,
    },
    {
      key: "other-settings",
      label: "Other Settings",
      href: '/app/account/other-settings',
      visible: true,
    },
    {
      key: "contact-us",
      label: "Contact Us",
      href: '/app/account/contact-us',
      visible: true,
    },
  ].filter(item => item.visible);

  // keys of currently selected items 
  const selectedKeys = menuItems.map(item => {

    const active = item.subpages
      ? item.subpages.some(page => location.pathname.startsWith(page))
      : location.pathname.startsWith(item.href);

    return {
      key: item.key,
      active: active,
    }
  }).filter(item => item.active).map(item => item.key);

  // items in format required by ant design menu component
  const items = menuItems.map((item) => ({
    key: item.key,
    label: <Link to={item.href}>
      { item.label }
    </Link>
  }))

  return <Menu
    style={{ backgroundColor: 'transparent' }}
    theme={ isDark ? 'dark' : 'light' }
    selectedKeys={selectedKeys}
    items={items}
  />
}