import axios from "./axios";
import { AutotradeOptionsExpiryType, AutotradeStrikePreference, TimeInForce } from "./enums";
import { GenericStatusResponse } from "./types";

export type EditAutotradePreferenceRequest = {
  autotrade_enabled: boolean;
  share: boolean;
  autotrade_start_time: string | null;
  autotrade_end_time: string | null;
  stocks_max_quantity: number | null;
  stocks_min_price: number | null;
  stocks_max_price: number | null;
  options_max_quantity: number | null;
  options_min_price: number | null;
  options_max_price: number | null;
  options_min_premium: number | null;
  options_max_premium: number | null;
  options_expiry_type: AutotradeOptionsExpiryType;
  strike_preference: AutotradeStrikePreference;
  options_expiry_date: string | null;
  crypto_max_price: number | null;
  crypto_start_time: string | null;
  crypto_end_time: string | null;
  crypto_tif: TimeInForce | null;
}

export type UsersAutotradePreference = {
  autotrade_enabled: boolean;
  share: boolean;
  autotrade_start_time: string | null;
  autotrade_end_time: string | null;
  stocks_max_quantity: number | null;
  stocks_min_price: number | null;
  stocks_max_price: number | null;
  options_max_quantity: number | null;
  options_min_price: number | null;
  options_max_price: number | null;
  options_min_premium: number | null;
  options_max_premium: number | null;
  options_expiry_type: AutotradeOptionsExpiryType;
  strike_preference: AutotradeStrikePreference;
  options_expiry_date: string | null;
  crypto_max_price: number | null;
  crypto_max_quantity: number | null;
  crypto_start_time: string | null;
  crypto_end_time: string | null;
  crypto_tif: TimeInForce | null;
}

const autotradePreferenceApi = {
  async fetch() {
    const { data } = await axios.get<UsersAutotradePreference | null>('/autotrade-preference');
    return data;
  },

  async update(request : UsersAutotradePreference) {
    const { data } = await axios.put<GenericStatusResponse>('/autotrade-preference', request);
    return data;
  },
}

export default autotradePreferenceApi;