import { Button, Dropdown, MenuProps, Typography } from "antd";
import { useWatchList } from "../context/watchlist-context";
import { useWatchListItem } from "../context/watchlist-item-context";
import styles from './watchlist.module.scss';

export function WatchlistSelectDropdown({ 
  onCreateWatchlistClick,
} : { 
  onCreateWatchlistClick: () => void
}) {
  const { lists, selectedListId, select } = useWatchList();

  const selectedList = selectedListId === null
    ? null
    : lists.data.find(list => list.id === selectedListId) || null;

  const items : MenuProps['items'] = [
      {
        key: 'default',
        label: <a>Default</a>
      },
      ...(
        lists.data.map(list => ({
          key: list.id.toString(),
          label: list.name,
        }))
      ),
      {
        key: 'add',
        label: <Typography.Text type="success">New WatchList</Typography.Text>
      }
    ];

  const handleSelect = async (key: string) => {

    if (key === 'default') {
      select(null);
      return;
    }

    const id = parseInt(key);
    if (!isNaN(id)) {
      select(id);
      return;
    }

    if (key === 'add') {
      onCreateWatchlistClick();
      return;
    }
  }

  const title = selectedList !== null
    ? `${selectedList.name} Watchlist`
    : `Default Watchlist`

  return (
    <Dropdown
      menu={{ 
        selectable: true,
        items, 
        selectedKeys: [selectedList?.id?.toString() || 'default'],
        onSelect: (e) => {
          const key = e.key;
          handleSelect(key);
        }
      }}
    >
      <Button className={ styles.selectListButton }>
        { title }
      </Button>
    </Dropdown>
  )
}