import { HTMLAttributes } from 'react'
import image from '../images/y-so-serious-white.png'

export function PatternImage({ aspect, children, ...props } : { aspect: number, children?: React.ReactNode } & HTMLAttributes<HTMLDivElement> ) {
  return <div 
    {...props}
    style={{ 
      ...props?.style,
      width: '100%', 
      aspectRatio: `${aspect}`, 
      backgroundImage: `url(${image})`,
      backgroundRepeat: 'repeat',
    }}
  >
    { children }
  </div>
}