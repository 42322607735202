import { SignalProvider } from "../../api/signal-providers";
import { getImageUrl } from "../../shared/image-utils";
import { PatternImage } from "../../shared/components/PatternImage";
import { useEmotionCss } from "@ant-design/use-emotion-css";

export function SignalProviderCoverImage({ 
  provider 
} : {
  provider: SignalProvider,
}) {
  const aspect = 1920 / 1080

  const bgContainer = useEmotionCss(({ token }) => ({
    background: token.colorBgContainer
  }));

  return <div className={bgContainer} style={{ width: '100%', position: 'relative', borderRadius: 8, overflow: 'clip' }}>
    {
      provider.cover_image && (
        <img src={getImageUrl(provider.cover_image, { variant: '1920x1080' })} 
          style={{ width: '100%', height: 'auto', aspectRatio: `${aspect}`, backgroundSize: 'cover' }}
        />
      )
    }
    {
      !provider.cover_image && (
        <PatternImage aspect={aspect}/>
      )
    }
  </div>
}
