import { Card } from "antd";
import { useState } from "react";
import { ParseSignalResponse } from "../../api/discord-signal-parser";
import { SignalCopyDialog } from "../../signals/components/SignalCopyDialog";
import { parsedDiscordSignalToFormInitivalValues } from "../../signals/utils/signals";
import { PlaceOrderFormInitialValues } from "../../trade/types/place-order-form-initial-values";
import { SignalNewParserForm } from "./SignalParserNewForm";

export function SignalParserCard() {
  const [formInitialValues, setformInitialValues] = useState<PlaceOrderFormInitialValues | null>(null);
  const [open, setOpen] = useState(false);
  const [key, setKey] = useState(1);

  const onClose = () => setOpen(false);

  const onSignal = (signal: ParseSignalResponse) => {
    const formInitialValues = parsedDiscordSignalToFormInitivalValues(signal);
    setformInitialValues(formInitialValues);
    setKey(k => k + 1);
    setOpen(true);
  }

  return <Card size="small">
    <SignalNewParserForm
      onSignal={onSignal}
    />    
    {/* <SignalParserForm
      onSignal={onSignal}
    /> */}
    {
      formInitialValues !== null && (
        <SignalCopyDialog
          title="Place Order"
          open={open}
          onClose={onClose}
          key={key}
          initialValues={formInitialValues}
        />
      )
    }
  </Card>
}
