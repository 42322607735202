import { Navigate, Outlet } from "react-router-dom";
import { useWindowSize } from "../../shared/hooks/useWindowSize";
import { AccountMenu } from "../components/AccountMenu";
import { AccountPageHeader } from "../components/AccountPageHeader";
import styles from './account-layout.module.scss';
import { useMatch } from 'react-router-dom';
import { Card } from "antd";
import { PageContainer } from "../../shared/components/PageContainer";

const AccountLayout = () => {

  const windowSize = useWindowSize();
  const match = useMatch('/app/account');

  const isMobile = windowSize.width < 992;
  const isMenuPage = match !== null;
  const isMobileMenuPage = isMenuPage && isMobile;

  if (isMenuPage && !isMobile) {
    return <Navigate 
      to="info"
      replace
    />
  }

  return (
    <PageContainer>
      <div className={`${styles.layout} ${isMobileMenuPage ? styles.mobileMenuLayout : ''}`}>
        <AccountPageHeader />
        <div className={styles.innerLayout}>
          <Card
            className={styles.sidebarWrapper} 
            style={{ borderRadius: 5, height: 'auto' }}>
            <AccountMenu />
          </Card>
          <div className={styles.contentWrapper}>
            <Outlet />
          </div>
        </div>
      </div>
    </PageContainer>
  );
};

export default AccountLayout;
