import { Card, Col, Pagination, Row } from "antd"
import Flex from "../../shared/components/Flex";
import SignalProviderListItemCard from "../../signal-providers/components/SignalProviderListItemCard";
import { useMarketplaceSearch } from "./MarketplaceSearchContext"

export const MarketplaceSearchResults = () => {
  const { data, error, isLoading, page, setPage } = useMarketplaceSearch();
  const hasData = data !== undefined;

  return <Card loading={isLoading}>
    {
      hasData && (
        <Flex gap={24} flexDirection="column">
          <Row gutter={[16, 16]}>
            {
              (data?.data || []).map(item => (
                <Col 
                  style={{ display: 'flex' }}
                  span={24} 
                  md={{ span: 12 }}>
                  <SignalProviderListItemCard item={item} />
                </Col>
              ))
            }
          </Row>
          <Flex>
            <Pagination
              showSizeChanger={false}
              total={data?.total}
              current={page}
              onChange={setPage}
            />
          </Flex>
        </Flex>
      )
    }
    {
      !isLoading && !data && <span>No Data</span>
    }
  </Card>
}