import { createContext, useContext, useEffect, useState } from "react"
import { ListSignalProvidersRequest, SignalProviderListItem } from "../../api/signal-providers"
import { PaginatedResponse } from "../../api/types"
import useSWR from 'swr';
import api from "../../api";

export enum AssetTypeFilter {
  All = 'all',
  Stocks = 'stocks',
  Options = 'options',
  Crypto = 'crypto',
}

export const assetTypeFilterLabels : Record<AssetTypeFilter, string> = {
  [AssetTypeFilter.All]: 'All Asset Types',
  [AssetTypeFilter.Options]: 'Options',
  [AssetTypeFilter.Stocks]: 'Stocks',
  [AssetTypeFilter.Crypto]: 'Crypto'
}

export type MarketplaceSearch = {

  assetType: AssetTypeFilter,
  setAssetType: (assetType: AssetTypeFilter) => void,

  query: string | null
  setQuery: (page: string | null) => void,

  page: number
  setPage: (page: number) => void,

  data?: PaginatedResponse<SignalProviderListItem>,
  isLoading: boolean,
  error: unknown,
}

const initialValues : MarketplaceSearch = {
  assetType: AssetTypeFilter.All,
  setAssetType: () => {},
  page: 1,
  setPage: () => {},
  isLoading: true,
  error: null,
  query: null,
  setQuery: () => {}
}

const MarketplaceSearchContext = createContext<MarketplaceSearch>(initialValues);

export function MarketplaceSearchContextProvider({
  children
} : {
  children: React.ReactNode,
}) {
  const [assetType, setAssetType] = useState<AssetTypeFilter>(AssetTypeFilter.All);
  const [page, setPage] = useState<number>(1);
  const [query, setQuery] = useState<string | null>(null);

  useEffect(() => {
    setPage(1);
  }, [assetType])

  useEffect(() => {
    setPage(1);
  }, [query]);
  
  const { data, isLoading, error } = useSWR(['/signal-providers', page, assetType, query], (key) => {

    const [_, page, assetType] = key;

    const request = new ListSignalProvidersRequest();

    if (assetType === AssetTypeFilter.Options) {
      request.options = true;
    }

    if (assetType === AssetTypeFilter.Stocks) {
      request.stocks = true;
    }

    if (assetType === AssetTypeFilter.Crypto) {
      request.crypto = true;
    }


    if (query !== null && query.length > 0) {
      request.q = query;
    }

    request.page = page;
    return api.signalProviders.index(request);
  });

  const value : MarketplaceSearch = {
    assetType,
    setAssetType,
    page,
    setPage,
    data,
    error,
    isLoading,
    query,
    setQuery,
  }

  return <MarketplaceSearchContext.Provider value={value}>
    { children }
  </MarketplaceSearchContext.Provider>
}

export const useMarketplaceSearch = () => useContext(MarketplaceSearchContext);
