
import axios from './axios';
import { BillingIntervalUnit } from './enums';
import { GenericIDResponse, GenericStatusResponse } from './types';

export interface CreateMembershipPlanRequest {
  name: string;
  signal_provider_id: number;
  billing_interval: number;
  billing_interval_unit: BillingIntervalUnit;
  billing_cycles: number;
  amount: number;
  stocks: boolean;
  options: boolean;
  crypto: boolean;
}

export interface MembershipPlanDetails {
  id: number
  name: string
  amount: number
  billing_interval: number
  billing_interval_unit : BillingIntervalUnit
  billing_cycles: number
  active: boolean
  stocks: boolean
  options: boolean
  crypto: boolean
}

export interface EditPriceRequest {
  new_price: number
}

const membershipPlansApi = {

  async create(request: CreateMembershipPlanRequest){
    const { data } = await axios.post<GenericIDResponse>(`/membership-plans` , request);
    return data;
  },

  async delete(id: number) {
    const { data } = await axios.delete<GenericStatusResponse>(`/membership-plans/${id}`);
    return data;
  },

  async findById(id: number) {
    const { data } = await axios.get<MembershipPlanDetails>(`/membership-plans/${id}`);
    return data;
  },

  async editPrice(id: number, request: EditPriceRequest) {
    const { data } = await axios.patch<GenericStatusResponse>(`/membership-plans/${id}/price`, request);
    return data;
  }
}

export default membershipPlansApi
