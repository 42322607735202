import { Descriptions, Modal, Typography } from "antd";
import { CryptoQuoteResponse } from "../../api/crypto-quote";
import { orderTypeLabels, sideLabels, timeInForceShortLabels } from "../../api/enum-labels";
import { CryptoAmountUnit, OrderType, Side, TimeInForce } from "../../api/enums";
import { useCryptoEstimate } from "../hooks/useCryptoEstimate";
import { displayCryptoSymbol } from "../utils/crypto";
import useSWR from 'swr';
import api from "../../api";

export function CryptoOrderConfirmationDialog({ 
  open,
  setOpen,
  onOk,
  symbol,
  side,
  amountUnit,
  amount,
  tif,
  orderType,
  limitPrice,
  stopPrice,
  quote,
} : {
  open: boolean,
  setOpen: (open: boolean) => void,
  onOk: () => void,
  symbol: string | null,
  side: Side | null,
  amountUnit: CryptoAmountUnit | null,
  amount: number | null,
  tif: TimeInForce | null,
  orderType: OrderType | null,
  limitPrice: number | null,
  stopPrice: number | null,
  quote: CryptoQuoteResponse | null,
}) {

  const {
    data: cryptoQuote,
  } = useSWR(
      symbol
        ? ['crypto-quote-confirm-dialog', symbol]
        : null,
    (key) => {
      return api.cryptoQuote.getQuote({ ticker_symbol: key[1] })
    },
    {
      refreshInterval: 100
    }
  )

  const estimate = useCryptoEstimate({
    amount: amount,
    unit: amountUnit,
    limitPrice: limitPrice,
    quote: cryptoQuote || quote || null,
  });

  return <Modal 
    title="Place Crypto Order?" 
    open={open} 
    onOk={onOk}
    onCancel={() => setOpen(false)}
    okText="Yes"
    cancelText="No"
  >

    {
      estimate && <div style={{ marginTop: 16, marginBottom: 16 }}>
        <Typography.Title level={5}>{ estimate.label }</Typography.Title>
        <span style={{ fontSize: '1.5rem' }}>
          { estimate.value }
        </span>
      </div>
    }
     <Descriptions title="Details" size="small" column={{ xs: 2, sm: 2, lg: 2, xl: 2, xxl: 2 }}>
      <Descriptions.Item label="Symbol">
        { displayCryptoSymbol(symbol || '') }
      </Descriptions.Item>
      {
        !!side && <Descriptions.Item label="Side">
          { sideLabels[side] }
        </Descriptions.Item>
      }
      {
        !!orderType && <Descriptions.Item label="Order Type">
          { orderTypeLabels[orderType] }
        </Descriptions.Item>
      }
      {
        !!amountUnit && !!amount && <Descriptions.Item label="Amount">
          {
            {
              [CryptoAmountUnit.Coin]: (symbol !== null) 
                ? displayCryptoSymbol(symbol) 
                : 'Coin',
              [CryptoAmountUnit.USD]: '$',
            }[amountUnit]
          }
          &nbsp;
          { amount }
        </Descriptions.Item>
      }
      {
        !!limitPrice && <Descriptions.Item label="Limit Price">
          { limitPrice }
        </Descriptions.Item>
      }
      {
        !!stopPrice && <Descriptions.Item label="Stop Price">
          { stopPrice }
        </Descriptions.Item>
      }
      {
        !!tif && <Descriptions.Item label="Time In Force">
          { timeInForceShortLabels[tif] }
        </Descriptions.Item>
      }
    </Descriptions>
  </Modal>
}