import { useAuthUser } from "../../auth/hooks/useAuthUser"
import { SignalProviderIntro } from "../../signal-providers/components/analyst-dashboard/SignalProviderIntro";
import { AnalystSignalProvidersListCard } from "../../signal-providers/components/analyst-dashboard/AnalystSignalProvidersListCard";
import { Space } from "antd";
import { StripeConnectSection } from "../components/StripeConnectSection";
import { MyCommunitiesCard } from "../../signal-providers/components/analyst-dashboard/MyCommunitiesCard";
import { AnalystPageHeader } from "../components/AnalystPageHeader";
import { useStripeConnectAccount } from "../../stripe-connect/hooks/useStripeConnectAccount";

export function SignalProviders() {
  const { user } = useAuthUser();
  const providers = (user?.signal_providers || [])
  const { setupComplete: hasConnectedStripe } = useStripeConnectAccount();

  if (providers.length <= 0) {
    return <SignalProviderIntro />
  }

  return <Space direction="vertical" style={{ width: '100%', margin: '1rem 0' }}>
    <AnalystPageHeader />

    {/* stripe card on top when stripe is not connected */}
    {
      !hasConnectedStripe && <StripeConnectSection/>
    }

    <AnalystSignalProvidersListCard />


    {/* stripe card at bottom when stripe is connected */}
    {
      hasConnectedStripe && <StripeConnectSection/>
    }

    <MyCommunitiesCard />
  </Space>
}


