import { PoweroffOutlined, SettingOutlined, UserOutlined, LoginOutlined, TeamOutlined } from "@ant-design/icons";
import { Button, Dropdown, MenuProps } from "antd";
import { Link } from "react-router-dom";
import { logout } from "../../auth/utils";
import { useAuthUser } from "../../auth/hooks/useAuthUser";
import { useMaskInfoContext } from "../context/MaskInfoContext";
import { useEmotionCss } from "@ant-design/use-emotion-css";
import { useState } from "react";

export const AccountDropdown = () => {

  const { user, initialized } = useAuthUser();
  const { masked } = useMaskInfoContext();
  const [loggingOut, setLoggingOut] = useState(false);

  const dropdownButtonClass = useEmotionCss(({ token }) => ({
    '&.ant-btn': {
      ' > span[data-name="true"]': {
        display: 'none',
        [`@media(min-width: ${token.screenSMMin}px)`]: {
          display: 'inline-block'
        }
      }
    }
  }))

  const onClick = async ({ key }: { key: string; }) => {
    if (key === 'logout') {
      try {
        setLoggingOut(true);
        await logout();
      } finally {
        setLoggingOut(false);
      }
    }
  };

  const items : MenuProps['items'] = [];

  if (initialized && user) {
    items.push({ key: 'my-account', label: <Link to={"/app/account"}>My Account</Link>, icon: <SettingOutlined /> })
    items.push({ key: 'membership', label: <Link to={"/app/account/candefi-memberships"}>My Membership</Link>, icon: <TeamOutlined/> });
    items.push({ key: 'logout', label: 'Logout', icon: <PoweroffOutlined /> });
  }

  if (initialized && user === null) {
    items.push({ key: 'login', label: <Link to={'/auth/login'}>Login</Link>, icon: <LoginOutlined /> });
  }

  return <Dropdown menu={{ onClick, items }}>
    <Button
      size="large"
      type="text"
      icon={<UserOutlined />}
      loading={loggingOut}
      className={dropdownButtonClass}
      >
        {
          !masked && (
            <span data-name="true">
              { user?.name || '' }
            </span>
          )
        }
    </Button>
  </Dropdown>;
};
