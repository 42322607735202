import { Button, Spin, Table, Typography } from 'antd';
import { useCallback, useEffect } from 'react';
import useSWRInfinite from 'swr/infinite';
import api from '../../api';
import { assetTypeLabels, orderTypeLabels, sideLabels, timeInForceShortLabels } from '../../api/enum-labels';
import { ListOrdersResponse, BrokerOrder, OrderCursorParams } from '../../api/orders';
import { ordersDateFormat } from '../../shared/constants';
import { useResponsiveTable } from '../../shared/styles/useResponsiveTable';
import { formatUnixTsInTZ, sideToAntTextType } from '../../shared/utils';
import useResponsiveTableInfiniteScroll from '../../shared/hooks/useResponsiveTableInfiniteScroll';
import * as orderUtils from '../utils/orders';

const { Column } = Table;
const getKey = (pageIndex: number, previousPageData: ListOrdersResponse | null) : [string, OrderCursorParams] | null => {

  const emptyCursorParams : OrderCursorParams = {
    robinhood_options_cursor: null,
    robinhood_stocks_cursor: null,
    webull_end_date_str: null,
    webull_last_create_time_0: null,
    webull_start_date_str: null,
    webullpay_crypto_cursor: null,
    robinhood_crypto_cursor: null,
  }
  // If there is no previous data, it's the first request, so we return the base URL with no params
  if (previousPageData === null) return ['/orders', emptyCursorParams];

  // If there are no more orders, we return null to indicate no further requests
  if (!previousPageData.orders.length || !previousPageData.has_more) return null;

  // Otherwise, we use the cursor params from the previous response for the next request
  return [
    '/orders',
    {
      webull_start_date_str: previousPageData.webull_start_date_str,
      webull_end_date_str: previousPageData.webull_end_date_str,
      webull_last_create_time_0: previousPageData.webull_last_create_time_0,
      robinhood_stocks_cursor: previousPageData.robinhood_stocks_cursor,
      robinhood_options_cursor: previousPageData.robinhood_options_cursor,
      webullpay_crypto_cursor: previousPageData.webullpay_crypto_cursor,
      robinhood_crypto_cursor: previousPageData.robinhood_crypto_cursor,
    },
  ];
};

function useOrders() {

  const orders = useSWRInfinite(getKey, (key) => {
    const request = key[1];
    return api.orders.listOrders(request);
  });

  const { isLoading, size, data } = orders;
  const isLoadingMore = isLoading || (size > 0 && data && typeof data[size - 1] === "undefined") || false;
  const hasMore = isLoadingMore || (!!data && data.length > 0 && data[data.length - 1].has_more)

  return { ...orders, isLoadingMore, hasMore };
}

export function OrderHistory() {

  const { 
    data, 
    size, 
    setSize, 
    isLoading, 
    isValidating,
    isLoadingMore,
    hasMore,
  } = useOrders();

  const loadMore = useCallback(() => {
    setSize(size => size + 1);
  }, [setSize])

  const orders = data 
    ? data.flatMap(page => page.orders) 
    : [];

  const responsiveTable = useResponsiveTable();

  const tableRef = useResponsiveTableInfiniteScroll(
    loadMore, 
    isLoadingMore, 
    hasMore
  );

  // Load more data if data has too few elements
  // 
  // Why?
  // When data has too few elements scroll event will not fire
  // and data for next page wont be fetched even if we have more data.
  // 
  useEffect(() => {
    if (isLoadingMore || !hasMore) {
      return
    }
    if (orders.length <= 10) {
      loadMore();
    }
  }, [orders.length, isLoadingMore, hasMore, loadMore]);

  const numColumns = 10;
  const columnHeight = 60;

  return <div>
    <Table
        ref={tableRef}
        dataSource={orders}
        loading={isLoading}
        pagination={false}
        className={`${responsiveTable} ${!hasMore ? 'end' : ''}`}
        rowKey={(record) => record.broker_order_id}
        style={{ overflow: "auto", maxHeight: columnHeight * numColumns }}
        footer={() => {
          if (isLoading) {
            return null;
          }
          if (isLoadingMore) {
            return <Spin></Spin>
          }
          if (!hasMore) {
            return null;
          }
        }}
      >
        <Column 
          title="Asset" 
          dataIndex="asset_type" 
          key="asset_type"
          render={(_, order : BrokerOrder) => assetTypeLabels[order.asset_type]}
        />
        <Column 
          title="Side" 
          dataIndex="side" 
          key="side" 
          render={
            (_, order: BrokerOrder) => {
              if (order.side === null) {
                return null;
              }

              const type = sideToAntTextType(order.side);

              return <Typography.Text type={type} >
                { sideLabels[order.side] }
              </Typography.Text>
            }
          } 
        />
        <Column 
          title="Symbol" 
          dataIndex="stock" 
          key="stock"
          render={(_, record: BrokerOrder) => (
            <span style={{ whiteSpace: 'nowrap' }}>
              {orderUtils.getFormattedSymbol(record)} 
            </span>
          )}
        />
        <Column 
          title="Type" 
          dataIndex="order_type" 
          key="order_type" 
          render={(_, order: BrokerOrder) => !!order.order_type ? orderTypeLabels[order.order_type] : ''}  
        />
        {/* <Column 
          title="Qty" 
          dataIndex="quantity" 
          key="quantity" 
          render={(_, order: Order) => orderUtils.getQuantity(order)}    
        /> */}
        <Column 
          // title="Filled Qty"
          title="Qty"
          dataIndex="filled_quantity" 
          key="filled_quantity" 
          render={(_, order: BrokerOrder) => orderUtils.getFormattedFilledQuantity(order)}
        />
        <Column 
          // title="Average Price" 
          title="Price" 
          dataIndex="average_price" 
          key="average_price" 
          render={(_, order: BrokerOrder) => orderUtils.getFormattedAverageFilledPrice(order)}   
        />``
        <Column 
          // title="Created At" 
          title="Created" 
          dataIndex="created_at" 
          key="created_at" 
          render={(_, order: BrokerOrder) => order.created_at !== null ? formatUnixTsInTZ(order.created_at, ordersDateFormat) : null}
        />
        <Column 
          // title="Filled At" 
          title="Filled" 
          dataIndex="filled_time" 
          key="filled_time" 
          render={(_, order: BrokerOrder) => order.filled_time !== null ? formatUnixTsInTZ(order.filled_time, ordersDateFormat) : null}
        />
        <Column 
          title="TIF" 
          dataIndex="time_in_force" 
          key="time_in_force" 
          render={(_, order : BrokerOrder) => !!order.time_in_force ? timeInForceShortLabels[order.time_in_force] : ''}  
        />
        <Column 
          title="Total" 
          key="total_amount"
          render={(_, order: BrokerOrder) => orderUtils.getFormattedApproxTotal(order)}
        />
        {/* <Column 
          title="Limit Price" 
          dataIndex="limit_price" 
          key="limit_price" 
          render={(_, order : Order) => !!order.limit_price ? formatMoney(order.limit_price, 3) : ''}    
        />
        <Column 
          title="Stop Price" 
          dataIndex="stop_price" 
          key="stop_price" 
          render={(_, order : Order) => !!order.stop_price ? formatMoney(order.stop_price, 3) : ''}      
        /> */}
      </Table>
  </div>
}