import { useEmotionCss } from "@ant-design/use-emotion-css";
import { Typography } from "antd";
import Flex from "../../shared/components/Flex";
import background from './maxim-hopman-fiXLQXAhCfk-unsplash.jpg'

export function AnalystPageHeader() {

  return <Flex
    alignItems='center'
    style={{
      width: '100%',
      padding: 32,
      borderRadius: 8,
      backgroundSize: 'cover',
      backgroundPosition: 'left center',
      backgroundImage: `url("${background}")`,
      position: 'relative',
      zIndex: 1,
      overflow: 'clip',
      // backgroundImage: 'radial-gradient(ellipse at -40% -40%,#fff 0,#8846db 22%,#311e58 39%,#241845 47%,#140d20 60%,#000 75%)'
    }}
  >
    <div style={{ position: 'absolute', zIndex: -1, inset: 0, backgroundColor: 'rgba(0,0,0,0.8)' }}></div>
    <div style={{ maxWidth: 600 }}>
      <Typography.Title style={{ margin: 0, color: 'white' }} level={1}>
        Signal Provider Hub
      </Typography.Title>
      <Typography.Paragraph style={{ 
        // @ts-ignore
        textWrap: 'balance',
        fontSize: '1.4rem', 
        marginTop: 16,
        color: 'white',
      }}>
        {/* Monetize your expertise and grow your subscriber base. Everything you need, right here on your dashboard */}
        Monetize Your Expertise and Grow Your Subscriber Base—All the Tools You Need in One Place
      </Typography.Paragraph>
    </div>
  </Flex>
}
