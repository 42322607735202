import { Button, Form, Input, Space } from "antd"
import { MailOutlined } from '@ant-design/icons';
import { useState } from "react";
import { ErrorMessage } from "../../../shared/components/ErrorMessage";
import { useWebullPayConnectContext } from "../../contexts/WebullPayConnectContext";

export const EnterEmail = () => {
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState<unknown>(null);
  const { changeEmail, sessionId } = useWebullPayConnectContext();


  return <div>
    <ErrorMessage error={error}/>
    <Form<{ email: string }> 
      layout="vertical"
      onFinish={async (values) => {
        try {
          setSubmitting(true);
          await changeEmail(values.email);
        } catch(e) {
          setError(e);
        } finally {
          setSubmitting(false)
        }
      }}>
      <Form.Item 
        label="Email" 
        name="email"
        rules={[
          { required: true, message: 'email is required' },
          { type: 'email', message: 'must be a valid email' },
        ]}
      >
        <Input 
          addonBefore={<MailOutlined/>}
        />
      </Form.Item>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <span></span>
        <Button loading={submitting} size="large" htmlType="submit" type="primary">
          Next
        </Button>
      </div>
    </Form>
  </div>
}