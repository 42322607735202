import { Typography } from 'antd';
import { SignalProvider } from "../../api/signal-providers";
const { Text } = Typography;

function getSignalProviderlink(provider: SignalProvider) {
  return new URL(`/app/signal-providers/${provider.username}`, window.location.origin).toString();
}

export function SignalProviderLink({ provider } : { provider: SignalProvider }) {
  const link = getSignalProviderlink(provider);

  return<Text copyable={{ text: link! }}>
    { link }
  </Text>
}
