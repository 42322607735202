import { Card, Col, Descriptions, Row, Table, Typography } from "antd";
import useSWR from 'swr';
import api from "../../api";
import { useState } from "react";
import { MyReferralsRequest } from "../../api/referrals";
import { referralStatusLabels } from "../../api/enum-labels";
import { UserRole } from "../../api/enums";
import { formatDate } from "../../shared/utils";
import { useAuthUser } from "../../auth/hooks/useAuthUser";
import { AnalystApplicationCard } from "../components/AnalystApplicationCard";
import { useAuthUserSignalProviders } from "../../signal-providers/hooks/useAuthUserSignalProviders";
import { getSignalProviderReferralLink, getUserReferralLink } from "../../referral/utils";

const { Title, Text } = Typography;

export const ReferralPage = () => {
  const { user } = useAuthUser();
  const isTrader = user?.role === UserRole.trader;

  return <Row gutter={[ 8, 8 ]}>
    <Col span={24}>
      <ReferralInfoCard />
    </Col>
    <Col span={24}>
      <ReferralCodeCard />
    </Col>
    <Col span={24}>
      <ReferralsTableCard />
    </Col>
    {
      isTrader && (
        <Col span={24}>
          <AnalystApplicationCard />
        </Col>
      )
    }
  </Row>
}

function ReferralInfoCard() {
  const coinsRender = (v: any) => `${v} - CandeFi Coins`;

  const {
    isLoading,
    data: info
  } = useSWR('/referrals/rewards-info/table', async () => {
    const info = await api.referrals.getRewardsInfo();
    return [
      { refer: 'Analyst', you_get: info.refer_analyst_coins, they_get: info.analyst_free_coins },
      { refer: 'Trader', you_get: info.refer_trader_coins, they_get: info.trader_free_coins },
    ]
  });


  return <Card loading={isLoading}>
    <Title level={4}>
      Refer and earn!
    </Title>
    {
      !!info && <div style={{
        marginBottom: 20
      }}>
        <Table
          pagination={false}
          columns={[
            { key: 'refer', title: 'Refer', dataIndex: 'refer' },
            { key: 'you_get', title: 'You Get', dataIndex: 'you_get', render: coinsRender, },
            { key: 'they_get', title: 'They Get', dataIndex: 'they_get', render: coinsRender },
          ]}
          dataSource={info}
        ></Table>
      </div>
    }
  </Card>
}

function ReferralCodeCard() {
  const { user } = useAuthUser();
  const { providers } = useAuthUserSignalProviders();

  const {
    isLoading,
    data
  } = useSWR('/referral/my-code', api.referrals.getMyCode);

  const userReferrerLink = !!data 
    ? getUserReferralLink(data.code)
    : '';

  const isAnalyst = user?.role === UserRole.analyst;
  const isTrader = user?.role === UserRole.trader;

  return <Card loading={isLoading}>
    <Title level={4}>
      Your Referral Link
    </Title>
    {
      !!data && isTrader && <Card>
        <Text style={{ fontSize: '1.2rem' }} copyable>
          { userReferrerLink }
        </Text>
      </Card>
    }
    {
      !!data && isAnalyst && <Descriptions bordered size="small" column={{ xs: 1, sm: 1, md: 1, lg: 1, xl: 1, xxl: 1 }}>
        <Descriptions.Item 
          label={
            <>
              Marketplace Link <br/>
              Redirects to Marketplace after signup.
            </>
          }
        >
          <Text copyable>
            { userReferrerLink }
          </Text>
        </Descriptions.Item>
        {
          providers.map((provider, i) => (
            <Descriptions.Item
            label={
              <>
                { provider.name } Link <br/>
                Redirects to { provider.name } signal provider page after signup.
              </>
            }>
              <Text copyable>
                { getSignalProviderReferralLink(provider, data.code) }
              </Text>
            </Descriptions.Item>
          ))
        }
      </Descriptions>
    }
  </Card>
}

function ReferralsTableCard() {
  const perPage = 10;
  const [page, setPage] = useState(1);

  const {
    data,
    isLoading,
  } = useSWR(['/referral/my-referrals', page, perPage], (key) => {
    const [_, page, perPage] = key;
    const request = new MyReferralsRequest();
    request.page = page;
    request.per_page = perPage;
    return api.referrals.getMyReferrals(request);
  });

  return <Card loading={isLoading}>
    <Title level={4}>
      Your Referrals
    </Title>
    {
      !!data && <Table
        pagination={{
          total: data.total,
          pageSize: perPage,
          current: page,
          onChange: (page) => {
            setPage(page);
          },
          showSizeChanger: false,
          hideOnSinglePage: true,
        }}
        columns={[
          { key: 'id', title: 'Id', dataIndex: 'id' },
          { key: 'coins', title: 'CandeFi - Coins', dataIndex: 'coins' },
          {
            key: 'status',
            title: 'Status',
            dataIndex: 'status',
            render: (value, record, index) => referralStatusLabels[record.status],
          },
          { 
            key: 'created_at', 
            title: 'Date', 
            dataIndex: 'created_at', 
            render: (value) => formatDate(value) 
          },
        ]}
        dataSource={data.data}
      ></Table>
    }
  </Card>
}
