import { Button } from "antd";
import { useEffect, useMemo, useState } from "react";
import api from "../../../api";
import { SignalProvider } from "../../../api/signal-providers";
import { ImageUploadPopup } from "../../../shared/components/ImageUploadPopup";
import { getImageResizeFunction } from "../../../shared/get-image-resize-function";
import { EditOutlined } from '@ant-design/icons'
import { getProfileImage } from "../../utils/get-profile-image";

type SignalProviderProfileImageEditProps = {
  provider: SignalProvider,
  onSuccess: () => void | Promise<void>,
}


export function SignalProviderProfileImageEdit({ provider, onSuccess }: SignalProviderProfileImageEditProps) {
  const [open, setOpen] = useState(false);

  // key to rest image upload popup state
  const [key, setKey] = useState(0);
  useEffect(() => {
    if (open) {
      setKey(k => k + 1);
    }
  }, [open]);

  // resize to 500 x 500 if image is larger
  const beforeUpload = useMemo(() => getImageResizeFunction(500, 500), []);

  const handleOnChange = async (key: string) => {
    await api.signalProviders.editProfileImage(provider.id, { image: key });
    setOpen(false);
    onSuccess();
  }

  return <div style={{ position: 'relative', width: 100, height: 100, background: '#ccc', borderRadius: '100%', overflow: 'clip', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
    {
      <img
        src={getProfileImage(provider, { variant: '100x100' })} 
        style={{ width: '100%', height: 'auto', backgroundSize: 'cover', position: 'absolute' }}
      />
    }
    <Button
      title="Edit Profile Image"
      type="ghost"
      size="large"
      style={{ color: '#444', fontSize: '20px' }}
      icon={<EditOutlined />}
      onClick={() => setOpen(true)}
    >
    </Button>

    <ImageUploadPopup
      key={key}
      open={open}
      onClose={() => setOpen(false)}
      croperProps={{ aspect: 1, modalTitle: 'Crop Image', modalWidth: '500px' }}
      uploadProps={{
        beforeUpload: beforeUpload,
      }}
      onChange={handleOnChange}
      title="Upload Profile Image"
    />
  </div>
}
