import { Radio } from "antd";
import { CryptoAmountUnit, Side } from "../../../api/enums";
import { useRadioGroupStyle } from "../../../shared/styles/useRadioGroup";
import { displayCryptoSymbol } from "../../utils/crypto";

export const CryptoAmountUnitInput = ({
  options,
  value,
  setValue,
  symbol,
  hidden = false,
} : {
  value: CryptoAmountUnit,
  options : CryptoAmountUnit[],
  setValue: (value: CryptoAmountUnit) => void,
  symbol: string | null,
  hidden?: boolean
}) => {
  // alert(symbol);
  const selectOptions = options.map((option) => ({
    label: {
      [CryptoAmountUnit.Coin]: symbol ? displayCryptoSymbol(symbol) : 'Coins',
      [CryptoAmountUnit.USD]: 'USD ($)',
    }[option],
    value: option,
  }))

  const radioClass = useRadioGroupStyle();
  return <Radio.Group
    style={{ display: hidden ? 'none' : undefined }}
    className={radioClass}
    options={selectOptions}
    optionType="button"
    buttonStyle="solid"
    size="large"
    onChange={(event) => setValue(event.target.value as CryptoAmountUnit)}
    value={value}
  />;
}