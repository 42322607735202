import { UsersAutotradePreference } from "../../../api/autotrade-preference";
import dayjs from 'dayjs';

/**
 * 
 * time type used by form.
 * Its only used in form not on server.
 * 
 */
export enum CryptoTimeType {
  allTime = 'all_time',
  setTime = 'set_time'
}

export const cryptoTimeTypeLabels: Record<CryptoTimeType, string> = {
  [CryptoTimeType.allTime]: '24 hours trading',
  [CryptoTimeType.setTime]: 'Set Time',
};

/**
 * Data type of forms input.
 * Fields in data don't map 1 to 1 with fields used in form.
 * 
 */
export type FormInput = Omit<UsersAutotradePreference, 'options_expiry_date' | 'autotrade_start_time' | 'autotrade_end_time' | 'crypto_start_time' | 'crypto_end_time'> & {
  options_expiry_date: dayjs.Dayjs | null;
  autotrade_start_time: dayjs.Dayjs | null;
  autotrade_end_time: dayjs.Dayjs | null;
  crypto_start_time: dayjs.Dayjs | null;
  crypto_end_time: dayjs.Dayjs | null;
  crypto_time_type: CryptoTimeType;
};


export const dayJSTimeFormat = "HH:mm";
export const dayJSDateFormat = "YYYY-MM-DD";

/**
 * Transforms autotrade prefrence into form input
 * 
 */
export function toFormInput(prefs: UsersAutotradePreference | null) {
  if (prefs === null) {
    return null;
  }
  const {
    options_expiry_date, autotrade_start_time, autotrade_end_time, crypto_start_time, crypto_end_time, ...rest
  } = prefs;

  let cryptoStartTime = !!crypto_start_time ? dayjs(crypto_start_time, dayJSTimeFormat) : null;
  let cryptoEndTime = !!crypto_end_time ? dayjs(crypto_end_time, dayJSTimeFormat) : null;

  const cryptoTimeType = cryptoEndTime && cryptoStartTime
    ? CryptoTimeType.setTime
    : CryptoTimeType.allTime;

  const crypto24Hours = cryptoTimeType === CryptoTimeType.allTime;

  if (crypto24Hours) {
    cryptoStartTime = null;
    cryptoEndTime = null;
  }

  const formInput: FormInput = {
    ...rest,
    options_expiry_date: !!options_expiry_date ? dayjs(options_expiry_date, dayJSDateFormat) : null,
    autotrade_start_time: !!autotrade_start_time ? dayjs(autotrade_start_time, dayJSTimeFormat) : null,
    autotrade_end_time: !!autotrade_end_time ? dayjs(autotrade_end_time, dayJSTimeFormat) : null,
    crypto_time_type: cryptoTimeType,
    crypto_start_time: cryptoStartTime,
    crypto_end_time: cryptoEndTime,
  };
  return formInput;
}

/**
 * Transforms form input to autotrade preference
 * 
 */
export function toPreference(formInput: FormInput) {
  const {
    options_expiry_date, autotrade_enabled, autotrade_start_time, autotrade_end_time, crypto_time_type, crypto_start_time, crypto_end_time, ...rest
  } = formInput;

  let cryptoStartTime = crypto_start_time?.format(dayJSTimeFormat) || null;
  let cryptoEndTime = crypto_end_time?.format(dayJSTimeFormat) || null;

  const crypto24Hours = crypto_time_type === CryptoTimeType.allTime;

  if (crypto24Hours) {
    cryptoStartTime = null;
    cryptoEndTime = null;
  }

  const prefs: UsersAutotradePreference = {
    options_expiry_date: options_expiry_date?.format(dayJSDateFormat) || null,
    autotrade_enabled: autotrade_enabled || false,
    autotrade_start_time: autotrade_start_time?.format(dayJSTimeFormat) || null,
    autotrade_end_time: autotrade_end_time?.format(dayJSTimeFormat) || null,
    crypto_start_time: cryptoStartTime,
    crypto_end_time: cryptoEndTime,
    ...rest,
  };
  return prefs;
}
