import { useEmotionCss } from "@ant-design/use-emotion-css"
import { Collapse } from "antd";
import { useRef, useState } from "react"
import { useNotificationClickEvent } from "../../notifications/hooks/use-notification-click-event";
import { ChatWidgetNew } from "./chat-widget-new";

export const ChatCollapsibleSection = ({
  title = 'Smart Chat',
  defaultOpen = true,
} : {
  title?: string,
  defaultOpen?: boolean
}) => {

  const [open, setOpen] = useState(defaultOpen);
  const ref = useRef<HTMLDivElement>(null);
  
  useNotificationClickEvent((data) => {
    // User clicked on notification

    // User clicked on new message notification
    // So we expand this section
    setOpen(true);

    // scroll this into view
    ref.current?.scrollIntoView();
  });

  const collapseStyle = useEmotionCss(({ token }) => ({
    '&.ant-collapse': {
      border: 'none',
      backgroundColor: token.colorBgContainer,
      '> .ant-collapse-item': {
        borderBottom: 'none',
      },
      '.ant-collapse-content': {
        borderTop: 'none',
        '> .ant-collapse-content-box': {
          padding: 0,
        }
      }
    }
  }));

  return <div ref={ref}>
    <Collapse
      className={collapseStyle}
      activeKey={open ? '1' : '0'}
      onChange={(key) => {
        
        // get the first key
        let firstKey : string | null = null;
        if (Array.isArray(key) && key.length > 0) {
          firstKey = key[1];
        }
        if (typeof key === 'string') {
          firstKey = key;
        }

        setOpen(firstKey === '1');
      }}
    >
      <Collapse.Panel  header={title} key={'1'}>
        <ChatWidgetNew />
      </Collapse.Panel>
    </Collapse>
  </div>
}