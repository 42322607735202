import { CanceledError, isCancel } from "axios";

export class NetworkError extends Error {
  constructor() {
    super('Could not connect to server');
  }
}

export class ApiError extends Error {
  httpStatusCode : number

  constructor(
    httpStatusCode: number,
    message: string 
  ) {
    super(message);
    this.httpStatusCode = httpStatusCode
  }
}

export interface ValidationError {
  loc: string[],
  msg: string,
  type: string
}

export interface WebullError {
  code: string | null,
  msg: string | null,
  traceId: string | null, 
}

export const getValidationErrorMessage = (e : ValidationError) : string => {
  let message = '';
  if (e.loc.length > 1) {
    message += e.loc.slice(1).join(" ") + " ";
  }
  message += e.msg;
  return message;
}

export class ApiValidationError extends ApiError {

  validationError: ValidationError[] | string | null

  constructor(
    httpStatusCode: number,
    data: any,
  ) {

    let validationError : ValidationError[] | null | string = null;
    let messageStr = '';

    if (data && data.detail && Array.isArray(data.detail)) {
      validationError = data.detail as ValidationError[]
      let messages = (data.detail as ValidationError[]).map(getValidationErrorMessage);
      messageStr = messages.join(" ");
    }

    if (data && data.detail && typeof(data.detail) === "string") {
      validationError = data.detail as string;
      messageStr = data.detail;
    }

    super(httpStatusCode, messageStr);
    this.validationError = validationError;
  }
}

export class ApiWebullError extends ApiError {
  
  webullError : WebullError | null

  constructor(
    httpStatusCode: number,
    data: any,
  ) {
    let message = data.detail as string;
    let webullError = null;
    if (data.webull) {
      webullError = data.webull as WebullError
    }
    super(httpStatusCode, message);
    this.webullError = webullError
  }
}

export default  function transformError(error : any) : any {
  if (error instanceof CanceledError) {
    throw error;
  }

  if (error.response) {
    let status = error.response.status as number;
    let data = error.response.data as any;
    if (data && data.detail && typeof(data.detail) === 'string' && data.webull) {
      throw new ApiWebullError(status, data);
    }
    if (data && data.detail && typeof(data.detail) === 'string') {
      throw new ApiError(status, data.detail);
    }
    if (data && data.detail && Array.isArray(data.detail)) {
      throw new ApiValidationError(status, data);
    }
    throw new ApiError(status, 'Something went wrong')
  }
  // NetworkError
  throw new NetworkError();
}