import { createContext, useCallback, useContext, useEffect, useState } from "react"
import api from "../../api";
import { GenericStatusResponse } from "../../api/types";
import { ImageExtraInfo, isMFAImageResponse, isMFAImageStatusResponse, SecurityQuestion } from "../../api/webull-pay-connect";
import { useRefreshUser } from "../../auth/hooks/useRefreshUser";

export enum WebullPayConnectStep {
  Email = 'email',
  MFAImage = 'check_mfa_image',
  MFAQuestion = 'check_mfa_question',
  PasswordAndPin = 'password_and_pin',
  Success = 'success'
}

export interface WebullPayConnectContextState {
  currentStep: WebullPayConnectStep
  sessionId: number | null,
  newDevice: boolean | null,
  email: string | null,
  mfaImage: ImageExtraInfo | null,
  securityQuestion: SecurityQuestion | null,
  changeEmail: (email : string) => Promise<void>,
  checkMFAImage: (xPos : number) => Promise<boolean>,
  fetchAlternativeSecurityQuestion: () => Promise<void>,
  checkSecurityQuestionAnswer: (answer: string) => Promise<boolean>,
  login: (tradingPin: string, password: string) => Promise<GenericStatusResponse>,
}

export const WebullPayConnectContext = createContext<WebullPayConnectContextState>({
  currentStep: WebullPayConnectStep.Email,
  sessionId: null,
  newDevice: null,
  email: null,
  mfaImage: null,
  securityQuestion: null,
  changeEmail: async () => {},
  checkMFAImage: async () => { return false },
  fetchAlternativeSecurityQuestion: async () => {},
  checkSecurityQuestionAnswer: async () => { return false },
  login: async (tradingPin: string, password: string) => ({ success: false, message: 'not implemented' }),
});

export const WebullPayConnectContextProvider = ({ children } : {
  children: React.ReactNode
}) => {

  const [step, setStep] = useState<WebullPayConnectStep>(WebullPayConnectStep.Email);
  const [sessionId, setSessionId] = useState<number | null>(null);
  const [email, setEmail] = useState<string | null>(null);
  const [mfaImage, setMFAImage] = useState<ImageExtraInfo | null>(null);
  const [xPos, setXPos] = useState<number | null>(null);
  const [newDevice, setNewDevice] = useState<boolean | null>(null);
  const [securityQuestion, setSecurityQuestion] = useState<SecurityQuestion | null>(null);
  const [securityQuestionAnswer, setSecurityQuestionAnswer] = useState<string | null>(null);

  const refetchUser = useRefreshUser();

  // fetch mfa security question
  const fetchSecurityQuestion = useCallback(async (alternate : boolean = false) => {
    if (sessionId === null || email === null) {
      return;
    }
    const question = await api.webullPayConnect.getSecurityQuestion(sessionId, { 
      email,
      alternate: alternate
    });
    setSecurityQuestion(question);
  }, [sessionId, email]);

  // fetch alternate mfa security question
  const fetchAlternativeSecurityQuestion = useCallback(() => {
    return fetchSecurityQuestion(true);
  }, [fetchSecurityQuestion]);


  // check mfa jigsaw puzzle image solution
  const checkMFAImage = async (xPos : number) => {
    if (sessionId === null || email === null) {
      return false;
    }

    const { response } = await api.webullPayConnect.checkMFAImage(sessionId, {
      email,
      xPos
    });
    
    // image verified
    if (isMFAImageStatusResponse(response) && response.result) {
      setXPos(xPos);
      setStep(WebullPayConnectStep.MFAQuestion);
      return true;
    }

    if (isMFAImageResponse(response)) {
      setMFAImage(response);
      return false;
    }

    return false;
  }

  // set email for the session
  const changeEmail = async (email : string) => {
    const { id, new_device, image } = await api.webullPayConnect.createSession({ email });
    setEmail(email);
    setSessionId(id);
    setNewDevice(new_device)
    setMFAImage(image);
    setStep(WebullPayConnectStep.MFAImage);
    // const nextStep = new_device  ? WebullPayConnectStep.MFAImage : WebullPayConnectStep.PasswordAndPin
    // setStep(nextStep)
  }

  // check security question answer 
  const checkSecurityQuestionAnswer = useCallback(async (answer: string) => {
    if (sessionId === null || email === null || securityQuestion === null) {
      return false;
    }
    const response = await api.webullPayConnect.checkSecurityAnswer(sessionId, {
      email,
      questionId: securityQuestion!.questionId,
      answer: answer,
    });
    if (response.success) {
      setSecurityQuestionAnswer(answer);
      setStep(WebullPayConnectStep.PasswordAndPin);
    }
    return response.success; 
  }, [sessionId, email, securityQuestion]);

  // login
  const login = async (password: string, tradingPin: string) : Promise<GenericStatusResponse> => {
    // session id and email are required
    if (sessionId === null || 
      email === null
    ) {
      return { success: false, message: 'Please complete all the steps' };
    }
    
    // login for new device
    if (newDevice) {
      if (securityQuestion === null 
          || securityQuestionAnswer === null 
          || xPos === null
      ) {
        return { success: false, message: 'Please complete all the steps' };
      }
      let response = await api.webullPayConnect.login(sessionId, {
        email,
        password,
        security_question_answer: securityQuestionAnswer,
        security_question_id: securityQuestion.questionId,
        trading_pin: tradingPin,
        x_pos: xPos.toFixed(0)
      });
      if (response.success) {
        refetchUser();
        setStep(WebullPayConnectStep.Success);
      }
      return response;
    }

    // login for existing devices
    let response = await api.webullConnect.loginShort(sessionId, {
      email: email,
      password: password,
      trading_pin: tradingPin
    })
    if (response.success) {
      refetchUser();
      setStep(WebullPayConnectStep.Success);
    }
    return response;
  }
  

  // perform actions on when current step changes
  useEffect(() => {
      if (step === WebullPayConnectStep.MFAQuestion) {
        fetchSecurityQuestion();
      }
    }, [step, fetchSecurityQuestion]);

  const value = {
    currentStep: step,
    sessionId,
    email,
    mfaImage,
    changeEmail,
    checkMFAImage,
    newDevice,
    securityQuestion,
    fetchAlternativeSecurityQuestion,
    checkSecurityQuestionAnswer,
    login
  } satisfies WebullPayConnectContextState;

  return <WebullPayConnectContext.Provider value={value}>
    {children}
  </WebullPayConnectContext.Provider>
}

export const useWebullPayConnectContext = () => {
  return useContext(WebullPayConnectContext);
}