import { useEffect } from "react";
import { FirebaseNotificationData } from "../../api/extra-types/firebase-notification-data";
import { isCandefiNotificationClickEvent } from "../../service-worker-events/CandefiNotificationClickEvent";

export function useNotificationClickEvent(onNotificationClick: (payload: FirebaseNotificationData) => void) {

  useEffect(() => {

    const serviceWorkerListener = (e: MessageEvent<any>) => {
      const data = e.data;  
      
      if (!isCandefiNotificationClickEvent(data)) {
        return
      }

      // data has just the notification's payload
      const eventData = data.data;
      onNotificationClick(eventData);
    }

    navigator.serviceWorker.addEventListener('message', serviceWorkerListener);

    return () => {
      navigator.serviceWorker.removeEventListener('message', serviceWorkerListener)
    }

  }, [onNotificationClick]);

  return null;
}