import { Card, Space } from "antd";
import { useMemo } from "react";
import { useParams } from "react-router-dom"
import { useAuthUserSignalProviders } from "../../signal-providers/hooks/useAuthUserSignalProviders";
import { SignalProviderHeaderCard } from "../components/SignalProviderHeaderCard";
import { StripeConnectSection } from "../components/StripeConnectSection";
import { AnalystSignalProviderPlansSection } from "../../signal-providers/components/analyst-dashboard/AnalystSignalProviderPlansSection";
import { AnalystSignalProviderTeamSection } from "../../signal-providers/components/analyst-dashboard/AnalystSignalProviderTeamSection";
import { SignalProviderType } from "../../api/enums";
import { SignalProviderCoverImageEdit } from "../../signal-providers/components/analyst-dashboard/SignalProviderCoverImageEdit";
import { SignalProviderProfileImageEdit } from "../../signal-providers/components/analyst-dashboard/SignalProviderProfileImageEdit";

export function SignalProviderDetailPage() {
  const { signalProviderId } = useParams();

  const { providers, refresh } = useAuthUserSignalProviders();

  const id = useMemo(() => {
    const parsed = parseInt(signalProviderId || '')
    return isNaN(parsed) ? null : parsed;
  }, [signalProviderId]);

  const provider = providers.find(provider => provider.id === id) || null;
  if (provider === null) {
    return null;
  }
  const isCommunity = provider.type === SignalProviderType.Community;

  return <div>
    <Space direction="vertical" style={{ width: '100%', margin: '1rem 0' }}>
      <SignalProviderCoverImageEdit
        provider={provider}
        onSuccess={refresh}
      />
      <SignalProviderHeaderCard 
        signalProvider={provider} 
        onEditSuccess={refresh}
      />
      {
        isCommunity && (
          <AnalystSignalProviderTeamSection provider={provider} />
        )
      }
      <AnalystSignalProviderPlansSection provider={provider} />
    </Space>
  </div>
}