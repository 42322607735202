import { Card, List } from "antd";
import { useEffect, useMemo, useState } from "react";
import { useStretchedTabs } from "../../shared/styles/useStretchedTabs";
import { TradeOptions } from "./TradeOptions";
import { TradeStocks } from "./TradeStocks";
import { AssetType, UserRole } from "../../api/enums";
import { useAuthUser } from "../../auth/hooks/useAuthUser";
import { TradeCrypto } from "./TradeCrypto";
import { SupportsAsset } from "./SupportsAsset";
import { useSupportedAssetTypes } from "../hooks/useSupportedAssetTypes";

export enum QuickTradeTabKeys {
  Options = "options",
  Stocks = "stocks",
  Crypto = "crypto",
}

export const quickTradeTabKeysLabels : Record<QuickTradeTabKeys, string> = {
  [QuickTradeTabKeys.Options]: 'Options',
  [QuickTradeTabKeys.Stocks]: 'Stocks',
  [QuickTradeTabKeys.Crypto]: 'Crypto',
}

const tabToAssetType : Record<QuickTradeTabKeys, AssetType> = {
  [QuickTradeTabKeys.Options]: AssetType.Options,
  [QuickTradeTabKeys.Stocks]: AssetType.Stocks,
  [QuickTradeTabKeys.Crypto]: AssetType.Crypto,
}

export const QuickTrade = ({
  syncWithWatchlist = false,
  title = 'Smart Trader',
}: {
  syncWithWatchlist?: boolean,
  title?: string,
}) => {
  // asset types supported by currently selected broker
  const supportedAssetTypes = useSupportedAssetTypes();

  // selected tab
  const [tab, setTab] = useState<QuickTradeTabKeys>(QuickTradeTabKeys.Options);

  const allTabs = [ QuickTradeTabKeys.Options, QuickTradeTabKeys.Stocks, QuickTradeTabKeys.Crypto ];
  
  const supportedTabs = allTabs.filter(tab => {
    const assetType = tabToAssetType[tab];
    const supported = supportedAssetTypes.includes(assetType);
    return supported
  });

  // Preselect the first supported tab
  useEffect(() => {
    // current broker does not support any asset type
    if (supportedTabs.length <= 0) {
      return;
    }
    // tab is already supported
    if (supportedTabs.includes(tab)) {
      return
    }
    // select the first suported tab
    setTab(supportedTabs[0]);
  }, [supportedTabs, tab, setTab]);

  const stretchedTabs = useStretchedTabs();

  const noTabsSupported = supportedTabs.length <= 0;

  if (noTabsSupported) {
    return null;
  }

  return (
    <Card
      style={{ width: '100%' }}
      title={title}
      size="small"
      className={stretchedTabs}
      tabList={
        supportedTabs.map(tab => ({ key: tab, tab: quickTradeTabKeysLabels[tab] })) 
      }
      onTabChange={(tab) => setTab(tab as QuickTradeTabKeys)}
      tabProps={{
        size: "middle",
        centered: true,
      }}
    >
      {
        tab === QuickTradeTabKeys.Options && (
          <SupportsAsset type={AssetType.Options}>
            <TradeOptions syncWithWatchlist={syncWithWatchlist} />
          </SupportsAsset>
        )
      }
      {
        tab === QuickTradeTabKeys.Stocks && (
          <SupportsAsset type={AssetType.Stocks}>
            <TradeStocks syncWithWatchlist={syncWithWatchlist} />
          </SupportsAsset>
        )
      }
      {
        tab === QuickTradeTabKeys.Crypto && (
          <SupportsAsset type={AssetType.Crypto}>
            <TradeCrypto syncWithWatchlist={syncWithWatchlist} />
          </SupportsAsset>
        )
      }
    </Card>
  );
};
