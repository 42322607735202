import { CanceledError } from "axios";
import api from "../../../api";
import { createAppAsyncThunk } from "../../../redux/hooks";
import { updateChatroomUnreadMessageCount } from "../chat.slice";

const abortControllers = new Map<string, AbortController>();

export const fetchChatroomUnreadMessageCount = createAppAsyncThunk(
  'chat/fetchChatroomUnreadMessageCount',
  async function (chatroomId: string, { dispatch }) {

      // abort previous api calls for the same chatroom 
      let oldAbortController = abortControllers.get(chatroomId);
      if (oldAbortController) {
        oldAbortController.abort();
      }

      // create new abort controller
      let abortController = new AbortController();
      abortControllers.set(chatroomId, abortController);

      try {

        const count = await api.chat.getChatroomUnreadMessageCount(chatroomId, abortController.signal);

        // signal aborted dont update the state
        if (abortController.signal.aborted) {
          return;
        }

        // update state
        dispatch(
          updateChatroomUnreadMessageCount({
            chatroomId,
            count: count?.count || 0
          })
        );
  
      } catch (e) {
        // request cancelled
        if (e instanceof CanceledError) {
          return
        }
        console.log("Failed to fetch chatrooms unread message count");
        console.log(e);
  
      }
  }
);
