import { Button, message } from "antd";
import { DeleteOutlined } from '@ant-design/icons';
import { useWatchList } from "../context/watchlist-context";
import { useState } from "react";
import api from "../../api";
import { getErrorMessage } from "../../shared/utils";

export function ClearWatchlistButton() {
  const { selectedListId, items } = useWatchList();
  const [processing, setProcessing] = useState(false);
  const [ messageApi, contextHolder ] = message.useMessage();
  
  const onClear = async () => {
    try {
      setProcessing(true);
      if (selectedListId === null) {
        await api.watchlistV2.deleteAllDefaultWatchlistItems();
      } else {
        await api.watchlistV2.deleteAllWatchlistItems(selectedListId)
      }
      items.reload();
    } catch(e) {
      messageApi.error(getErrorMessage(e));
    } finally {
      setProcessing(false);
    } 
  }

  return <Button
    loading={processing}
    type='dashed'
    danger
    icon={<DeleteOutlined />}
    onClick={(e) => {
      e.preventDefault();
      onClear();
    }}
  >
    { contextHolder }
    Clear
  </Button>
}
