import { Button, Col, Form, message, Row, RowProps, Switch } from "antd";
import api from "../../api";
import { ErrorMessage } from "../../shared/components/ErrorMessage";
import { useEmotionCss } from "@ant-design/use-emotion-css";
import { CSSProperties, useState } from "react";
import { Preference, UpdatePreferenceRequest } from "../../api/preference";
import { useRefreshUser } from "../../auth/hooks/useRefreshUser";

/**
 * payload of data stored in form.
 * right now it maps directly with Preference used by api this could change later.
 * also separating allows to set default preference
 */
type PreferenceFormValues = {
  notifications_enabled: boolean
}

function toPreferenceFormValues(pref: Preference | null): PreferenceFormValues {
  if (!pref) {
    return {
      notifications_enabled: true,
    }
  }
  return { ...pref }
}

function toUpdatePreferenceRequest(values: PreferenceFormValues): UpdatePreferenceRequest {
  return { ...values }
}

const rowProps: RowProps = { gutter: 16 }
const formItemStyle: CSSProperties = { marginBottom: 0 };

export const PreferenceForm = ({
  pref
}: {
  pref: Preference | null
}) => {

  const [submitting, setSubmitting] = useState<boolean>(false);
  const [error, setError] = useState<any>(null);
  const [messageApi, contextHolder] = message.useMessage();
  const refresh = useRefreshUser();

  const [form] = Form.useForm<PreferenceFormValues>();
  const initialValues: PreferenceFormValues = toPreferenceFormValues(pref)

  const onFinish = async (formValues: PreferenceFormValues) => {
    try {

      setError(null);
      setSubmitting(true);
      const request = toUpdatePreferenceRequest(formValues);
      await api.preference.update(request);
      messageApi.success("Updated");
      refresh();

    } catch (e) {

      messageApi.error("something went wrong.");
      setError(e);

    } finally {

      setSubmitting(false);

    }
  }


  const switchFormItemClass = useEmotionCss(({ theme, token }) => ({
    '& .ant-row.ant-form-item-row': {
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      '& .ant-col': {
        'width': 'auto',
        '&.ant-form-item-label': {
          flexGrow: 1,
          '& label': {
            width: '100%',
          },
        },
        '&.ant-form-item-control': {
          flexGrow: 0
        }
      }
    }
  }));

  return <>
    {contextHolder}
    {
      error && <ErrorMessage error={error} />
    }
    <Form
      form={form}
      layout="vertical"
      initialValues={initialValues}
      onFinish={onFinish}
      autoComplete="off"
    >

      <Row {...rowProps}>
        <Col span={24}>
          <Form.Item
            className={switchFormItemClass}
            label='Notifications Enabled'
            style={formItemStyle}
            name="notifications_enabled"
            valuePropName="checked">
            <Switch />
          </Form.Item>
        </Col>
      </Row>
  
      <div style={{ marginBottom: 24 }}></div>

      <Row {...rowProps}>
        <Col span={24}>
          <Button 
            block 
            disabled={submitting}
            loading={submitting}
            htmlType="submit" 
            type="primary" 
            size="large" 
          >
            Save
          </Button>
        </Col>
      </Row>
  
    </Form>
  </>
}