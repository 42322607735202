import axios from "./axios"


export type GetCryptoQuoteRequest = {
  ticker_symbol: string
}

export type CryptoQuoteResponse = {
  ask: number | null
  bid: number | null
  last: number | null
}


const cryptoQuoteApi = {
  async getQuote(request : GetCryptoQuoteRequest) {
    const { data } = await axios.get<CryptoQuoteResponse | null>(
      "/crypto-quote",
      {
        params: request,
        needsBrokerId: true,
      }
    )
    return data;
  },

}

export default cryptoQuoteApi;
