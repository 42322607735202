import { useState } from "react";
import useSWR from 'swr';
import api from "../../../api";
import { Select, SelectProps } from "antd";
import { UserRole } from "../../../api/enums";

export const UsernameAutocomplete = ({
  setValue,
  role,
  ...props
}: {
  setValue: (value: string | null) => void;
  role?: UserRole
} & SelectProps) => {

  const [query, setQuery] = useState<string>('');

  const {
    data, 
    isLoading,
  } = useSWR(
    (query !== null && query.length > 1) ? ['/api/users/search', query, role] : null,
    (key) => {
      const [_, query, role] = key;
      return api.users.search({
        query,
        role: role || null,
      })
    }
  );

  const selectOptions = data 
    ? data.map(data => ({ label: `${data.name} @${data.username}`, value: data.username }))
    : [];

  return <Select
    showSearch={true}
    searchValue={query}
    filterOption={false}
    onChange={(value) => setValue(value)}
    onSearch={(newValue) => setQuery(newValue || '')}
    options={selectOptions}
    loading={isLoading}
    {...props}
  />;
};
