import { Card, Input, Select } from "antd"
import { useEffect, useState } from "react";
import { useDebounceValue } from "usehooks-ts";
import Flex from "../../shared/components/Flex";
import { AssetTypeFilter, assetTypeFilterLabels, useMarketplaceSearch } from "./MarketplaceSearchContext"
import { SearchOutlined } from '@ant-design/icons';

export const MarketplaceSearchAndFilterCard = () => {

  const { assetType, setAssetType, query, setQuery } = useMarketplaceSearch();
  
  // get select options
  const options = Object.values(AssetTypeFilter)
    .map(option => ({ 
      value: option, 
      label: assetTypeFilterLabels[option] 
    }))

  return <Card size="small">
    <Flex justifyContent='space-between' flexWrap="wrap" gap={8}>
      <div>
        <SearchInput />
      </div>
      <Select
        style={{ width: 150 }}
        options={options}
        value={assetType}
        onChange={(value) => setAssetType(value)}
      />
    </Flex>
  </Card>
}

const SearchInput = () => {
  const { query, setQuery } = useMarketplaceSearch();

  const [localQuery, setLocalQuery] = useState(query);
  const [debouncedQuery, _] = useDebounceValue(localQuery, 500);

  useEffect(() => {
    setQuery(debouncedQuery || null)
  }, [debouncedQuery]);


  return <Input
    addonBefore={<SearchOutlined/>}
    placeholder="Search"
    value={localQuery || ''}
    onChange={(e) => setLocalQuery(e.target.value || null)}
  />
}