import { Card, Space, Tag, Typography } from "antd";
import api from "../../api";
import useSWR from "swr";
import { useParams } from "react-router-dom";
import SignalProviderPlans from "../components/SignalProviderPlans";
import { EmailVerifiedMessage } from "../../auth/components/EmailVerifiedMessage";
import { SignalProviderTypeTag } from "../components/SignalProviderTypeTag";
import Flex from "../../shared/components/Flex";
import { SignalProviderCoverImage } from "../components/SignalProviderCoverImage";
import { ErrorMessage } from "../../shared/components/ErrorMessage";
import { SignalProviderBasicInfoCard } from "../components/SignalProviderBasicInfoCard";
import { SignalProviderAboutCard } from "../components/SignalProviderAboutCard";
const { Title, Paragraph, Text } = Typography;

export const SignalProviderDetails = () => {
  const { username } = useParams();


  const { data : provider, isLoading, error } = useSWR(`/api/signal-providers/${username}`, () =>
    api.signalProviders.findByUsername(username || '')
  );

  if (isLoading) {
    return <Card loading></Card>
  }

  if (!provider || error) {
    return <ErrorMessage error={error || 'Provider not found'} />
  } 

  return (
    <Flex 
      flexDirection="column"
      gap={16}
    >
      <EmailVerifiedMessage />
      <SignalProviderCoverImage provider={provider} />
      <SignalProviderBasicInfoCard provider={provider} />
      <SignalProviderAboutCard provider={provider} />
      <Card title='Plans'>
        <SignalProviderPlans  signalProvider={provider} />
      </Card>
      {/* <Card loading={isLoading}>
        <Space size="small" direction="vertical">
          <Space>
            <Title level={3} style={{ marginBottom: 0 }}>{signalProvider?.name}</Title>
            {
              signalProvider?.type && (
                <SignalProviderTypeTag type={signalProvider.type} />
              )
            }
          </Space>
          <Text type="secondary">@{ signalProvider?.username }</Text>
          <Paragraph type="secondary" style={{ marginBottom: 0 }}>{signalProvider?.bio}</Paragraph>
        </Space>
      </Card>
      <Card title="Plans" loading={isLoading}>
        {
          signalProvider?.membership_plans && (
            <SignalProviderPlans
              signalProvider={signalProvider}
            />
          )
        }
      </Card> */}
    </Flex>
  );
};
