import { useMemo } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import useSWR from 'swr';
import api from "../../api";

export function useReferralInfo() {

  const { referralCode } = useParams();
  const [queryParams, _] = useSearchParams();

  const { data, isLoading, error } = useSWR(
      referralCode === undefined
        ? null
        : ['referral', referralCode],
      (key) => {
        const code = key[1];
        return api.referrals.getCodeInfo(code);
      }
  );

  const destination = useMemo(() => {
    // if we dont have referral data
    // we redirect user to dashboard after signup
    if (data === null || data === undefined) {
      return '/app/dashboard';
    }

    // redirect user to dest param if it's specified
    if (queryParams.get('dest') !== null) {
      return queryParams.get('dest');
    }

    // redirect user to marketplace
    return '/app/marketplace';

  }, [ queryParams.get('dest'), data ]);

  const signalProviderUsername = useMemo(() => {

    const urlRegex = /^\/app\/signal-providers\/(.+)\/?$/;
    if (destination === null) {
      return null;
    }

    const match = destination.match(urlRegex);
    if (match === null || match.length <= 0) {
      return null;
    }

    return match[1];

  }, [destination])

  return {
    referralCode: referralCode || null,
    isLoading,
    data: data || null,
    error,
    destination,
    signalProviderUsername
  }
}