import { Typography } from "antd";
import { WebullPayAccountBasicInfo } from "../../api/account-info";
import { fixDecimalPlaces, formatMoneyAuto } from "../../shared/utils";
import styles from "./account-details.module.css";
const { Title, Text } = Typography;

const WebullPayAccountDetails = ({ info }: { info: WebullPayAccountBasicInfo }) => {
  const { capital, account } = info;

  const cashBalance = capital.cashBal;
  const buyingPower = capital.purchasingAmt;
  const positionValue = capital.totalMarketValue;
  const unrealizedPL = account.unrealizedPL;
  const realizedPL = account.realizedPL;
  const totalPL = account.totalPL;

  return <>
    <div>
      <Title level={5}>Purchasing Power</Title>
      <NumberSection
        title="Position Value"
        number={positionValue}
      />
      <NumberSection
        title="Cash Balance"
        number={cashBalance}
      />
      <NumberSection
        title="Buying Power"
        number={buyingPower}
      />
      <Title level={5} style={{ marginTop: 16 }}>P/L</Title>
      <NumberSection
        title="Unrealized P/L"
        number={unrealizedPL}
      />
      <NumberSection
        title="Realized P/L"
        number={realizedPL}
      />
      <NumberSection
        title="Total P/L"
        number={totalPL}
      />
    </div>
  </>
};

function NumberSection({
  title,
  number,
}: {
  title: string,
  number: number | null
}) {
  if (number === null) {
    return null;
  }
  return <div className={styles.row}>
    <Text type="secondary">{title}</Text>
    {
      !isNaN(number) && (
        <Text type="success">
          {formatMoneyAuto(fixDecimalPlaces(number))}
        </Text>
      )
    }
  </div>
}

export default WebullPayAccountDetails;
