import getStore from "./store";
import selectedBrokerRepo from '../brokers/repos/selected-broker-repo';
import { maskInfoRepo } from "../shared/context/mask-info-repo";
import { deleteToken, getToken,  } from "firebase/messaging";
import { messaging } from "../firebase/messaging";
import { getFCMToken } from "../firebase/messaging-utils";
import api from "../api";

export async function logout() {
  const store = getStore();
  
  try {
    // get token
    const token = await getFCMToken();

    // delete from server
    await api.fcmTokens.deleteToken(token);

  } catch(e) {

    // ignore
    console.error("could not delete firebase token");
    console.error(e);

  }

  await Promise.all([
    store.setUser(null),
    store.setTokens(null),
    selectedBrokerRepo.removeBroker(),
    maskInfoRepo.setMasked(false),
    deleteToken(messaging),
  ]);
}
