import { Button, Card, Col, notification, Row } from "antd";
import { useState } from "react";
import { useAuthUserSignalProviders } from "../../hooks/useAuthUserSignalProviders";
import { CreateSignalProviderModal } from "./CreateSignalProviderModal";
import { PlusOutlined } from '@ant-design/icons';
import { AnalystSignalProviderListItem } from "./AnalystSignalProviderListItem";

export function AnalystSignalProvidersListCard() {
  const [notificationApi, contextHolder] = notification.useNotification();

  const [createFormOpen, setCreateFormOpen] = useState(false);
  
  const { refresh, providers } = useAuthUserSignalProviders();

  const onCreated = () => {
    refresh();
    notificationApi.success({
      message: 'Created Account'
    });
    setCreateFormOpen(false);
  };

  return <Card
    title="Signal Provider Accounts"
    extra={<Button onClick={() => setCreateFormOpen(true)}>
      Create New Account <PlusOutlined />
    </Button>}
  >
    <Row gutter={[16, 16]}>
      {
        providers.map(provider => (
          <Col
            // makes all columns equal height
            // need to add flex: 1 to children
            style={{ display: 'flex' }}
            key={provider.id}
            span={24} 
            md={{ span: 12 }}
          >
            <AnalystSignalProviderListItem
              provider={provider}
            />
          </Col>
        ))
      }
    </Row>
    {contextHolder}

    <CreateSignalProviderModal
      open={createFormOpen}
      onClose={() => setCreateFormOpen(false)}
      onSuccess={onCreated} />
  </Card>
}
