import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import ScrollableContainer from "./scrollable-container";
import { fetchPrevMessages } from "../redux/thunks/fetch-prev-messages";
import { MessageItem } from "./message-item/message-item";
import { currentChatroomIdSelector, currentChatroomMessagesListSelector, prevCursorSelector } from "../redux/chat.selectors";
import { useAuthUser } from "../../auth/hooks/useAuthUser";
import { markChatroomMessagesRead } from "../redux/thunks/mark-chatroom-message-read.thunk";
import { useRef } from "react";
import { checkVisibility } from "../../shared/check-visibility";

export const MessagesList = () => {
  const dispatch = useAppDispatch();
  const chatroomId = useAppSelector(currentChatroomIdSelector);
  const messages = useAppSelector(currentChatroomMessagesListSelector);
  const prev = useAppSelector(prevCursorSelector);
  const { user } = useAuthUser();
  const scrollContainerRef = useRef<HTMLDivElement>(null);

  if (!user) {
    return null;
  }

  const onTopReached = () => {
    if (!prev || !prev.hasMore || prev.loading) {
      return;
    }
    dispatch(fetchPrevMessages());
  };

  const onBottomReached = () => {

    // just in case chatroom is not selected
    if (!chatroomId) {
      return
    }

    // window is not focused
    if (!document.hasFocus()) {
      return;
    }
  
    // We dont have access to scroll container
    // we need its ref to check its visibility
    if (!scrollContainerRef || !scrollContainerRef.current) {
      return;
    }

    // Check scroll containers visibility
    const scrollDivVisible = checkVisibility(scrollContainerRef.current, {
      checkOpacity: true,
      checkVisibilityCSS: true,
      contentVisibilityAuto: true,
    });

    // The message list is not visible
    if (!scrollDivVisible) {
      return
    }

    // mark message as read if all checks above pass
    dispatch(markChatroomMessagesRead(chatroomId));
  }

  return <ScrollableContainer
    ref={scrollContainerRef}
    style={{
      flex: 1,
      height: '100%',
      overflowY: 'scroll',
      padding: 8,
      gap: 8,
    }}
    onTopReached={onTopReached}
    onBottomReached={onBottomReached}
    dataLength={messages?.length || 0}
  >
    <div>
      {messages && messages.map(message => (
        <MessageItem
          key={message.id}
          item={message}
          currentUser={user} />
      ))}
    </div>
  </ScrollableContainer>;
};
