import { Modal } from "antd";
import { useState } from "react";
import { TradeCrypto } from "../../trade/components/TradeCrypto";
import { TradeOptions } from "../../trade/components/TradeOptions";
import { TradeStocks } from "../../trade/components/TradeStocks";
import { CryptoFormInitialValues } from "../../trade/hooks/useCryptoForm";
import { OptionFormInitialValues } from "../../trade/hooks/useOptionsForm";
import { StockFormInitialValues } from "../../trade/hooks/useStocksForm";
import { isCrypto, isOptions, isStocks, PlaceOrderFormInitialValues } from "../../trade/types/place-order-form-initial-values";

export const SignalCopyDialog = ({
  open,
  onClose,
  initialValues,
  title = "Are You Sure you want to copy?",
}: {
  open: boolean;
  onClose: () => void;
  initialValues: PlaceOrderFormInitialValues;
  title?: string,
}) => {
  const [confirmationShown, setConfirmationShown] = useState(false);

  let form = null;

  if (isStocks(initialValues)) {
    form = <TradeStocks
      initialValues={initialValues as StockFormInitialValues}
      onSuccess={onClose}
      onConfirmationModalVisibilityToggle={setConfirmationShown}
    />
  }

  if (isOptions(initialValues)) {
    form = <TradeOptions
      initialValues={initialValues as OptionFormInitialValues}
      onSuccess={onClose}
      onConfirmationModalVisibilityToggle={setConfirmationShown}
    />
  }

  if (isCrypto(initialValues)) {
    form = <TradeCrypto
      initialValues={initialValues as CryptoFormInitialValues}
      onSuccess={onClose}
      onConfirmationModalVisibilityToggle={setConfirmationShown}
    />
  }

  return (
    <Modal
      style={{ opacity: confirmationShown ? 0 : 1 }}
      title={title}
      open={open}
      onCancel={onClose}
      footer={null}
    >
      { form }
    </Modal>
  );
};
