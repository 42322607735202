import { useEffect } from "react";
import { useAuthUser } from "../auth/hooks/useAuthUser";
import { CandeFiSWAuthUserEvent, CANDEFI_SW_AUTH_USER_EVENT } from "../service-worker-events/CandefiAuthUser";

/**
 * Sends user id to service worker.
 */
export function ServiceWorkerUserIdSyncer() {
  const { initialized, user } = useAuthUser();

  useEffect(() => {

    if (!initialized) {
      return
    }

    const message : CandeFiSWAuthUserEvent = { 
      type: CANDEFI_SW_AUTH_USER_EVENT, 
      userId: user?.id || null,
    }
  
    if (navigator.serviceWorker.controller) {
      navigator.serviceWorker.controller?.postMessage(message)
      return;    
    }

    navigator.serviceWorker.ready.then(reg => {
      reg.active?.postMessage(message)
    })

  }, [initialized, user]);

  return null;
}