import { UserPublicRead } from './auth';
import axios from './axios';
import { UserRole } from "./enums";

export type SearchUserRequest = {
  query: string,
  role: UserRole | null
}

const usersApi = { 
  async search(request: SearchUserRequest){
    const {data} = await axios.get<UserPublicRead[]>("/users/search", {
      params: request,
    })
    return data
  },
}

export default usersApi;