import { AssetType } from "../../api/enums";
import { BrokerType } from "../../brokers/entities/broker-account.entity";

export function getSupportedAssets(brokerType: BrokerType, paper: boolean) {
  if (brokerType === BrokerType.webull) {
    return [ AssetType.Stocks, AssetType.Options ];
  }
  if (brokerType === BrokerType.robinhood) {
    return [ AssetType.Stocks, AssetType.Options, AssetType.Crypto ];
  }
  if (brokerType === BrokerType.webull_pay) {
    return [ AssetType.Crypto ]
  }
  return [];
}