import { Button, ConfigProvider, message, Spin } from "antd";
import { useState } from "react";
// import { useWatchListItem } from "../context/watchlist-item-context";
// import { AddToWatchlistModal } from "./AddToWatchlistModal";
import { WatchlistItem } from "./WatchlistItem";
import { PlusOutlined } from '@ant-design/icons';
import { Typography } from 'antd';
import styles from './watchlist.module.scss';
// import { ClearWatchlistButton } from "./ClearWatchlistButton";
import { useWatchList } from "../context/watchlist-context";
import { WatchlistSelectDropdown } from "./WatchlistSeletDropdown";
import { AddToWatchlistModal } from "./AddToWatchlistModal";
import { CreateWatchlistModal } from "./CreateWatchlistModal";
import { ClearWatchlistButton } from "./ClearWatchlistButton";

const { Text, Title } = Typography;

export const Watchlist = ({
  layout,
} : {
  layout: 'horizontal' | 'vertical',
}) => {

  // watchlists, selected watchlist, add watchlist modal, add item modal

  const [showAddItemModal, setShowAddItemModal] = useState(false);
  const [showCreateWatchlistModal, setShowCreateWatchlistModal] = useState(false);
  const [ messageApi, contextHolder ] = message.useMessage();

  const {  items, lists } = useWatchList();
  const { data, loading, error } = items;
  
  const onItemAdded = () => {
    setShowAddItemModal(false);
    items.reload();
    messageApi.success('added successfully');
  }

  const onListAdded = () => {
    setShowCreateWatchlistModal(false);
    lists.reload();
    messageApi.success('added successfully');
  }


  return <div className={`${styles.watchlist} ${layout === 'horizontal' ? styles.horizontal : styles.vertical }`}>
    <>
      { contextHolder }
      <AddToWatchlistModal 
        open={showAddItemModal} 
        setOpen={setShowAddItemModal} 
        onSuccess={onItemAdded}
      />
      <CreateWatchlistModal 
        open={showCreateWatchlistModal} 
        setOpen={setShowCreateWatchlistModal} 
        onSuccess={onListAdded}
      />
      <WatchlistSelectDropdown 
        onCreateWatchlistClick={() => {
          setShowCreateWatchlistModal(true);
        }}
      />
      {/* <Title className={styles.title} level={5}>Watchlist</Title> */}
      {/* change error color to green, to create green add button */}
      <ConfigProvider theme={{
        token: {
          colorError: '#14d118',
        }
      }}>
        <Button 
          icon={<PlusOutlined/>}
          type='default'
          danger
          className={styles.addButton}
          onClick={() => setShowAddItemModal(true)}>
          Add
        </Button>
      </ConfigProvider>
      <ClearWatchlistButton />
      {
        loading && <Spin className={styles.spinner}/>
      }
      {
        !loading && !error && data.map(item => <WatchlistItem 
          item={item} 
          key={item.symbol} 
        />)
      }
      {
        !loading && !error && data.length == 0 && <div className={styles.empty}>
          <Text type="secondary">Watchlist Empty</Text>
        </div>
      }
    </>
  </div>;
}