import { assetTypeLabels } from "../../api/enum-labels";
import { AssetType } from "../../api/enums";
import { useSupportedAssetTypes } from "../hooks/useSupportedAssetTypes";

export function SupportsAsset({ type, children } : { type: AssetType, children: React.ReactElement }) {
  const supportedAssetTypes = useSupportedAssetTypes();
  if (!supportedAssetTypes.includes(type)) {
    return <div>
      Selected broker does not support {assetTypeLabels[type]}
    </div>
  }
  return children || null;
}