import { Modal, Upload, UploadProps } from "antd";
import { InboxOutlined } from '@ant-design/icons';
import api from "../../api";
import axios from "axios";
import { ImageUploadUrlResponse } from "../../api/image-upload";
import ImgCrop, { ImgCropProps } from 'antd-img-crop';

export function ImageUploadPopup({
  open,
  onClose,
  onChange,
  croperProps,
  uploadProps,
  title = 'Upload Image'
}: {
  open: boolean,
  onClose: () => void,
  onChange: (image: string) => void | Promise<void>,
  croperProps: Partial<ImgCropProps>
  uploadProps?: Partial<UploadProps>
  title?: string,
}) {
  return <Modal
    open={open}
    onCancel={onClose}
    title={title}
    footer={null}
  >
    <ImgCrop {...croperProps}>
      <Upload.Dragger
        {...uploadProps}
        onChange={(info) => {
          console.log("onChange", info);
          if (info.file.status === 'done') {
            const response = info.file.response as ImageUploadUrlResponse;
            onChange(response.key)
          }
        }}
        style={{ marginBottom: 16 }}
        multiple={false}
        accept="image/png, image/jpeg"
        method="PUT"
        customRequest={async ({
          file, onProgress, onError, onSuccess,
        }) => {
          if (!(file instanceof File)) {
            throw new Error("Invalid file");
          }
          const { type } = file;
          const { url, key } = await api.imageUpload.createUrl({ content_type: type });

          try {
            await axios.put(url, file, {
              headers: {
                'Content-Type': type,
              },
              onUploadProgress: (e) => {
                if (e.progress !== undefined && onProgress !== undefined) {
                  onProgress({ percent: e.progress * 100 });
                }
              }
            });
            if (onSuccess !== undefined) {
              onSuccess({ url, key });
            }
          } catch (e) {
            if (onError !== undefined) {
              onError(e as Error);
            }
            return;
          }
        }}
      >
        <p className="ant-upload-drag-icon">
          <InboxOutlined />
        </p>
        <p className="ant-upload-text">Click or drag file to this area to upload</p>
        <p className="ant-upload-hint">
          Support for a single or bulk upload. Strictly prohibited from uploading company data or other
          banned files.
        </p>
      </Upload.Dragger>
    </ImgCrop>
  </Modal>
}
