import webullIcon from '../images/webull_icon.png';
import robinhoodIcon from '../images/robinhood_icon.png';
import { BrokerType } from '../entities/broker-account.entity';

export const brokerIcons : Record<BrokerType, any> = {
  [BrokerType.robinhood]: robinhoodIcon,
  [BrokerType.webull]: webullIcon,
  [BrokerType.webull_pay]: webullIcon,
  [BrokerType.tda]: '',
}
