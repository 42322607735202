import React, { useState } from "react";
import { Button, Card, Col, Divider, message, notification, Popconfirm, Row, Tag, Typography } from "antd";
import { MembershipPlan, SignalProvider } from "../../api/signal-providers";
import { DeleteOutlined, EyeOutlined, EditOutlined } from "@ant-design/icons";
import { formatBillingInterval, formatMoney } from "../../shared/utils";
import api from "../../api";
import { ErrorMessage } from "../../shared/components/ErrorMessage";
import { Link } from "react-router-dom";
import { EditPlanPriceModal } from "./EditPlanPriceModal";
const { Title, Text } = Typography;

const rowStyle: React.CSSProperties = {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  marginTop: 8,
};

const MyMembershipPlanCard = ({
  provider,
  membershipPlan,
  style,
  onDelete,
  onEditPrice,
}: {
  provider: SignalProvider,
  membershipPlan: MembershipPlan;
  style?: React.CSSProperties;
  onDelete?: () => void,
  onEditPrice?: () => void,
}) => {

  const [messageApi, contextHolder] = message.useMessage();
  const [error, setError] = useState<any>();

  const showEditButton = membershipPlan.active;
  const showDeleteButton = membershipPlan.active;

  const [editOpen, setEditOpen] = useState(false);


  const deleteSignalProviderPlan = async () => {
    try {

      setError(null);
      await api.membershipPlans.delete(membershipPlan.id);
      messageApi.success("Deleted");
      if (onDelete) {
        onDelete();
      }

    } catch (e) {

      setError(e);

    }
  };


  const handleEditClick = () => {
    setEditOpen(true);
  }

  return (
    <Card style={style}>
      {
        showEditButton && (
          <EditPlanPriceModal
            open={editOpen}
            onClose={() => setEditOpen(false)}
            currentPrice={membershipPlan.amount}
            planId={membershipPlan.id}
            onSuccess={() => {
              setEditOpen(false);
              messageApi.success("Price Updated");
              if (onEditPrice) {
                onEditPrice();
              }
            }}
          />
        )
      }
      {contextHolder}
      <Title style={{ margin: 0 }} level={4}>
        {membershipPlan.name}
      </Title>

      <div style={{ margin: '16px 0', display: 'flex', flexWrap: 'wrap' }}>
        {
          membershipPlan.stocks && <Tag color="success">
            Stocks
          </Tag>
        }
        {
          membershipPlan.options && <Tag color="success">
            Options
          </Tag>
        }
        {
          membershipPlan.crypto && <Tag color="success">
            Crypto
          </Tag>
        }
      </div>

      <div style={rowStyle}>
        <Text type="secondary">Status</Text>
        <Text type={membershipPlan.active ? "success" : "danger"}>
          {membershipPlan.active ? "Active" : "In Active"}
        </Text>
      </div>

      <div style={rowStyle}>
        <Text type="secondary">Billing Interval</Text>
        <Text type="secondary">
          {
            formatBillingInterval(
              membershipPlan.billing_interval,
              membershipPlan.billing_interval_unit
            )
          }
        </Text>
      </div>

      <div style={rowStyle}>
        <Text type="secondary">Billing Cycles</Text>
        <Text type="secondary">
          {
            membershipPlan.billing_cycles === 0
              ? 'Infinite'
              : membershipPlan.billing_cycles
          }
        </Text>
      </div>

      <div style={rowStyle}>
        <Text type="secondary">Amount</Text>
        <Text type="secondary">
          {formatMoney(membershipPlan.amount)}
        </Text>
      </div>
      <div>
        {error && <ErrorMessage error={error} />}
      </div>
      <Divider />
      <div style={{ display: 'flex', flexWrap: 'wrap', gap: 8, marginTop: 16, justifyContent: 'space-between' }}>
        <div >
          <Link to={`/app/account/analyst/${provider.id}/plans/${membershipPlan.id}`}>
            <Button
              block
              size="middle"
              type="default"
            >
              <EyeOutlined /> Details
            </Button>
          </Link>
        </div>
        {
          (showEditButton || showDeleteButton) && <div style={{ display: 'flex', gap: 8 }}>
            {
              showEditButton && (
                <Button
                  block
                  size="middle"
                  type="default"
                  onClick={handleEditClick}
                >
                  <EditOutlined />
                </Button>
              )
            }
            {
              showDeleteButton && (
                <Popconfirm
                  title="Are you sure?"
                  description="Once deleted plan cannot be restored"
                  okText="Yes"
                  cancelText="No"
                  onConfirm={deleteSignalProviderPlan}
                >
                  <Button
                    block
                    size="middle"
                    type="default"
                    danger
                  >
                    <DeleteOutlined />
                  </Button>
                  <div>
                    {error && <ErrorMessage error={error} />}
                  </div>
                </Popconfirm>
              )
            }
          </div>
        }
      </div>
    </Card>
  );
};

export default MyMembershipPlanCard;
