import { GenericAbortSignal } from "axios"
import axios from "./axios"
import { AssetType, ChatroomMemberRole, CryptoAmountUnit, Direction, OrderType, Side, SignalSource, SortOrder, TimeInForce, TrailType } from "./enums"
import { GenericStatusResponse, GenericStrIDResponse } from "./types"
import { CentTokenResponse } from "./cent"

export interface BasicChatroomUser {
  id: string
  name: string
  username: string
}

export enum DirectMessageRequestStatus {
  Pending = 'pending',
  Accepted = 'accepted',
  Rejected = 'rejected',
}

export interface BasicChatroom {
  id: string
  title: string | null,
  dm_request_sender: BasicChatroomUser | null
  dm_request_recipient: BasicChatroomUser | null
  dm_request_status: DirectMessageRequestStatus | null
  channel_name: string
  created_at: string
  updated_at: string
}

export interface BasicChatroomWithPermissionSummary extends BasicChatroom {
  permission_summary: ChatroomPermissionSummary | null
}

export interface ChatroomPermissionSummary {
  chatroom_id: string
  user_id: number
  can_send: boolean
  role: ChatroomMemberRole | null
}

export interface ChatroomMessageChatroom {
  id: string
  title: string
  channel_name: string
}

export interface ChatroomMessageUserBase {
  id: string
  name: string
}

export interface ChatroomMessageBase {
  id: string
  chatroom: ChatroomMessageChatroom
  sender: ChatroomMessageUserBase | null
  system_message: boolean
  text: string
  image: string | null
  created_at: string
  updated_at: string
}

export interface ListMessageRequest {
  cursor_timestamp: number | null
  cursor_uuid: string | null
  limit: number | null
  order: SortOrder | null
}

export interface BasicChatroomMessageSignal {
  id: number
  source: SignalSource | null
  asset_type: AssetType,
  stock: string | null
  side: Side | null
  order_type: OrderType | null
  time_in_force: TimeInForce | null
  expiration_date: string | null
  direction: Direction | null
  strike_price: number | null
  limit_price: number | null
  stop_price: number | null
  last_price: number | null
  quantity: number | null
  quantity_multipler: number | null
  trail_value: number | null
  trail_type: TrailType | null
  outside_regular_trading_hour: boolean | null
  created_at: string
  updated_at: string
  created_by_bot: boolean
  crypto_amount_unit: CryptoAmountUnit | null
  crypto_amount: number | null
  crypto_limit_price: number | null
  crypto_stop_price: number | null
}

export interface BasicChatroomMessage {
  id: string
  sender: BasicChatroomUser | null
  text: string | null
  image: string | null
  system_message: boolean
  signal_id: number | null
  created_at: number
  updated_at: number
}

export interface CreateMessageRequest {
  text: string | null
  image: string | null
}

export type CreateDMChatroomRequest = 
  { user_id: number } |
  { username: string }

export interface ChatDMPreference {
  id: number
  allow_dm: boolean
}

export interface UpdateDMPreferenceRequest {
  allow_dm: boolean
}

export interface BasicChatroomPrivacySettings {
  chatroom_id: string
  members_can_send: boolean
}

export interface ChatroomPrivacyEditRequest {
  members_can_send: boolean
}

export interface BasicChatroomMember {
  user: BasicChatroomUser
  role: ChatroomMemberRole
}

export interface BasicChatroomBlockedUser {
  user: BasicChatroomUser
}

export interface BasicChatroomMembersInfo {
  members: BasicChatroomMember[]
  blocked_users: BasicChatroomBlockedUser[]
}

export interface ChatModerator {
  user_id: number
}

export type UnreadMessageCount = {
  chatroom_id: string
  count: number
  updated_at: string // datetime string
}

const chatApi = {
  async myChatrooms() {
    const { data } = await axios.get<Array<BasicChatroomWithPermissionSummary>>('/chat/my-chatrooms');
    return data;
  },

  async getSubscriptionToken(chatroomId: string) {
    const { data } = await axios.post<CentTokenResponse>(
      `/chat/chatrooms/${chatroomId}/subscription-token`
    );
    return data;
  },

  async listMessages(chatroomId: string, request: ListMessageRequest) {
    const {data} = await axios.get<Array<BasicChatroomMessage>>(`/chat/chatrooms/${chatroomId}/messages`, {
      params: request,
    });
    return data;
  },

  async createMessage(chatroomId: string, request: CreateMessageRequest) {
    const { data } = await axios.post<GenericStrIDResponse>(`/chat/chatrooms/${chatroomId}/messages`, request);
    return data;
  },

  async acceptDmRequest(chatroomId: string) {
    const { data } = await axios.patch<GenericStatusResponse>(`/chat/chatrooms/${chatroomId}/request/accept`);
    return data;
  },

  async rejectDmRequest(chatroomId: string) {
    const { data } = await axios.patch<GenericStatusResponse>(`/chat/chatrooms/${chatroomId}/request/reject`);
    return data;
  },

  async createDmChatroom(request : CreateDMChatroomRequest) {
    const { data } = await axios.post<GenericStrIDResponse>(`/chat/chatrooms/dm`, request);
    return data;
  },

  async getChatDMPreference() {
    const { data } = await axios.get<ChatDMPreference | null>(`/chat/dm-preference`);
    return data;
  },

  async updateDMPreference(request: UpdateDMPreferenceRequest, signal?: GenericAbortSignal) {
    const { data } = await axios.patch<GenericStatusResponse>('/chat/dm-preference', request, {
      signal,
    });
    return data;
  },

  async getPrivacySettings(id: string) {
    const { data } = await axios.get<BasicChatroomPrivacySettings | null>(
      `/chat/chatrooms/${id}/privacy-settings`
    );
    return data;
  },

  async editPrivacySettings(id: string, request: ChatroomPrivacyEditRequest, signal?: GenericAbortSignal) {
    const { data } = await axios.patch<GenericStatusResponse>(
      `/chat/chatrooms/${id}/privacy-settings`,
      request,
      { signal }
    );
    return data;
  },

  async basicChatroomMemberInfo(id: string) {
    const { data } = await axios.get<BasicChatroomMembersInfo>(`/chat/chatrooms/${id}/members`);
    return data
  },
  
  async blockChatroomUser(roomId: string, userId: number) {
    const { data } = await axios.patch<GenericStatusResponse>(`/chat/chatrooms/${roomId}/members/${userId}/block`);
    return data;
  },

  async unblockChatroomUser(roomId: string, userId: number) {
    const { data } = await axios.patch<GenericStatusResponse>(`/chat/chatrooms/${roomId}/members/${userId}/unblock`);
    return data;
  },

  async messageSignal(messageId: string) {
    const { data } = await axios.get<BasicChatroomMessageSignal | null>(`/chat/messages/${messageId}/signal`);
    return data
  },

  async moderators() {
    const { data } = await axios.get<ChatModerator[]>('/chat/moderators');
    return data
  },

  async getUnreadMessageCount(signal?: AbortController['signal']) {
    const { data } = await axios.get<UnreadMessageCount[]>(
      '/chat/unread-message-count',
      { signal: signal }
    );
    return data;
  },

  async getChatroomUnreadMessageCount(chatroomId: string, signal?: AbortController['signal']) {
    const { data } = await axios.get<UnreadMessageCount | null>(
      `/chat/chatrooms/${chatroomId}/unread-message-count`, { signal }
    );
    return data;
  },

  async markMessagesRead(chatroomId: string) {
    const { data } = await axios.patch<null>(`/chat/chatrooms/${chatroomId}/unread-message-count/mark-read`);
    return data;
  },
}

export default chatApi;