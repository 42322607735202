import { Button, Form, Input } from "antd";
import { useState } from "react";
import api from "../../../api";
import { SignalProviderType } from "../../../api/enums";
import { ErrorMessage } from "../../../shared/components/ErrorMessage";

type FormData = {
  name: string;
  username: string;
  bio: string | null;
  intro: string | null;
}

export function SignalProviderRegistrationForm({
  type,
  onSuccess,
}: {
  type: SignalProviderType,
  onSuccess: () => void | Promise<void>,
}) {

  const [error, setError] = useState<any>(null);
  const [submitting, setIsSubmitting] = useState(false);

  const onFinish = async (data: FormData) => {
    try {

      setError(null);
      setIsSubmitting(true);

      if (type === SignalProviderType.Community) {
        await api.signalProviders.createCommunitySignalProvider(data);
      }

      if (type === SignalProviderType.Individual) {
        await api.signalProviders.createIndividualSignalProvider(data);
      }

      onSuccess();

    } catch (e) {

      setError(e);

    } finally {

      setIsSubmitting(false);

    }
  };

  const usernameMessage = {
    [SignalProviderType.Community]: 'Community username (used as part of the URL for this community)',
    [SignalProviderType.Individual]: 'Signal provider username (used as part of the URL for this provider)',
  }[type];
  
  const nameMessage = {
    [SignalProviderType.Community]: 'Community name (displayed to users)',
    [SignalProviderType.Individual]: 'Signal provider name (displayed to users)',
  }[type];

  return (
    <>
      {
        error && <div>
          <ErrorMessage error={error} />
        </div>
      }
      <Form
        name="signal_provider_registration"
        style={{ width: '100%' }}
        layout="vertical"
        onFinish={onFinish}
        autoComplete="off"
      >
        <Form.Item
          label="Username"
          name="username"
          help={usernameMessage}
          rules={[{ required: true, message: "username for this signal provider" }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Name"
          name="name"
          rules={[{ required: true, message: "name is required" }]}
          help={nameMessage}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Intro"
          name="intro"
          help="Single line intro"
          rules={[{ max: 255, message: "max 255 characters" }]}
        >
          <Input />
        </Form.Item>


        <Form.Item
          label="Bio"
          name="bio"
        >
          <Input.TextArea />
        </Form.Item>

        <Form.Item>
          <Button
            block
            size="large"
            disabled={submitting}
            htmlType="submit"
            type="primary"
          >
            Register
          </Button>
        </Form.Item>
      </Form>
    </>
  )
}