import api from "../../../api";
import { createAppAsyncThunk } from "../../../redux/hooks";
import { fetchChatroomUnreadMessageCount } from "./fetch-chatroom-unread-message-count.thunk";

export const markChatroomMessagesRead = createAppAsyncThunk(
  'chat/markChatroomMessagesRead',
  async function (chatroomId: string, { dispatch }) {
    try {

      await api.chat.markMessagesRead(chatroomId);
      // could set to zero
      // but fetching just in case something has changed
      dispatch(fetchChatroomUnreadMessageCount(chatroomId));

    } catch (e) {

      console.log("Failed to mark chatrooms messages as read");
      console.log(e);

    }
  }
);
