import axios from './axios';
import { GenericStatusResponse } from './types';

export type WebullPayCreateSessionRequest = {
  email: string
}

export type ImageExtraInfo = {
  yPos: number
  slidingImage: string
  type: string
  backImage: string
}

export type WebullPayCreateSessionResponse = {
  id: number
  new_device: boolean
  image: ImageExtraInfo | null
}

export type WebullPayCheckMFAImageRequest = {
  email: string
  xPos: number
}

export type CheckMFAImageStatusResponse = {
  result: boolean
}

export type CheckMFAImageResponse = {
  response: CheckMFAImageStatusResponse | ImageExtraInfo
}

export function isMFAImageStatusResponse(response: CheckMFAImageStatusResponse | ImageExtraInfo) : response is CheckMFAImageStatusResponse {
  return Object.hasOwn(response, 'result');
}

export function isMFAImageResponse(response: CheckMFAImageStatusResponse | ImageExtraInfo) : response is ImageExtraInfo {
  return !Object.hasOwn(response, 'result');
}

export type SecurityQuestion = {
  questionId: string
  questionName: string
}

export type GetSecurityQuestionRequest = {
  email: string
  alternate: boolean
}
export type CheckAnswerRequest = {
  email: string
  questionId: string
  answer: string 
}

export type WebullPayLoginRequest = {
    email: string
    password: string
    x_pos: string
    security_question_id: string
    security_question_answer: string
    trading_pin: string
}

export type WebullPayShortLoginRequest = {
  email: string
  password: string
  trading_pin: string
}

const webullPayConnectApi = {
  async createSession(request: WebullPayCreateSessionRequest) : Promise<WebullPayCreateSessionResponse> {
    const { data } = await axios.post<WebullPayCreateSessionResponse>(`/webull-pay/connect-sessions`, request);
    return data;
  },

  async checkMFAImage(sessionId: number, request: WebullPayCheckMFAImageRequest) : Promise<CheckMFAImageResponse> {
    const { data } = await axios.patch<CheckMFAImageResponse>(`/webull-pay/connect-sessions/${sessionId}/check-mfa-image`, request);
    return data;
  },

  async getSecurityQuestion(sessionId: number, request: GetSecurityQuestionRequest) {
    const { data } = await axios.patch<SecurityQuestion>(`/webull-pay/connect-sessions/${sessionId}/security-question`, request);
    return data;
  },

  async checkSecurityAnswer(sessionId: number, request: CheckAnswerRequest) {
    const { data } = await axios.patch<GenericStatusResponse>(`/webull-pay/connect-sessions/${sessionId}/check-answer`, request);
    return data;
  },
  async login(sessionId: number, request: WebullPayLoginRequest) {
    const { data } = await axios.patch<GenericStatusResponse>(`/webull-pay/connect-sessions/${sessionId}/login`, request);
    return data;
  },

  async loginShort(sessionId: number, request: WebullPayShortLoginRequest) {
    const { data } = await axios.patch<GenericStatusResponse>(`/webull-pay/connect-sessions/${sessionId}/login-short`, request);
    return data;
  }
}

export default webullPayConnectApi;