import { Button } from "antd";
import { useEffect, useMemo, useState } from "react";
import api from "../../../api";
import { SignalProvider } from "../../../api/signal-providers";
import { ImageUploadPopup } from "../../../shared/components/ImageUploadPopup";
import { getImageResizeFunction } from "../../../shared/get-image-resize-function";
import { getImageUrl } from "../../../shared/image-utils";
import { EditOutlined, CameraOutlined } from '@ant-design/icons'
import { PatternImage } from "../../../shared/components/PatternImage";
import { useEmotionCss } from "@ant-design/use-emotion-css";

type SignalProviderCoverImageEditProps = {
  provider: SignalProvider,
  onSuccess: () => void | Promise<void>
}


export function SignalProviderCoverImageEdit({ provider, onSuccess }: SignalProviderCoverImageEditProps) {
  const [open, setOpen] = useState(false);

  // key to rest image upload popup state
  const [key, setKey] = useState(0);
  useEffect(() => {
    if (open) {
      setKey(k => k + 1);
    }
  }, [open]);

  // resize to 1920 x 1080 if image is larger
  const beforeUpload = useMemo(() => getImageResizeFunction(1920, 1080), []);
  const aspect = 1920 / 1080

  const handleOnChange = async (key: string) => {
    await api.signalProviders.editCoverImage(provider.id, { image: key });
    setOpen(false);
    onSuccess();
  }

  const bgContainer = useEmotionCss(({ token }) => ({
    background: token.colorBgContainer
  }));

  return <div className={bgContainer} style={{ width: '100%', position: 'relative', borderRadius: 8, overflow: 'clip' }}>
    {
      provider.cover_image && (
        <img src={getImageUrl(provider.cover_image, { variant: '1920x1080' })} 
          style={{ width: '100%', height: 'auto', aspectRatio: `${aspect}`, backgroundSize: 'cover' }}
        />
      )
    }
    {
      !provider.cover_image && (
        <PatternImage aspect={aspect}/>
      )
    }
    <Button
      icon={<EditOutlined />}
      onClick={() => setOpen(true)}
      style={{ position: 'absolute', bottom: 16, right: 16 }}>
      Cover Image
    </Button>
    <ImageUploadPopup
      key={key}
      open={open}
      onClose={() => setOpen(false)}
      croperProps={{ aspect: aspect, modalTitle: 'Crop Image', modalWidth: '800px' }}
      uploadProps={{
        beforeUpload: beforeUpload,
      }}
      onChange={handleOnChange}
      title="Upload Cover Image (16:9)"
    />
  </div>
}
