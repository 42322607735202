import { Card } from "antd"
import { TradeChart } from "../../trade/components/TradeChart"
import { useWatchListItem } from "../../watchlist/context/watchlist-item-context";
import { CollapsibleSection } from "./CollapsibleSection";
import { useAuthUser } from "../../auth/hooks/useAuthUser";

export const AnalystTradeChart = () => {
  const { user } = useAuthUser();
  const { item } = useWatchListItem();
  const symbol = item?.symbol || 'AAPL';

  if (!user) {
    return null;
  }

  return <CollapsibleSection title="Trade Chart" >
    <Card 
      style={{ overflow: 'hidden' }} 
      bodyStyle={{ paddingTop: 0, paddingBottom: 0 }}>
      <TradeChart symbol={symbol}/>
    </Card>
  </CollapsibleSection>
}