import { List, Typography } from "antd";
import { useEffect, useState } from "react";
import { SignalProviderType } from "../../../api/enums";
import { useAuthUserSignalProviders } from "../../hooks/useAuthUserSignalProviders";
import { UserOutlined, TeamOutlined } from '@ant-design/icons';
import { SignalProviderRegistrationForm } from "./SignalProviderRegistrationForm";

export function CreateSignalProviderWizard({
  onSuccess,
}: {
  onSuccess: () => void | Promise<void>;
}) {

  const { providers } = useAuthUserSignalProviders();
  const [step, setStep] = useState<'initial' | 'select_type' | 'show_form'>('initial');
  const [providerType, setProviderType] = useState<SignalProviderType | null>(null);

  useEffect(() => {
    const individualProvider = providers.find(p => p.type === SignalProviderType.Individual);

    if (individualProvider === undefined) {
      setStep('select_type');
      return;
    }

    setStep('show_form');
    setProviderType(SignalProviderType.Community);

  }, [providers]);

  const changeType = (type: SignalProviderType) => {
    setProviderType(type);
    setStep('show_form');
  }

  if (step === 'select_type') {
    return (
      <div>
        <Typography.Title level={4}>Choose Signal Provider Type</Typography.Title>
        <List>
          <List.Item onClick={() => changeType(SignalProviderType.Individual)}>
            <List.Item.Meta
              avatar={<UserOutlined />}
              title='Individual'
              description='Individual account lets your share signals with your subscribers' />
          </List.Item>
          <List.Item onClick={() => changeType(SignalProviderType.Community)}>
            <List.Item.Meta
              avatar={<TeamOutlined />}
              title='Community'
              description='Community account lets you and your fellow analysts to share signals with your subscribers.' />
          </List.Item>
        </List>
      </div>
    );
  }

  if (step === 'show_form' && !!providerType) {
    return <div>
      <Typography.Title level={4}>Community Signal Provider</Typography.Title>
      <div style={{ width: 'min(600px, 100%)' }}>
        <SignalProviderRegistrationForm
          type={providerType}
          onSuccess={onSuccess}
        />
      </div>
    </div>
  }

  return null;
}
