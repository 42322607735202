import { createAppAsyncThunk } from "../../../redux/hooks";
import { NewChatroomMessagePayload } from "../../cent/chatroom-events";
import { appendMessages } from "../chat.slice";
import { fetchChatroomUnreadMessageCount } from "./fetch-chatroom-unread-message-count.thunk";
import mutex from "./mutex";

export const handleNewMessage = createAppAsyncThunk(
  'chat/handle-new-message',
  async (payload: NewChatroomMessagePayload, thunkApi) => {
    const { getState, dispatch } = thunkApi;

    dispatch(fetchChatroomUnreadMessageCount(payload.message.chatroom.id));

    // Must run exclusively
    // See note on mutex variable for reason.
    mutex.runExclusive(async () => {

      const chatroomId = getState().chat.selectedChatroomId;
      
      if (chatroomId !== null && chatroomId === payload.message.chatroom.id) {
        // remove chatroom from payload
        const { chatroom, ...basicMessage } = payload.message;
        dispatch(appendMessages([basicMessage]))
      }
    });
  }
);