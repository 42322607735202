import { useRef, useState } from "react";
import useSWR from 'swr';
import api from "../../../api";
import { Select, SelectProps } from "antd";
import { useSelectedBroker } from "../../../brokers/context/SelectedBrokerContext";
import { useDebounceValue } from "usehooks-ts";

export const StockTickerAutocomplete = ({
  setValue,
  searchOptions = false,
  searchStocks = false,
  searchCrypto = false,
  ...props
}: {
  setValue: (value: string | null) => void;
  searchOptions?: boolean,
  searchStocks?: boolean,
  searchCrypto?: boolean,
} & SelectProps) => {

  const { broker } = useSelectedBroker();
  const abortControllerRef = useRef<AbortController | null>(null);

  const [query, setQuery] = useState<string>('');
  const [debouncedQuery] = useDebounceValue(query, 500)

  const {
    data: tickers, 
    isValidating: tickersLoading
  } = useSWR(
    
    broker !== null && debouncedQuery !== null 
    ? ['api/ticker/search', debouncedQuery, searchStocks, searchOptions, searchCrypto]
    : null,
    
    (key) => {
      const [_, keyword, stocks, options, crypto] = key;
      // Abort the previous request if there is one
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }

      // Create a new AbortController for the current request
      const controller = new AbortController();
      abortControllerRef.current = controller;
      
      return  api.tickerSearch.search({
        keyword,
        stocks,
        options,
        crypto,
      }, controller);
    }
  );

  const selectOptions = !!tickers
    ? tickers.map(t => ({ label: t.long_name, value: t.symbol, ticker: t }))
    : [];

    return <Select
    showSearch={true}
    searchValue={query}
    filterOption={false}
    onChange={(value) => setValue(value)}
    onSearch={(newValue) => setQuery(newValue || '')}
    options={selectOptions}
    loading={tickersLoading}
    {...props}
  />;
};
