import { Button, Empty, Modal, Space, notification } from "antd";
import { BotAlertWithOrders } from "../../../api/autotrade-alerts";
import { botAssetTypeLabels } from "../../../api/enum-labels";
import { getFormattedBotAlertSymbol, getFormattedBotAction, getFormattedBotAlertLimitPrice, wasAlertExecuted, getFormattedBotAlertQuantity } from "../../utils/alert-utils";
import { BotAction } from "../../../api/enums";
import { formatDate } from "../../../shared/utils";
import { useEmotionCss } from "@ant-design/use-emotion-css";
import { CSSProperties, useState } from "react";
import { PlaceOrderFormInitialValues } from "../../../trade/types/place-order-form-initial-values";
import { getFormInitialValuesFromAlert } from "../../utils/get-form-initial-values-from-alert";
import { ExecuteAlertDialog } from "./execute-alert-dialog";
import { AlertInfo } from "./alert-info";
import { signalsDateFormat } from "../../../shared/constants";

export function AlertRow ({ record }: { record?: BotAlertWithOrders }) {
  if (!record) {
    return <tr className="ant-table-placeholder">
      {/* colspan must match number of rows */}
      <td className="ant-table-cell" colSpan={8}>
        <Empty description="No alerts" image={Empty.PRESENTED_IMAGE_SIMPLE} />
      </td>
    </tr>
  }

  return <AlertRowInternal alert={record} />
}

export function AlertRowInternal({ alert }: { alert: BotAlertWithOrders }) {
  const [notificationApi, contextHolder] = notification.useNotification();

  const isClose = alert.action == BotAction.close
  const autotraded = wasAlertExecuted(alert);

  const showTradeButton = !isClose && !autotraded;
    
  const [executeModalOpen, setExecuteModalOpen] = useState(false);
  const [placeOrderFormInitialValues, setPlaceOrderFormInitialValues] = useState<PlaceOrderFormInitialValues | null>(null);
  const [infoModalOpen, setInfoModalOpen] = useState(false);

  const noWrapStyles : CSSProperties = { whiteSpace: 'nowrap', wordBreak: 'keep-all' }

  const dotClass = useEmotionCss(({ token }) => ({
    backgroundColor: autotraded ? token.colorSuccess : token.colorError,
    width: 8,
    height: 8,
    borderRadius: '100%',
    display: 'inline-block'
  }));

  const handleExecuteClick = () => {
    const formValues = getFormInitialValuesFromAlert(alert);
    if (formValues != null) {
      setPlaceOrderFormInitialValues(formValues);
      setExecuteModalOpen(true);
    }
  }

  return <tr>
    { contextHolder }
    <td style={noWrapStyles}> { alert.bot.name } </td>
    <td> 
      <span style={noWrapStyles}>
        { formatDate(alert.created_at!, signalsDateFormat) }
      </span>
    </td>
    <td> 
      <Space size='small'>
        <span className={dotClass}></span>{ autotraded ? 'Yes' : 'No' } 
      </Space>
    </td>
    <td> { botAssetTypeLabels[alert.asset_type] } </td>
    <td> { getFormattedBotAction(alert.action) } </td>
    <td style={noWrapStyles}> { getFormattedBotAlertSymbol(alert) } </td>
    <td> { getFormattedBotAlertLimitPrice(alert) }</td>
    <td> { getFormattedBotAlertQuantity(alert) }</td>
    <td>
      <Space>
        <Button onClick={() => setInfoModalOpen(true)}>
          Info
        </Button>
        {
          showTradeButton && (
            <Button type="primary" onClick={handleExecuteClick}>
              Trade
            </Button>
          )
        }
      </Space>
    </td>
    {
      placeOrderFormInitialValues && (
        <ExecuteAlertDialog
          showModal={executeModalOpen}
          setShowModal={setExecuteModalOpen}
          placeOrderFormInitialValues={placeOrderFormInitialValues}
          onSuccess={() => {
            notificationApi.success({
              message: 'Success'
            });
          }}
        />
      )
    }
    <Modal
      open={infoModalOpen}
      onCancel={() => setInfoModalOpen(false)}
      footer={null}
    >
      <AlertInfo alert={alert} />
    </Modal>
  </tr>
}