import { Button, Col, Input, Result, Row } from "antd";
import { directionLabels, orderTypeLabels } from "../../api/enum-labels";
import { Side, TimeInForce } from "../../api/enums";
import { BrokerOrder } from "../../api/orders"
import { ErrorMessage } from "../../shared/components/ErrorMessage";
import { FormLabel } from "../../trade/components/FormLabel";
import { useEditOptionsOrderForm } from "../hooks/useEditOptionsOrderForm";
import { LimitInput } from "./inputs/LimitInput";
import { QuantityInput } from "./inputs/QuantityInput";
import { SideReadonlyInput } from "./inputs/SideReadonlyInput";
import { StopInput } from "./inputs/StopInput";
import { TIFInput } from "./inputs/TIFInput";
import { useSelectedBroker } from "../../brokers/context/SelectedBrokerContext";
import { isReplaceOrderSupported } from "../utils/orders";

export const EditOptionOrderForm = ({
  order,
  onSuccess,
  onError,
} : {
  order: BrokerOrder,
  onSuccess?: () => void,
  onError?: (e: any) => void,
}) => {

  const {
    error, submitting, submit,
    limitEditable, limitPrice, setLimitPrice,
    stopEditable, stopPrice, setStopPrice,
    quantity, setQuantity,
    tif, setTif,
  } = useEditOptionsOrderForm({
    order: order,
    onSuccess,
    onError,
  });

  const handleSubmit = async () => {
    await submit();
  }

  const { broker } = useSelectedBroker();
  const supportsReplace = isReplaceOrderSupported(broker?.account?.type || null, broker?.paper || false);

  if (!supportsReplace) {
    return <Result 
      status='error' 
      title='Cannot Replace Order'
      subTitle='Selected broker implementation does not support replacing orders'
    />
  }

  return <Row gutter={[16, 16]}>
    {
      error && <Col span={24}>
        <ErrorMessage error={error} />
      </Col>
    }

    <Col span={24}>
      <FormLabel>
        Side
      </FormLabel>
      <SideReadonlyInput 
        value={order.side}
        options={[Side.Buy, Side.Sell]}
      />
    </Col>

    <Col span={24}>
      <FormLabel>
        Stock
      </FormLabel>
      <Input
        disabled
        value={order.stock || ''}
      />
    </Col>

    <Col span={12}>
      <FormLabel>
        Direction
      </FormLabel>
      <Input
        disabled
        placeholder="Direction"
        value={order.direction ? directionLabels[order.direction] : ''}
      />
    </Col>

    <Col span={12}>
      <FormLabel>
        Expiration Date
      </FormLabel>
      <Input
        disabled
        placeholder="Expiration Date"
        value={order?.expiration_date || ''}
      />
    </Col>

    <Col span={12}>
      <FormLabel>
        Strike Price
      </FormLabel>
      <Input
        disabled
        addonBefore="$"
        placeholder="Strike price"
        value={order?.strike_price || ''}
      />
    </Col>

    <Col span={12}>
      <FormLabel>
        Order Type
      </FormLabel>
      <Input
        disabled
        value={order.order_type ? orderTypeLabels[order.order_type] : ''}
      />
    </Col>


    <Col span={24}>
      <FormLabel>
        Quantity
      </FormLabel>
      <QuantityInput 
        setValue={(v) => setQuantity(v || 1)}
        value={quantity}
        precision={0}
      />
    </Col>

    <Col span={ stopEditable ? 12 : 24 }>
      <FormLabel>
        Limit Price
      </FormLabel>
      <LimitInput
        value={limitPrice}
        disabled={!limitEditable}
        setValue={v=> setLimitPrice(v || 0)}
      />
    </Col>

    {
      stopEditable && <Col span={12}>
        <FormLabel>
          Stop Price
        </FormLabel>
        <StopInput
          value={stopPrice}
          disabled={!stopEditable}
          setValue={v=> setStopPrice(v || 0)}
        />
      </Col>
    }

    <Col span={24}>
      <FormLabel>
        Time in force
      </FormLabel>
      <TIFInput
        options={Object.values(TimeInForce)}
        value={tif}
        setValue={setTif}
      />
    </Col>
    
    <Col span={24} style={{ justifyContent: 'flex-end', display: 'flex' }}>
      <Button 
        type="primary" 
        loading={submitting} 
        disabled={submitting}
        onClick={handleSubmit}
      >
        Submit
      </Button>
    </Col>

  </Row>
}