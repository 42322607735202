import { Modal } from "antd";
import { TradeCrypto } from "../../trade/components/TradeCrypto";
import { TradeOptions } from "../../trade/components/TradeOptions";
import { TradeStocks } from "../../trade/components/TradeStocks";
import { isCrypto, isOptions, isStocks, PlaceOrderFormInitialValues } from "../../trade/types/place-order-form-initial-values";

export const ClosePositionDialog = ({
  showModal,
  setShowModal,
  placeOrderFormInitialValues,
  onSuccess
}: {
  showModal: boolean;
  setShowModal: (showModal: boolean) => void;
  placeOrderFormInitialValues: PlaceOrderFormInitialValues;
  onSuccess?: () => void;
}) => {

  const closeModal = () => {
    if (onSuccess !== undefined) {
      onSuccess()
    } 
    setShowModal(false)
  };

  let form = null;

  if (isOptions(placeOrderFormInitialValues)) {
    form = <TradeOptions
      initialValues={placeOrderFormInitialValues}
      onSuccess={closeModal}
      positionCloseMode
    />
  }

  if (isStocks(placeOrderFormInitialValues)) {
    form = <TradeStocks
      initialValues={placeOrderFormInitialValues}
      onSuccess={closeModal}
      positionCloseMode
    />
  }

  if (isCrypto(placeOrderFormInitialValues)) {
    form = <TradeCrypto
      initialValues={placeOrderFormInitialValues}
      onSuccess={closeModal}
      positionCloseMode
    />
  }

  return (
    <Modal
      title="Are You Sure you want to close your position?"
      open={showModal}
      onCancel={() => setShowModal(false)}
      footer={null}
    >
      {form}
    </Modal>
  );
};
