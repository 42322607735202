import { Card, Descriptions, Divider, Space, Typography } from "antd";
import { userRoleLabels } from "../../api/enum-labels";
import { useAuthUser } from "../../auth/hooks/useAuthUser";
import { SignalProviderLink } from "./SignalProviderLink";
import { fixDecimalPlaces, formatNumberAuto } from "../../shared/utils";
import { useAuthUserSignalProviders } from "../../signal-providers/hooks/useAuthUserSignalProviders";
import { useMaskInfoContext } from "../../shared/context/MaskInfoContext";

const { Text, Title, Paragraph } = Typography;

export const AccountInfo = () => {
  const { user } = useAuthUser();
  const { providers } = useAuthUserSignalProviders();
  const { masked } = useMaskInfoContext();

  if (!user) return null;
  
  const total = formatNumberAuto(fixDecimalPlaces(user?.total_coins || 0));
  const monthly = formatNumberAuto(fixDecimalPlaces(user?.monthly_coins || 0));
  const extra = formatNumberAuto(fixDecimalPlaces(user?.coin_balance || 0));

  return <div>

    <Descriptions
     style={{ maxWidth: 600 }}
     bordered
     column={{ xs: 1, sm: 1, md: 1 }}
    >
      <Descriptions.Item label="Name">
        { user?.name }
      </Descriptions.Item>
      <Descriptions.Item label="Email">
        {
          !masked 
            ? user.email
            : '***************'
        }
      </Descriptions.Item>
      <Descriptions.Item label="Username">
        { user?.username ? `@${user.username}` : '-' }
      </Descriptions.Item>
      {
        !!(user?.role) && <Descriptions.Item label="Account Type">
          { userRoleLabels[user.role] }
        </Descriptions.Item>
      }
      <Descriptions.Item label="CandeFi Coins">
        { total }
        ({monthly} Monthly {extra} Extra)
      </Descriptions.Item>
    </Descriptions>
    {
      providers.length > 0 && <>
        <Divider />
        <Title level={3}>Your Signal Provider Hub</Title>
        <Paragraph type="secondary">
          {/* Share this unique link of your membership plan with your followers */}
          {/* Share this exclusive link to your membership plan with your followers: */}
          Share your exclusive membership link to invite followers to your community!
          
        </Paragraph>
        <Card size="small">
          <Space direction="vertical" style={{ width: '100%' }}>
            {
              providers.map(provider => (
                <SignalProviderLink  provider={provider}/>
              ))
            }
          </Space>
        </Card>
      </>
    }
  </div>
}