import { useEmotionCss } from "@ant-design/use-emotion-css";
import { Button, Card, Input, Radio, Typography } from "antd";
import { useState } from "react";
import api from "../../api";
import { ParseSignalResponse } from "../../api/discord-signal-parser";
import { assetTypeLabels } from "../../api/enum-labels";
import { AssetType } from "../../api/enums";
import Flex from "../../shared/components/Flex";
import { useThemeContext } from "../../shared/context/ThemeContext";
import { getErrorMessage } from "../../shared/utils";

export function SignalNewParserForm({
  onSignal,
}: {
  onSignal: (signal: ParseSignalResponse) => void | Promise<void>;
}) {

  const [signal, setSignal] = useState('');
  const [assetType, setAssetType] = useState(AssetType.Options);
  const [error, setError] = useState<unknown>(null);

  const [submitting, setSubmitting] = useState(false);
  const { isDark } = useThemeContext();

  const onExecuteClick = async () => {
    setError(null);

    if (!signal || signal.length <= 0) {
      setError('required');
      return
    }

    try {
      setSubmitting(true);

      const response = await api.discordSignalParser.parseDiscordSignal({
        asset_type: assetType,
        signal: signal,
      });

      if (response === null) {
        setError('could not parse the signal');
        return;
      }

      await onSignal(response);

    } catch (e) {

      setError(e);

    } finally {

      setSubmitting(false);

    }


  }

  const inputClass = useEmotionCss(() => ({
    '&.ant-input.ant-input-lg': {
      padding: '0.8rem 1rem'
    },
    backgroundColor: !isDark
      ? '#f8f8f8'
      : undefined,
  }));

  const searchContainerClass = useEmotionCss(({ token }) => ({
    width: '100%',
    [`@media(min-width: ${token.sizeLG}px)`]: {
      maxWidth: 600
    }
  }));

  const buttonClass = useEmotionCss(({ }) => ({
    height: 50,
    paddingLeft: '3rem',
    paddingRight: '3rem'
  }));

  const labelClass = useEmotionCss(({ }) => ({
    fontSize: '1rem',
  }));

  const containerClass = useEmotionCss(() => ({
    display: 'flex',
    flexDirection: 'column',
    containerType: 'inline-size'
  }))

  const formLayoutClass = useEmotionCss(() => ({
    display: 'flex',
    flexDirection: 'column',
    [`@container (width > 500px)`]: {
      flexDirection: 'row',
    }
  }));

  const sidebarClass = useEmotionCss(() => ({
    display: 'flex',
    flexDirection: 'row',
    flex: 0,
    alignItems: 'flex-start',
    [`@container (width > 500px)`]: {
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'flex-end'
    },
    padding: '1rem',
    minWidth: 150
  }));

  const assetTypeOptionsClass = useEmotionCss(() => ({
    display: 'flex',
    flexDirection: 'row',
    gap: 16,
    [`@container (width > 500px)`]: {
      flexDirection: 'column',
    },
  }));

  const sidebar2Class = useEmotionCss(() => ({
    flex: 0, 
    [`@container (width > 800px)`]: {      
      padding: '1rem', 
      minWidth: 150
    },
  }))

  return <div className={containerClass}>
    <div className={formLayoutClass}>

      {/* Sidebar 1 */}
      <div
        className={sidebarClass}
      >
        <Radio.Group value={assetType} onChange={(e) => setAssetType(e.target.value)}>
          <div className={assetTypeOptionsClass}>
            {[AssetType.Stocks, AssetType.Options, AssetType.Crypto].map(type => (
              <Radio key={type} value={type}>
                {assetTypeLabels[type]}
              </Radio>
            ))}
          </div>
        </Radio.Group>
      </div>

      {/* Signal Card */}
      <Flex flexGrow={1} justifyContent={'center'}>
        <Card className={searchContainerClass}>
          <Flex flexDirection="column" gap={20}>
            <Flex flexDirection="column" gap={16}>
              {/* <label className={labelClass}>
                Enter Discord Signal
              </label> */}
              <label className={labelClass}>
                Submit Trade Command or Discord Signal
              </label>
              
              <Flex flexDirection="column" gap={8}>
                <>
                  <Input
                    className={inputClass}
                    size="large" 
                    value={signal}
                    onChange={(e) => setSignal(e.target.value)}
                  />
                  {
                    error && (
                      <Typography.Text type="danger">
                        { getErrorMessage(error) }
                      </Typography.Text>
                    )
                  }
                </>
              </Flex>
            </Flex>
            <Flex justifyContent={'flex-end'}>
              <Button 
                loading={submitting}
                disabled={submitting} 
                className={buttonClass} 
                size="large" 
                type="primary"
                onClick={onExecuteClick}
              >
                Execute Signal
              </Button>
            </Flex>
          </Flex>
        </Card>
      </Flex>

      {/* Sidebar 2 */}
      <div className={sidebar2Class}></div>
    </div>

  </div>
}