import { Button, Card, Popconfirm, Result, Typography } from "antd";
import { CommunityAnalystBasic, CommunityInviteWithUserAndCommunity, SignalProvider } from "../../../api/signal-providers";
import useSWR from 'swr';
import api from "../../../api";
import { DeleteOutlined, UsergroupAddOutlined } from '@ant-design/icons';
import { useEmotionCss } from "@ant-design/use-emotion-css";
import { communityInviteStatusLabels } from "../../../api/enum-labels";
import teamIllustration from './team.svg';
import { useState } from "react";
import { useCardWithTopActions } from "../../../shared/styles/useCardWithTopActions";
import { InviteModal } from "./InviteModal";

export function AnalystSignalProviderTeamSection({
  provider
}: {
  provider: SignalProvider,
}) {

  const [inviteModalOpen, setInviteModalOpen] = useState(false);
  const openInviteModal = () => setInviteModalOpen(true);
  const closeInviteModal = () => setInviteModalOpen(false);

  const analysts = useSWR(['signal-providers', provider.id, 'analysts'], (key) => {
    const providerId = key[1];
    return api.signalProviders.getSignalProviderAnalysts(providerId);
  });

  const invites = useSWR(['signal-providers', provider.id, 'invites'], (key) => {
    const providerId = key[1];
    return api.signalProviders.getSignalProviderInvites(providerId);
  });

  const noAnalysts = !analysts.isLoading && !analysts.error && (analysts.data || []).length <= 0;
  const noInvites = !invites.isLoading && !invites.error && (invites.data || []).length <= 0;
  const noData = noAnalysts && noInvites;

  const reloadInvites = () => { invites.mutate() };
  const reloadAnalysts = () => { analysts.mutate() };

  const onInviteSent = () => {
    analysts.mutate();
    invites.mutate();
    setInviteModalOpen(false);
  }

  const listStyle = useEmotionCss(({ token }) => ({
    padding: 0,
    // background: 'red',
    '& > li': {
      listStyle: 'none',
      display: 'flex',
      flexDirection: 'column',
      gap: 8,
      width: '100%',
      padding: '16px 0',
      // background: 'green',
      [`@media(min-width: ${token.screenMDMin}px)`]: {
        justifyContent: 'space-between',
        flexDirection: 'row',
        alignItems: 'center',
      }
    }
  }));

  const cardStyle = useCardWithTopActions()

  return <>
    <Card 
      className={cardStyle}
      title='Your Team' 
      loading={analysts.isLoading || invites.isLoading}
      extra={(
        <>
          <Button type="primary" onClick={openInviteModal}>
            <UsergroupAddOutlined /> Invite Analyst
          </Button>
        </>
      )}
    >
      {
        noData && <NoData openModal={openInviteModal} />
      }
      <ul className={listStyle}>
        {
          (invites.data || []).map(invite => (
            <InviteListItem invite={invite} onChange={reloadInvites} key={invite.id} />
          ))
        }
        {
          (analysts.data || []).map(analyst => (
            <AnalystListItem analyst={analyst} onChange={reloadAnalysts} key={analyst.id} />
          ))
        }
      </ul>
    </Card>
    <InviteModal
      signalProviderId={provider.id}
      open={inviteModalOpen}
      onClose={closeInviteModal}
      onSuccess={onInviteSent}
    />
  </>
}

function NoData({ openModal }: { openModal: () => void, }) {
  return (
    <Result
      title="Invite analysts to join your team"
      icon={<img style={{ maxWidth: 300 }} src={teamIllustration} />}
      subTitle={
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Typography.Paragraph
            type="secondary"
            style={{
              maxWidth: 400,
              // @ts-ignore
              textWrap: 'balance'
            }}
          >
            You can invite other analysts to join your community, the signals they share would also be shared in your community
          </Typography.Paragraph>
        </div>
      }
      extra={
        <Button size="large" type="primary" onClick={openModal}>
          Invite Analyst
        </Button>
      }
    />
  )
}


function InviteListItem({ invite, onChange }: { invite: CommunityInviteWithUserAndCommunity, onChange: () => void | Promise<void> }) {
  return <li>
    <div>
      <Typography.Title level={5}>
        @{invite.user.username} ({invite.user.name})
      </Typography.Title>
      <Typography.Text type="secondary">
        {communityInviteStatusLabels[invite.status]} Invite
      </Typography.Text>
    </div>
    <div>
      <Popconfirm
        title="Are your sure?"
        description=""
        onConfirm={async () => {
          await api.signalProviders.deleteInvite(invite.signal_provider_id, invite.id);
          await onChange();
        }}
      >
        <Button>
          <DeleteOutlined />
        </Button>
      </Popconfirm>
    </div>
  </li>
}

function AnalystListItem({ analyst, onChange }: { analyst: CommunityAnalystBasic, onChange: () => void | Promise<void> }) {
  return <li>
    <div>
      <Typography.Title level={5}>
        @{analyst.analyst.username} ({analyst.analyst.name})
      </Typography.Title>
      <Typography.Text type="secondary">
        Team Member
      </Typography.Text>
    </div>
    <div>
      <Popconfirm
        title="Are your sure?"
        description=""
        onConfirm={async () => {
          await api.signalProviders.deleteAnalyst(analyst.signal_provider.id, analyst.id);
          await onChange();
        }}
      >
        <Button>
          <DeleteOutlined />
        </Button>
      </Popconfirm>
    </div>
  </li>
}