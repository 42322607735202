import { Button, Col, DatePicker, notification, Form, Input, InputNumber, Row, RowProps, Select, Switch, TimePicker, Typography, Tooltip, Space, Divider } from "antd";
import { UsersAutotradePreference } from "../../../api/autotrade-preference";
import { CSSProperties, useEffect, useState } from "react";
import { AutotradeOptionsExpiryType, AutotradeStrikePreference, UserRole } from "../../../api/enums";
import { autotradeOptionsExpiryTypeLabels, autotradeStrikePreferenceLabels, timeInForceLabels } from "../../../api/enum-labels";
import api from "../../../api";
import { ErrorMessage } from "../../../shared/components/ErrorMessage";
import { useAuthUser } from "../../../auth/hooks/useAuthUser";
import { InfoCircleOutlined } from '@ant-design/icons';
import { useEmotionCss } from "@ant-design/use-emotion-css";
import { CryptoMaxPrice, CryptoMaxQuantity, CryptoTIF, OptionsMaxQuantity, StocksMaxQuantity } from "./descriptions";
import { FormInput, CryptoTimeType, toPreference, toFormInput, dayJSTimeFormat, dayJSDateFormat, cryptoTimeTypeLabels } from "./form-input";

export function AutotradePreferenceForm({
  initialValues,
  onSuccess,
}: {
  initialValues: UsersAutotradePreference | null,
  onSuccess?: (prefs: UsersAutotradePreference) => void 
}) {
  const { user } = useAuthUser();
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState<unknown>(null);
  const [notificationApi, contextHolder] = notification.useNotification();
  
  const rowProps: RowProps = { gutter: 16 }
  const formItemStyle: CSSProperties = { marginBottom: 0 };
  const fieldStyle: CSSProperties = { width: '100%' };
  const [form] = Form.useForm<FormInput>();
  const expiryType = Form.useWatch('options_expiry_type', form);
  const optionsExpirationDateRequired = expiryType === AutotradeOptionsExpiryType.Manual;
  const cryptoTimeType = Form.useWatch('crypto_time_type', form);
  const crypto24Hours = cryptoTimeType == CryptoTimeType.allTime;

  useEffect(() => {
    if (crypto24Hours) {
      form.setFieldValue('crypto_start_time', null);
      form.setFieldValue('crypto_end_time', null);
    }
  }, [crypto24Hours, form])

  const isAnalyst = user?.role === UserRole.analyst;

  const switchFormItemClass = useEmotionCss(({ theme, token }) => ({
    '& .ant-row.ant-form-item-row': {
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      '& .ant-col': {
        'width': 'auto',
        '&.ant-form-item-label': {
          flexGrow: 1,
          '& label': {
            width: '100%',
          },
        },
        '&.ant-form-item-control': {
          flexGrow: 0
        }
      }
    }
  }));

  const handleSubmit = async (data: FormInput) => {
    try {
      console.log(data);
      setError(null);
      setSubmitting(true);
      const prefs = toPreference(data);
      await api.autotradePreference.update(prefs);
      if (onSuccess !== undefined) {
        onSuccess(prefs);
      }
      notificationApi.success({
        message: 'Autotrade preference updated'
      })
    } catch(e) {
      setError(e);
    } finally {
      setSubmitting(false);
    }
  }

  return <Form<FormInput>
    form={form}
    layout="vertical"
    onFinish={handleSubmit}
    initialValues={toFormInput(initialValues) || undefined}
    style={{ padding: '16px 0', display: 'flex', flexDirection: 'column', gap: 16 }}
  > 
    { contextHolder }
    <ErrorMessage error={error} />

    {/* enabled or not */}
    <Row {...rowProps}>
      <Col  span={24}>
        <Form.Item
          className={switchFormItemClass}
          label='AutoTrade Enabled' 
          style={formItemStyle} 
          name="autotrade_enabled" 
          valuePropName="checked">
          <Switch />
        </Form.Item>
      </Col>
    </Row>

    {/* Hiding the "share signal with subscribers" for now, based on adoption, we might charge higher commission for this feature*/}
    {/* share signal with subscribers */}
    {
      isAnalyst && (
        <Row {...rowProps}>
          <Col span={24}>
            <Form.Item 
              label='Share With Subscribers'
              style={formItemStyle}
              className={switchFormItemClass} 
              name="share" 
              valuePropName="checked">
              <Switch />
            </Form.Item>
          </Col>
        </Row>
      )
    }

    {/* time range */}
    <Row {...rowProps}>
      <Col span={12}>
        <Form.Item
          name="autotrade_start_time"
          label="Start Time"
          style={formItemStyle}>
          <TimePicker
            style={fieldStyle}
            showSecond={false}
            format={dayJSTimeFormat}
            allowClear
          />
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item
          name="autotrade_end_time"
          label="End Time"
          style={formItemStyle}
        >
          <TimePicker
            showSecond={false}
            style={fieldStyle}
            format={dayJSTimeFormat}
            allowClear
          />
        </Form.Item>
      </Col>
    </Row>

    <Divider orientation="center">Stocks</Divider>

    <Row {...rowProps}>
      <Col span={24}>
        <Form.Item
          style={formItemStyle}
          name='stocks_max_quantity'
          label={<LabelWithHint label="Lot Size (Max Quantity)" hint={StocksMaxQuantity} />}
        >
          <InputNumber
            addonBefore={'$'}
            style={fieldStyle}
            type="number"
          />
        </Form.Item>
      </Col>
    </Row>

    <Row {...rowProps}>
      <Col span={12}>
        <Form.Item
          style={formItemStyle}
          name='stocks_min_price'
          label={<LabelWithHint label="Min Price" hint="Min price of single stock" />}
        >
          <InputNumber
            addonBefore={'$'}
            style={fieldStyle}
            type="number"
          />
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item
          style={formItemStyle}
          name='stocks_max_price'
          label={<LabelWithHint label="Max Price" hint="Max price of single stock" />}
        >
          <InputNumber
            addonBefore={'$'}
            style={fieldStyle}
            type="number"
          />
        </Form.Item>
      </Col>
    </Row>

    <Divider orientation="center">Options</Divider>

    <Row {...rowProps}>
      <Col span={24}>
        <Form.Item
          style={formItemStyle}
          name='options_max_quantity'
          label={<LabelWithHint label="Lot Size (Max Quantity)" hint={OptionsMaxQuantity} />}
        >
          <InputNumber
            style={fieldStyle}
            type="number"
            addonBefore={'$'}
          />
        </Form.Item>
      </Col>
    </Row>
{/* 
    <Row {...rowProps}>
      <Col span={12}>
        <Form.Item
          style={formItemStyle}
          name='options_min_price'
          label={<LabelWithHint label="Min Price" hint="The lowest allowable options price to open a trade. If a options price falls below this number, the trade signal will be skipped." />}
        >
          <InputNumber
            addonBefore={'$'}
            style={fieldStyle}
            type="number"
          />
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item
          style={formItemStyle}
          name='options_max_price'
          label={<LabelWithHint label="Max Price" hint="The highest allowable options price to open a trade. Prices exceeding this amount will not be traded." />}          
        >
          <InputNumber
            addonBefore={'$'}
            style={fieldStyle}
            type="number"
          />
        </Form.Item>
      </Col>
    </Row> */}

    <Row {...rowProps}>
      <Col span={12}>
        <Form.Item
          style={formItemStyle}
          name='options_min_premium'
          label={<LabelWithHint label="Min Premium" hint="Min per unit premium of options" />}
        >
          <InputNumber
            addonBefore={'$'}
            style={fieldStyle}
            type="number"
          />
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item
          style={formItemStyle}
          name='options_max_premium'
          label={<LabelWithHint label="Max Premium" hint="Max per unit premium of options" />}
        >
          <InputNumber
            addonBefore={'$'}
            style={fieldStyle}
            type="number"
          />
        </Form.Item>
      </Col>
    </Row>

    <Row {...rowProps}>
      <Col span={optionsExpirationDateRequired ? 12 : 24}>
        <Form.Item<AutotradeOptionsExpiryType>
          style={formItemStyle}
          name='options_expiry_type'
          label='Expiry Date Type'
          required
          rules={[
            { required: true, message: 'Required' }
          ]}
        >
          <Select
            style={fieldStyle}
            options={
              Object.entries(autotradeOptionsExpiryTypeLabels)
                .map(e => ({ label: e[1], value: e[0] }))
            }>
          </Select>
        </Form.Item>
      </Col>
      {
        optionsExpirationDateRequired && (
          <Col span={12}>
            <Form.Item
              style={formItemStyle}
              name='options_expiry_date'
              label='Expiry Date'
              dependencies={['options_expiry_type']}
              rules={[ { required: optionsExpirationDateRequired } ]}
            >
              <DatePicker
                style={fieldStyle}
                format={dayJSDateFormat}
                allowClear
              />
            </Form.Item>
          </Col>
        )
      }
    </Row>

    <Row {...rowProps}>
      <Col span={24}>
        <Form.Item<AutotradeStrikePreference>
          style={formItemStyle}
          name='strike_preference'
          label='Strike Type'
          required
          rules={[
            { required: true, message: '' }
          ]}
        >
          <Select
            style={fieldStyle}
            options={
              Object.entries(autotradeStrikePreferenceLabels)
                .map(e => ({ label: e[1], value: e[0] }))
            }>
          </Select>
        </Form.Item>
      </Col>
    </Row>

    <Divider orientation="center">Crypto</Divider>

    <Row {...rowProps}>
      <Col span={24}>
        <Form.Item
          rules={[{ required: true, message: 'required' }]}
          name="crypto_time_type"
          label="Crypto Time"
          style={formItemStyle}>
          <Select
            style={fieldStyle}
            options={
              Object.entries(cryptoTimeTypeLabels)
              .map(e => ({ label: e[1], value: e[0] }))
            }
          >
          </Select>
        </Form.Item>
      </Col>
    </Row>

    {
      !crypto24Hours && (
        <Row {...rowProps}>
          <Col span={12}>
            <Form.Item
              rules={
                crypto24Hours 
                  ? undefined
                  : [{ required: true, message: 'required' }] 
              }
              name="crypto_start_time"
              label="Start Time"
              style={formItemStyle}>
              <TimePicker
                style={fieldStyle}
                showSecond={false}
                format={dayJSTimeFormat}
                allowClear
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              rules={
                crypto24Hours 
                  ? undefined
                  : [{ required: true, message: 'required' }]
              }
              name="crypto_end_time"
              label="End Time"
              style={formItemStyle}
            >
              <TimePicker
                showSecond={false}
                style={fieldStyle}
                format={dayJSTimeFormat}
                allowClear
              />
            </Form.Item>
          </Col>
        </Row>
      )
    }

    {/* tif */}
    <Row {...rowProps}>
      <Col span={24}>
        <Form.Item
          name="crypto_tif"
          label={<LabelWithHint label="Crypto Default TIF" hint={CryptoTIF} />}
          style={formItemStyle}>
          <Select
            allowClear
            style={fieldStyle}
            options={
              Object.entries(timeInForceLabels)
              .map(e => ({ label: e[1], value: e[0] }))
            }
          >
          </Select>
        </Form.Item>
      </Col>
    </Row>

    <Row {...rowProps}>
      <Col span={12}>
        <Form.Item
          style={formItemStyle}
          name='crypto_max_price'
          label={<LabelWithHint label="Lot Size ($)" hint={CryptoMaxPrice} />}
        >
          <InputNumber
            addonBefore={'$'}
            style={fieldStyle}
            type="number"
          />
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item
          style={formItemStyle}
          name='crypto_max_quantity'
          label={<LabelWithHint label="Lot Size (Coin)" hint={CryptoMaxQuantity} />}
        >
          <InputNumber
            addonBefore={'🪙'}
            style={fieldStyle}
            type="number"
          />
        </Form.Item>
      </Col>
    </Row>

    <div style={{ marginBottom: 24 }}></div>

    <Row {...rowProps}>
      <Col span={24}>
        <Button 
          block 
          disabled={submitting}
          loading={submitting}
          htmlType="submit" 
          type="primary" 
          size="large" 
        >
          Save
        </Button>
      </Col>
    </Row>
  </Form>
}

function LabelWithHint({ label, hint } : { label: string, hint: string }) {
  return <Space>
    <span>{ label }</span>
    <Tooltip title={hint}>
      <InfoCircleOutlined />
    </Tooltip>
  </Space>
}