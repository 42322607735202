import axios, { AxiosError } from 'axios';
import { logout } from '../auth/utils';
import { BASE_URL } from './constants';
import transformError from './errors';

// axios instance for refreshing access token
const refreshInstance = axios.create({
    baseURL: BASE_URL,
});

// transforms errors
refreshInstance.interceptors.response.use(null, async (e) => {
    if (isRefreshTokenExpiredError(e)) {
        // refresh token has expired logout
        await logout();
    }
    return transformError(e)
});

function isRefreshTokenExpiredError(e: any) {
    return e instanceof AxiosError 
        && e.response 
        && e.response.status === 422;
}

export default refreshInstance;