import axios from './axios';
import { AssetType, CryptoAmountUnit, Direction, OrderType, Side, TimeInForce, TrailType } from './enums';
import { GenericStatusResponse } from './types';
import { GenericIDResponse } from './types';

export interface PlaceOptionsOrderRequest {
  order_type: OrderType,
  time_in_force: TimeInForce,
  limit_price?: string
  stop_price?: string
  quantity: number
  ticker_symbol: string
  strike_price: string
  direction: Direction
  expiration_date: string
  side: Side
  share?: boolean
}

export interface PlaceStocksOrderRequest {
  ticker_symbol: string,
  order_type: OrderType,
  side: Side,
  time_in_force: TimeInForce,
  quantity: number,
  limit_price?: string,
  stop_price?: string,
  trail_type?: TrailType,
  trail_value?: string
  ouside_regular_trading_hour: boolean
  share?: boolean
}

export interface ReplaceStocksOrderRequest {
  time_in_force: TimeInForce;
  quantity: number;
  limit_price?: number;
  order_type: OrderType;
}

export interface ReplaceOptionsOrderRequest {
  time_in_force: TimeInForce;
  quantity: number;
  limit_price?: number;
  stop_price?: number;
  order_type: OrderType;
}

/**
 * Schema of order stored in database.
 * This differs from BrokerSchema
 */
export interface DBOrder {
  broker_order_id: string;
  asset_type: AssetType;
  stock: string | null;
  side: Side | null;
  order_type: OrderType | null;
  time_in_force: TimeInForce | null;
  expiration_date: string | null;
  direction: Direction | null;
  strike_price: number | null;
  limit_price: number | null;
  stop_price: number | null;
  quantity: number | null;
  quantity_multipler: number | null;
  crypto_amount_unit: CryptoAmountUnit | null;
  crypto_amount: number | null;
  crypto_limit_price: number | null;
  crypto_stop_price: number | null;
}

/**
 * Generic broker independent order schema.
 * Orders from brokers will be converted to this format by backend.
 */
export interface BrokerOrder {
  broker_order_id: string;
  asset_type: AssetType;
  stock: string | null;
  side: Side | null;
  order_type: OrderType | null;
  time_in_force: TimeInForce | null;
  expiration_date: string | null;
  direction: Direction | null;
  strike_price: number | null;
  limit_price: number | null;
  stop_price: number | null;
  quantity: number | null;
  quantity_multipler: number | null;
  // for options its number of lots.
  // for example if 3 lots were filled, this would be 3
  filled_quantity: number | null;
  // unix timestamp seconds
  filled_time: number | null;
  // unix timestamp seconds
  created_at: number | null;
  // average filled price
  // for options this is price per lot
  average_price: number | null;
  crypto_amount_unit: CryptoAmountUnit | null;
  crypto_amount: number | null;
  crypto_limit_price: number | null;
  crypto_stop_price: number | null;
  crypto_filled_amount: number | null;
  crypto_filled_quantity: number | null;
}


export type OrderCursorParams = {
  webull_start_date_str: string | null;
  webull_end_date_str: string | null;
  webull_last_create_time_0: number | null;
  robinhood_stocks_cursor: string | null;
  robinhood_options_cursor: string | null;
  webullpay_crypto_cursor: string | null;
  robinhood_crypto_cursor: string | null;
}

export type ListOrdersRequest = OrderCursorParams

export type ListOrdersResponse = OrderCursorParams & {
  orders: BrokerOrder[]
  has_more: boolean
}

export type PlaceCryptoOrderRequest = {
  symbol: string
  side: Side
  order_type: OrderType
  amount_unit: CryptoAmountUnit
  amount: number
  limit_price: number | null
  stop_price: number | null
  tif: TimeInForce
  share: boolean | null
}

const ordersApi = {
  async placeOptionsOrder(request : PlaceOptionsOrderRequest) {
    const { data } = await axios.post<GenericIDResponse>('/orders/options', request, {
      needsBrokerId: true,
    });
    return data;
  },

  async placeStockOrder(request : PlaceStocksOrderRequest) {
    const { data } = await axios.post<GenericIDResponse>('/orders/stocks', request, {
      needsBrokerId: true,
    });
    return data;
  },

  async placeCryptoOrder(request: PlaceCryptoOrderRequest) {
    const { data } = await axios.post<GenericIDResponse>('/orders/crypto', request, {
      needsBrokerId: true,
    });
    return data;
  },

  async replaceStocksOrder(brokerOrderId: string, request : ReplaceStocksOrderRequest) {
    const { data } = await axios.patch<GenericStatusResponse>(
      `/orders/stocks/${brokerOrderId}`, 
      request,
      {
        needsBrokerId: true,
      }
    );
    return data;
  },

  async replaceOptionsOrder(brokerOrderId: string, request : ReplaceOptionsOrderRequest) {
    const { data } = await axios.patch<GenericStatusResponse>(
      `/orders/options/${brokerOrderId}`, 
      request,
      {
        needsBrokerId: true,
      }
    );
    return data;
  },

  async getOpenOrders() {
    const {data} = await axios.get<BrokerOrder[]>("/orders/open", {
      needsBrokerId: true,
    });
    return data;
  },

  async listOrders(request: ListOrdersRequest) {
    const {data} = await axios.get<ListOrdersResponse>("/orders", {
      needsBrokerId: true,
      params: request,
    });
    return data;
  },
  
  async cancelOptionsOrder(brokerOrderId: string) {
    const {data} = await axios.delete<GenericStatusResponse>(`/orders/options/${brokerOrderId}`, {
      needsBrokerId: true
   });
   return data;
  },

  async cancelStocksOrder(brokerOrderId: string) {
    const {data} = await axios.delete<GenericStatusResponse>(`/orders/stocks/${brokerOrderId}`, {
      needsBrokerId: true
   });
   return data;
  },

  async cancelCryptoOrder(brokerOrderId: string) {
    const {data} = await axios.delete<GenericStatusResponse>(`/orders/crypto/${brokerOrderId}`, {
      needsBrokerId: true
    });
    return data;
  }

}

export default ordersApi;