import { Card } from "antd";
import { ConnectStripe } from "../../stripe-connect/components/ConnectStripe";
import { StripeConnectAccountInfo } from "../../stripe-connect/components/StripeConnectAccountInfo";
import { useStripeConnectAccount } from "../../stripe-connect/hooks/useStripeConnectAccount";

export function StripeConnectSection() {
  const { setupComplete: hasConnectedStripe } = useStripeConnectAccount();
  return <Card>
    {hasConnectedStripe ? <StripeConnectAccountInfo /> : <ConnectStripe />}
  </Card>;
}
