import { SignalProvider } from "../api/signal-providers";

export function getSignalProviderReferralLink(provider : SignalProvider, ownerReferralCode: string) {
  return `${window.location.origin}/ref/${ownerReferralCode}?dest=/app/signal-providers/${provider.username}`
}

export function getUserReferralLink(code: string, dest?: string) {
  let url = `${window.location.origin}/ref/${code}`;
  if (dest) {
    url = `${url}?dest=${dest}`;
  }
  return url;
}