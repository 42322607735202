import axios from './axios';
import { GenericIDResponse } from './types';

export type WatchList = {
  id: number
  name: string
}

export type WatchlistItem = {
  symbol: string
  short_name: string | null
  long_name: string | null
  watchlist_id: number | null
}

export type AddWatchlistItemRequest = {
  symbol: string
}

export type CreateWatchlistRequest = {
  name: string
}


const watchlistApiV2 = {
  // ---------------------- Watchlist Endpoints ----------------------

  async getWatchlists() {
    const { data } = await axios.get<WatchList[]>('/watchlists/v2');
    return data;
  },

  async createWatchlist(request: CreateWatchlistRequest) {
    const { data } = await axios.post<GenericIDResponse>('/watchlists/v2', request);
    return data;
  },

  async deleteWatchlist(listId: number) {
    const { data } = await axios.delete<null>(`/watchlists/v2/${listId}`);
    return data;
  },

  // ---------------------- Default Watchlist Item Endpoints ----------------------

  async getDefaultWatchlistItems() {
    const { data } = await axios.get<WatchlistItem[]>('/watchlists/v2/items');
    return data;
  },

  async addDefaultWatchlistItem(request: AddWatchlistItemRequest) {
    const { data } = await axios.post<GenericIDResponse>('/watchlists/v2/items', request, {
      needsBrokerId: true,
    });
    return data;
  },

  async deleteAllDefaultWatchlistItems() {
    const { data } = await axios.delete<null>('/watchlists/v2/items');
    return data;
  },

  async deleteDefaultWatchlistItem(symbol: string) {
    const { data } = await axios.delete<null>(`/watchlists/v2/items/${symbol}`);
    return data;
  },

  // ---------------------- Specific Watchlist Item Endpoints ----------------------

  async getWatchlistItems(listId: number) {
    const { data } = await axios.get<WatchlistItem[]>(`/watchlists/v2/${listId}/items`);
    return data;
  },

  async addWatchlistItem(listId: number, request: AddWatchlistItemRequest) {
    const { data } = await axios.post<GenericIDResponse>(`/watchlists/v2/${listId}/items`, request, {
      needsBrokerId: true,
    });
    return data;
  },

  async deleteAllWatchlistItems(listId: number) {
    const { data } = await axios.delete<null>(`/watchlists/v2/${listId}/items`);
    return data;
  },

  async deleteWatchlistItem(listId: number, symbol: string) {
    const { data } = await axios.delete<null>(`/watchlists/v2/${listId}/items/${symbol}`);
    return data;
  },
};

export default watchlistApiV2;