import axios from "./axios";
import { AssetType, BotAction, BotAssetType, BotEnforce, BotOrderType, CryptoAmountUnit, OrderType, Side, TimeInForce } from "./enums";
import { DBOrder } from "./orders";
import { ListRequest, PaginatedResponse } from "./types";

export class ListBotAlertRequest extends ListRequest {
  max_age_days?: number
}

export type BotAlertError = {
  error?: string
}

export type OptionsAlertCalculatedParams = {
  order_type: OrderType
  time_in_force: TimeInForce
  limit_price: number | null
  stop_price: number | null
  premium: number | null
  quantity: number
  ticker_symbol: string
  strike_price: number
  expiration_date: string
  side: Side
}

export type  StockAlertCalculatedParams = {
  ticker_symbol: string
  order_type: OrderType
  side: Side
  time_in_force: TimeInForce
  quantity: number
  limit_price: number | null
  stop_price: number | null
  mid_price: number | null
  per_unit_price: number
}

export type CryptoAlertCalculatedParams = {
  ticker_symbol: string
  side: Side
  order_type: OrderType
  amount_unit: CryptoAmountUnit
  amount: number
  limit_price: number | null
  stop_price: number | null
  tif: TimeInForce
  mid_or_last_price: number | null
  approx_total: number | null
}

export type AutotradeBotBasic = {
  id: number
  name: string | null
}

/*
 * Common parameters for bot alert with orders. 
*/
export type BotAlertWithOrdersCommonParams = {
  id: number
  bot: AutotradeBotBasic
  asset_name: string
  action: BotAction
  order_type: BotOrderType
  enforce: BotEnforce
  price: number | null
  stop_price: number | null
  strike_price: number | null
  quantity: number | null
  expiration_date: string | null
  calculated_at: string | null
  executed_at: string | null
  created_at: string | null
  updated_at: string | null
  orders: DBOrder[]
  errors: BotAlertError[] | null
  crypto_amount_unit: CryptoAmountUnit | null
  crypto_amount: number | null
  crypto_price: number | null
  crypto_stop_price: number | null
}

export type StockBotAlertWithOrders = BotAlertWithOrdersCommonParams & {
  asset_type: BotAssetType.stocks
  parameters: StockAlertCalculatedParams[] | null
}

export type OptionsBotAlertWithOrders = BotAlertWithOrdersCommonParams & {
  asset_type: BotAssetType.options
  parameters: OptionsAlertCalculatedParams[] | null
}

export type CryptoBotAlertWithOrders = BotAlertWithOrdersCommonParams & {
  asset_type: BotAssetType.crypto
  parameters: CryptoAlertCalculatedParams[] | null
}

export type BotAlertWithOrders = StockBotAlertWithOrders | OptionsBotAlertWithOrders| CryptoBotAlertWithOrders;

export function isStockBotAlert(alert: BotAlertWithOrders) : alert is StockBotAlertWithOrders {
  return alert.asset_type === BotAssetType.stocks;
}

export function isOptionsBotAlert(alert: BotAlertWithOrders) : alert is OptionsBotAlertWithOrders {
  return alert.asset_type === BotAssetType.options;
}

export function isCryptoBotAlert(alert: BotAlertWithOrders) : alert is CryptoBotAlertWithOrders {
  return alert.asset_type === BotAssetType.crypto;
}

const autotradeAlertsApi = {
  async list(request: ListBotAlertRequest) {
    const { data } = await axios.get<PaginatedResponse<BotAlertWithOrders>>(
      '/autotrade-alerts', 
      { params: request, needsBrokerId: true }
    );
    return data;
  }
}

export default autotradeAlertsApi;