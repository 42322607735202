import { Tag } from "antd";
import { signalProviderTypeLabels } from "../../api/enum-labels";
import { SignalProviderType } from "../../api/enums";

export function SignalProviderTypeTag({ type } : { type: SignalProviderType }) {
  const color = {
    [SignalProviderType.Community]: 'gold',
    [SignalProviderType.Individual]: 'blue'
  }[type];

  return <Tag color={color}>
    { signalProviderTypeLabels[type] }
  </Tag>
}