import { Modal } from "antd";
import { useEffect, useState } from "react";
import { CreateSignalProviderWizard } from "./CreateSignalProviderWizard";

export function CreateSignalProviderModal({
  open, onClose, onSuccess
}: {
  open: boolean;
  onClose: () => void;
  onSuccess: () => void | Promise<void>;
}) {
  const [key, setKey] = useState(1);

  useEffect(() => {
    setKey(k => k+1)
  }, [open]);

  return <Modal open={open} onCancel={onClose} footer={null}>
    <CreateSignalProviderWizard key={key} onSuccess={onSuccess} />
  </Modal>
}
