import { directionLabelsShort } from "../../api/enum-labels";
import { AssetType } from "../../api/enums";
import { OpenPosition } from "../../api/positions";
import { formatNumberAuto, formatTZDate, formatUTCDate } from "../../shared/utils";
import { displayCryptoSymbol } from "../../trade/utils/crypto";

export const getQuantity = (position : OpenPosition) => {
  return position.quantity ? `${position.quantity}` : '';
}

export const getSymbol = (position: OpenPosition) => {
  if (position.asset_type === AssetType.Stocks) {
    return position.short_name;
  }
  if (position.asset_type === AssetType.Options) {
    const stock = position.short_name;
    if (!position.options_strike_price || 
        !position.options_expiration_date || 
        !position.options_direction) {
      return '';
    }
    const strike = formatNumberAuto(position.options_strike_price);
    const expiration = formatTZDate(position.options_expiration_date, 'MM/dd');
    const direction = directionLabelsShort[position.options_direction];
    return `${stock} ${strike}${direction} ${expiration}`
  }
  if (position.asset_type === AssetType.Crypto) {
    return displayCryptoSymbol(position.short_name);
  }
  return '';
}