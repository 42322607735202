import { OrderType, Side, Direction, TimeInForce, TrailType, AssetType, CryptoAmountUnit } from "./enums";
import axios from './axios';
import { GenericIDResponse, ListRequest, PaginatedResponse } from "./types";

export interface SignalItem {
  id: number;
  asset_type: AssetType;
  stock: string | null;
  side: Side | null;
  order_type: OrderType | null;
  time_in_force: TimeInForce | null;
  expiration_date: string | null;
  direction: Direction | null;
  strike_price: number | null;
  limit_price: number | null;
  stop_price: number | null;
  last_price: number | null;
  quantity: number | null;
  quantity_multipler: number | null;
  trail_value: number | null;
  trail_type: TrailType | null;
  outside_regular_trading_hour: boolean | null;
  created_at: string;
  updated_at: string;
  created_by_bot: boolean
  crypto_amount_unit: CryptoAmountUnit | null
  crypto_amount: number | null
  crypto_limit_price: number | null
  crypto_stop_price: number | null
}

export class ListSignalsRequest extends ListRequest {
  signal_provider_id?: number
  max_age_days?: number
}

export interface ShareSignalRequest {
  order_id: number
}

const signalsApi = { 
  async getSubscribedSignals(request : ListSignalsRequest){
    const {data} = await axios.get<PaginatedResponse<SignalItem>>(`/signals/subscribed`, {
      params: request
    })
    return data
  },

  async share(request: ShareSignalRequest) {
    const { data } = await axios.post<GenericIDResponse>('/signals', request);
    return data;
  }
}

export default signalsApi;