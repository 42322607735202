import { RcFile } from "antd/es/upload";

type BeforeUploadValueType = void | boolean | string | Blob | File;

export const getImageResizeFunction = (maxWidth: number, maxHeight: number) => (file: RcFile) : Promise<BeforeUploadValueType> | BeforeUploadValueType => {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      const img = document.createElement('img');
      img.src = reader.result as string;
      img.onload = () => {

        let width = img.naturalWidth;
        let height = img.naturalHeight;

        if (width > maxWidth) {
          width = maxWidth;
          height = maxHeight
        }

        const canvas = document.createElement('canvas');
        canvas.width = width;
        canvas.height = height;

        const ctx = canvas.getContext('2d')!;
        ctx.drawImage(img, 0, 0, width, height);

        const fileType = file.type || 'image/jpeg';
        canvas.toBlob((result) => resolve(result as Blob), fileType);

      };
    };
  });
}