import { Card, Col, Layout, Row, Typography } from 'antd';
import { useEmotionCss } from '@ant-design/use-emotion-css';
import { Outlet } from 'react-router-dom';
import { WebullPayConnectContextProvider } from '../contexts/WebullPayConnectContext';
const { Title, Text } = Typography

export const WebullPayConnectLayout = () => {
  return <Layout>
    <Layout.Content>
      <Row style={{ minHeight: '100vh' }}>
        <WebullPayConnectSidebar />
        <Col xs={24} lg={12} style={{ width: '100%', height: '100%', justifyContent: 'center', alignItems: 'center' }}>
          <Row justify='center' align='middle' style={{ minHeight: '100vh' }}>
            <Col xs={24} md={12} lg={12}>
              <Card>
                <WebullPayConnectContextProvider>
                  <Outlet />
                </WebullPayConnectContextProvider>
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>
    </Layout.Content>
  </Layout>
}

const WebullPayConnectSidebar = () => {

  const sidebar = useEmotionCss(({ token }) => ({
    display: 'none',
    backgroundImage: `url("https://images.unsplash.com/photo-1578357078586-491adf1aa5ba?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=764&q=80")`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    [`@media (min-width: ${token.screenLGMin}px)`]: {
      display: 'flex'
    },
    textAlign: 'left',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'white',
  }));

  const sideBarOverlay = useEmotionCss(({ token }) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    padding: '1rem',
  }))

return (
  <Col className={sidebar} lg={12}>
    <div className={sideBarOverlay}>
      <Row justify={'center'}>
        <Col span={24} lg={12}>
          <Title level={1}>
            Connect Webull Pay
          </Title>
          <Text>
            Login to your Webull Pay account to establish a secure connection with CandeFi. To proceed with the connection, you'll need the following Webull account details:<br />
            <br /> • Email 
            <br /> • Password 
            <br /> • Security questions and answers 
            <br /> • 6-digit trading passcode
          </Text>
        </Col>
      </Row>
    </div>
  </Col>
);

}