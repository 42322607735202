import { CanceledError } from "axios";
import api from "../../../api";
import { createAppAsyncThunk } from "../../../redux/hooks";
import { setUnreadMessageCounts } from "../chat.slice";
import { unreadMessageCountToObject } from "../utils/unread-message-count";

let abortController : AbortController | null = null;

export const fetchUnreadMessageCount = createAppAsyncThunk(
  'chat/fetchUnreadMessageCount',
  async function (_, { dispatch }) {

      // abort old fetching operation 
      if (abortController) {
        abortController.abort();
      }

      abortController = new AbortController();

      try {

        const counts = await api.chat.getUnreadMessageCount(abortController.signal);
        const countsObject = unreadMessageCountToObject(counts);
 
        // operation aborted dont update the state
        if (abortController.signal.aborted) {
          return
        }

        dispatch(setUnreadMessageCounts(countsObject));
  
      } catch (e) {

        // request cancelled
        if (e instanceof CanceledError) {
          return
        }

        // retry on error
        setTimeout(() => {
          dispatch(fetchUnreadMessageCount());
        }, 1000)
  
      }
  }
);
