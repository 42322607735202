import { CryptoQuoteResponse } from "../../api/crypto-quote";
import { CryptoAmountUnit } from "../../api/enums";
import { getCryptoMidOrLastPrice } from "../utils/crypto";

export const useCryptoEstimate = ({
  unit,
  amount,
  quote,
  limitPrice,
} : {
  unit: CryptoAmountUnit | null,
  amount: number | null,
  quote: CryptoQuoteResponse | null,
  limitPrice: number | null,
}) => {
  if (!unit || !amount || !quote) {
    return null;
  }

  const marketPrice = getCryptoMidOrLastPrice(quote);
  if (marketPrice === null) {
    return null;
  }

  if (unit == CryptoAmountUnit.USD) {
    return {
      label: 'Estimated Coins',
      value: amount / (limitPrice ? limitPrice : marketPrice),
    }
  }

  if (unit == CryptoAmountUnit.Coin) {
    return {
      label: 'Estimated Cost / Price',
      value: (limitPrice || marketPrice) * amount
    };
  }

  return null;
}