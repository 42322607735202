import { Col, Row, Segmented, Typography } from "antd";
import { SignalProviderWithMembershipPlans } from "../../api/signal-providers";
import useSWR from 'swr';
import { ListMyMembershipRequest } from "../../api/membership";
import { BillingIntervalUnit, MembershipStatus } from "../../api/enums";
import api from "../../api";
import { useEffect, useState } from "react";
import { SignalProviderPlanCard } from "./SignalProviderPlanCard";

const SignalProviderPlans = ({ signalProvider }: { signalProvider: SignalProviderWithMembershipPlans }) => {

  const plans = signalProvider?.membership_plans || [];
  const [selectedUnit, setSelectedUnit] = useState<BillingIntervalUnit>(BillingIntervalUnit.Year);

  const tabOptions = [
    { label: 'Weekly', value: BillingIntervalUnit.Week },
    { label: 'Monthly', value: BillingIntervalUnit.Month },
    { label: 'Yearly', value: BillingIntervalUnit.Year },
  ].filter(option => {
    return plans.some(plan => plan.billing_interval_unit === option.value);
  });

  const filteredPlans = plans.filter(p => p.billing_interval_unit === selectedUnit);

  useEffect(() => {
    if (tabOptions && tabOptions.length > 0) {
      setSelectedUnit(tabOptions[tabOptions.length - 1].value);
    }
  }, [JSON.stringify(tabOptions)])

  const { data: myMemberships } = useSWR(
    `/membership/me?signal_provider_id=${signalProvider.id}&status=active`,
    async () => {
      const request = new ListMyMembershipRequest();
      request.page = 1;
      request.per_page = 100;
      request.status = MembershipStatus.Active;
      const response = await api.membership.listMyMemberships(request);
      return response.data;
    }
  );

  return (
    <div>
      <div style={{ display: 'flex', justifyContent: 'center', width: '100%', marginBottom: '32px' }}>
        <Segmented
          size="large"
          options={tabOptions}
          value={selectedUnit}
          onChange={(e) => {
            setSelectedUnit(e as BillingIntervalUnit)
          }}
        />
    </div>
      <Row gutter={[16, 16]}>
        {
          filteredPlans.map((plan) => (
            <Col
              span={24}
              md={{ span: 12 }}
              lg={{ span: 8 }}
              key={plan.id}>
              <SignalProviderPlanCard 
                plan={plan} 
                signalProvider={signalProvider} 
                myMemberships={myMemberships} 
              />
            </Col>
          ))
        }
      </Row>
    </div>
  );
};

export default SignalProviderPlans;
