import { Button, Result, Space, Typography } from "antd";
import { useState } from "react";
import { Link } from "react-router-dom";
import api from "../../../api";
import { CommunityInviteWithCommunity } from "../../../api/signal-providers";
import { ErrorMessage } from "../../../shared/components/ErrorMessage";
import teamIllustration from '../../components/team.svg';
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';

export function InviteUi({
  invite, token
}: {
  invite: CommunityInviteWithCommunity;
  token: string;
}) {

  const [state, setState] = useState<'deciding' | 'accepted' | 'rejected'>('deciding');
  const [error, setError] = useState<unknown>(null);
  const [submitting, setSubmitting] = useState(false);
  const communityUrl = `/app/signal-providers/${invite.signal_provider.username}`;
  const dashboardUrl = '/app/account/analyst';

  const accept = async () => {
    try {

      setSubmitting(true);
      setError(null);
      await api.signalProviders.acceptInvite(token);
      setState('accepted');

    } catch (e) {

      setError(e);

    } finally {

      setSubmitting(false);

    }
  };

  const reject = async () => {
    try {

      setSubmitting(true);
      setError(null);
      await api.signalProviders.rejectInvite(token);
      setState('rejected');

    } catch (e) {

      setError(e);

    } finally {

      setSubmitting(false);

    }
  };

  return <div style={{ maxWidth: 600 }}>

    {state === 'deciding' && !!error && <ErrorMessage error={error} />}

    {state === 'deciding' && (
      <Result
        icon={<img src={teamIllustration} style={{ maxWidth: 300 }} />}
        title={(
          <span style={{
            // @ts-ignore
            textWrap: 'balance',
            display: 'inline-block'
          }}>
            Invitation To Join &nbsp;
            <Link target={'_blank'} to={communityUrl}>
              {invite.signal_provider.name}
            </Link>  &nbsp;
            Community
          </span>
        )}
        subTitle={(
          <Typography.Paragraph
            type="secondary"
            style={{
              fontSize: '1.3rem',
              // @ts-ignore
              textWrap: 'balance',
              display: 'inline-block'
            }}
          >
            You can accept or reject this invitaiton.
            If you accept signals you share will also be shared to this community.
          </Typography.Paragraph>
        )}
        extra={(
          <Space>
            <Button
              disabled={submitting}
              onClick={accept}
              icon={<CheckCircleOutlined />}
              type="primary"
              size="large"
            >
              Accept Invite
            </Button>
            <Button
              disabled={submitting}
              onClick={reject}
              icon={<CloseCircleOutlined />}
              size="large"
            >
              Reject Invite
            </Button>
          </Space>
        )} 
      />
    )}

    {state === 'accepted' && (
      <Result
        status='success'
        title="Accepted Invite"
        extra={<Space>
          <Link to={communityUrl}>
            <Button type="primary" size="large">
              Community Page
            </Button>
          </Link>
          <Link to={dashboardUrl}>
            <Button size="large">
              Back To Dashboard
            </Button>
          </Link>
        </Space>} />
    )}

    {state === 'rejected' && (
      <Result
        status='success'
        title="Rejected Invite"
        extra={<Space>
          <Link to={dashboardUrl}>
            <Button type="primary" size="large">
              Back To Dashboard
            </Button>
          </Link>
        </Space>} />
    )}
  </div>
}
