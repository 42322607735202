import { InputNumber, Select } from "antd";
import { cryptoAmountUnitLabels } from "../../api/enum-labels";
import { CryptoAmountUnit } from "../../api/enums";
import { CryptoQuantityPreference } from "./types";

export type CryptoQuantityInputProps = {
  value?: CryptoQuantityPreference
  onChange: (value: CryptoQuantityPreference) => void,
}

export const CryptoQuantityInput = ({
  value = { unit: CryptoAmountUnit.USD, quantity: null }, 
  onChange
}: CryptoQuantityInputProps) => {

  const options = Object.values(CryptoAmountUnit).map(type => ({
    value: type,
    label: cryptoAmountUnitLabels[type]
  }));

  const onUnitChange = (unit: CryptoAmountUnit) => {
    onChange({
      unit,
      quantity: null,
    });
  };

  const onQuantityChange = (quantity: number | null) => {
    onChange({
      unit: value.unit,
      quantity,
    });
  };

  return <div style={{ display: 'flex', gap: 8 }}>
    <Select
      value={value?.unit}
      options={options}
      onChange={onUnitChange}
      style={{ width: 150 }} />
    <InputNumber
      onChange={onQuantityChange}
      value={value.quantity}
      style={{ flexGrow: 1 }}
      min={0}
      addonBefore={value.unit === CryptoAmountUnit.USD ? "$" : undefined}
    />
  </div>
};
