import { Button, message } from "antd";
import { useState } from "react";
import { AssetType } from "../../api/enums";
import { SignalItem } from "../../api/signals";
import { getErrorMessage } from "../../shared/utils";
import { CryptoOrderConfirmationDialog } from "../../trade/components/CryptoOrderConfirmationDialog";
import { OptionsOrderConfirmationDialog } from "../../trade/components/OptionsOrderConfirmationDialog";
import { StocksOrderConfirmationDialog } from "../../trade/components/StocksOrderConfirmationDialog";
import { useCopySignal } from "../hooks/useCopySignal";

export const SignalCopyButton = ({ overriddenSignal }: { overriddenSignal: SignalItem }) => {

  const [showConfirmation, setShowConfirmation] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const [copy, isCopying, error] = useCopySignal(overriddenSignal);

  const isStockSignal = overriddenSignal.asset_type === AssetType.Stocks;
  const isOptionsSignal = overriddenSignal.asset_type === AssetType.Options;
  const isCryptoSignal = overriddenSignal.asset_type === AssetType.Crypto;

  const showConfirmationDialog = () => {
    setShowConfirmation(true);
  }

  const copySignal = async () => {
    try {

      await copy();
      messageApi.success("Copied Signal");

    } catch (e) {

      const errorMessage = getErrorMessage(e);
      messageApi.error(errorMessage);

    }
  }

  return <>
    {contextHolder}
    <Button
      type="primary"
      loading={isCopying}
      disabled={isCopying}
      onClick={showConfirmationDialog}>
      Trade
    </Button>
    {
      showConfirmation && isStockSignal && <StocksOrderConfirmationDialog
        open={showConfirmation}
        setOpen={setShowConfirmation}
        onOk={copySignal}
        stock={overriddenSignal.stock!}
        side={overriddenSignal.side!}
        limitPrice={overriddenSignal.limit_price}
        quantity={overriddenSignal.quantity}
        stopPrice={overriddenSignal.stop_price}
        timeInForce={overriddenSignal.time_in_force}
        orderType={overriddenSignal.order_type}
        trailType={overriddenSignal.trail_type}
        trailValue={overriddenSignal.trail_value}
        outSideRegularTradingHours={false}
      />
    }
    {
      showConfirmation && isOptionsSignal && <OptionsOrderConfirmationDialog
        open={showConfirmation}
        setOpen={setShowConfirmation}
        onOk={copySignal}
        stock={overriddenSignal.stock!}
        side={overriddenSignal.side!}
        limitPrice={overriddenSignal.limit_price}
        quantity={overriddenSignal.quantity}
        stopPrice={overriddenSignal.stop_price}
        timeInForce={overriddenSignal.time_in_force}
        orderType={overriddenSignal.order_type}
        direction={overriddenSignal.direction}
        expirationDate={overriddenSignal.expiration_date}
        strikePrice={overriddenSignal.strike_price}
      />
    }
    {
      showConfirmation && isCryptoSignal && <CryptoOrderConfirmationDialog
        open={showConfirmation}
        setOpen={setShowConfirmation}
        onOk={copySignal}
        symbol={overriddenSignal.stock!}
        side={overriddenSignal.side!}
        limitPrice={overriddenSignal.crypto_limit_price}
        amount={overriddenSignal.crypto_amount}
        amountUnit={overriddenSignal.crypto_amount_unit}
        stopPrice={overriddenSignal.crypto_stop_price}
        tif={overriddenSignal.time_in_force}
        orderType={overriddenSignal.order_type} 
        quote={null}        
      />
    }
  </>;
}
