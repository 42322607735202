import { CandeFiSWEvent, isCandefiSWEvent } from './CandeFiSWEvent';
import { FirebaseNotificationData } from '../api/extra-types/firebase-notification-data';

export const CANDEFI_SW_NOTIFICATION_CLICK_EVENT = 'NOTIFICATION_CLICK';

/**
 * Service Worker's notification click event
 */

export type CandefiNotificationClickEvent = CandeFiSWEvent<typeof CANDEFI_SW_NOTIFICATION_CLICK_EVENT, FirebaseNotificationData>;

export function isCandefiNotificationClickEvent(event: unknown): event is CandefiNotificationClickEvent {
  if (!isCandefiSWEvent(event)) {
    return false;
  }
  return event.type === CANDEFI_SW_NOTIFICATION_CLICK_EVENT;
}
