import { BrokerType } from "../brokers/entities/broker-account.entity";
import { AssetType, AutotradeOptionsExpiryType, AutotradeStrikePreference, BillingIntervalUnit, BotAction, BotAssetType, CandeFiMembershipStatus, ChatroomMemberRole, CommunityInviteStatus, ContactReason, CryptoAmountUnit, Direction, MembershipStatus, OptionsExpiryPreference, OrderType, ReferralStatus, Side, SignalProviderType, SortOrder, StrikePreference, TimeInForce, TrailType, UserRole } from "./enums";

export const assetTypeLabels : Record<AssetType, string> = {
  [AssetType.Stocks]: 'Stocks',
  [AssetType.Options]: 'Options',
  [AssetType.Crypto]: 'Crypto'
}

export const directionLabels : Record<Direction, string> = {
  [Direction.Call]: 'Call',
  [Direction.Put]: 'Put',
}

export const directionLabelsShort : Record<Direction, string> = {
  [Direction.Call]: 'C',
  [Direction.Put]: 'P',
}

export const sideLabels : Record<Side, string> = {
  [Side.Buy]: 'Buy',
  [Side.Sell]: 'Sell',
  // [Side.Short]: 'Short',
}

export const orderTypeLabels : Record<OrderType, string> = {
  [OrderType.Limit]: 'Limit',
  [OrderType.Market]: 'Market',
  [OrderType.Stop]: 'Stop',
  [OrderType.StopLimit]: 'Stop Limit',
  [OrderType.StopTrail]: 'Trailing Stop'
}

export const timeInForceLabels : Record<TimeInForce, string> = {
  [TimeInForce.Day]: 'Day',
  [TimeInForce.GoodTillCancelled]: 'Good Till Cancelled',
  [TimeInForce.ImmediateOrCancelled]: 'Immediate Or Cancelled',
}

export const timeInForceShortLabels : Record<TimeInForce, string> = {
  [TimeInForce.Day]: 'DAY',
  [TimeInForce.GoodTillCancelled]: 'GTC',
  [TimeInForce.ImmediateOrCancelled]: 'IOC',
}

export const trailTypeLabels : Record<TrailType, string> = {
  [TrailType.Dollar]: 'Dollar',
  [TrailType.Percentage]: 'Percentage',
}

export const billingIntervalUnitLabels : Record<BillingIntervalUnit, string> = {
  [BillingIntervalUnit.Month]: 'Month',
  [BillingIntervalUnit.Week]: 'Week',
  [BillingIntervalUnit.Year]: 'Year',
}

export const sortOrderLabels : Record<SortOrder, string> = {
  [SortOrder.Asc]: 'Asecnding',
  [SortOrder.Desc]: 'Descending'
}

export const memberhshipStatusLabels : Record<MembershipStatus, string> = {
  [MembershipStatus.Active]: 'Active',
  [MembershipStatus.Cancelled]: 'Cancelled',
  [MembershipStatus.Completed]: 'Completed',
  [MembershipStatus.Draft]: 'Draft',
}

export const userRoleLabels : Record<UserRole, string> = {
  [UserRole.admin]: 'Admin',
  [UserRole.analyst]: 'Analyst',
  [UserRole.trader]: 'Trader'
}

export const brokerTypeLabels : Record<BrokerType, string> = {
  [BrokerType.robinhood]: 'Robinhood',
  [BrokerType.webull]: 'Webull',
  [BrokerType.tda]: 'TDA',
  [BrokerType.webull_pay]: 'Webull Pay',
}

export const contactReasonLabels: Record<ContactReason, string> = {
  [ContactReason.BugReport]: 'Report Bug',
  [ContactReason.FeatureRequest]: 'Request Feature',
  [ContactReason.Feedback]: 'Feedback',
  [ContactReason.Support]: 'Support',
  [ContactReason.Other]: 'Other',
}

export const chatroomMemberRoleLabels : Record<ChatroomMemberRole, string> = {
  [ChatroomMemberRole.admin]: 'Admin',
  [ChatroomMemberRole.user]: 'User',
  [ChatroomMemberRole.analyst]: 'Analyst',
}

export const referralStatusLabels : Record<ReferralStatus, string> = {
  [ReferralStatus.Pending]: 'Pending',
  [ReferralStatus.Completed]: 'Completed',
  [ReferralStatus.Rewarded]: 'Rewarded'
}

export const  autotradeOptionsExpiryTypeLabels : Record<AutotradeOptionsExpiryType, string> = {
  [AutotradeOptionsExpiryType.Closest]: 'Closest',
  [AutotradeOptionsExpiryType.FridayExpiry]: 'Friday',
  [AutotradeOptionsExpiryType.NextWeekFriday]: 'Next Friday',
  [AutotradeOptionsExpiryType.EomFriday]: 'End of Month Friday',
  [AutotradeOptionsExpiryType.Manual]: 'Manual Date',
}

export const autotradeStrikePreferenceLabels : Record<AutotradeStrikePreference, string> = {
  [AutotradeStrikePreference.Closest]: 'Closest Strike',
  [AutotradeStrikePreference.InTheMoney]: 'In The Money',
  [AutotradeStrikePreference.OutTheMoney]: 'Out Of The Money',
}

export const botAssetTypeLabels : Record<BotAssetType, string> = {
  [BotAssetType.stocks]: 'Stocks',
  [BotAssetType.options]: 'Options',
  [BotAssetType.crypto]: 'Crypto'
}

export const strikePreferenceLabels : Record<StrikePreference, string> = {
  [StrikePreference.Closest]: 'Closest Strike',
  [StrikePreference.InTheMoney]: 'In The Money',
  [StrikePreference.OutTheMoney]: 'Out Of The Money',
}

export const  expiryPreferenceLabels: Record<OptionsExpiryPreference, string> = {
  [OptionsExpiryPreference.Closest]: 'Closest',
  [OptionsExpiryPreference.FridayExpiry]: 'Friday',
  [OptionsExpiryPreference.NextWeekFriday]: 'Next Friday',
  [OptionsExpiryPreference.EomFriday]: 'End of Month Friday',
  [OptionsExpiryPreference.Manual]: 'Manual Date',
}

export const candeFiMembershipStatusLabels : Record<CandeFiMembershipStatus, string> = {
  [CandeFiMembershipStatus.Active]: 'Active',
  [CandeFiMembershipStatus.PastDue]: 'Past Due',
}

export const signalProviderTypeLabels : Record<SignalProviderType, string> = {
  [SignalProviderType.Individual]: 'Individual',
  [SignalProviderType.Community]: 'Community',
}

export const communityInviteStatusLabels : Record<CommunityInviteStatus, string> = {
  [CommunityInviteStatus.pending]: 'Pending',
  [CommunityInviteStatus.accepted]: 'Accepted',
  [CommunityInviteStatus.rejected]: 'Rejected',
}


export const cryptoAmountUnitLabels : Record<CryptoAmountUnit, string> = {
  [CryptoAmountUnit.Coin]: 'Coin',
  [CryptoAmountUnit.USD]: 'USD ($)',
}