import { AssetType, CryptoAmountUnit, Side } from "../../api/enums";
import { OpenPosition } from "../../api/positions";
import { PlaceOrderFormInitialValues } from "../../trade/types/place-order-form-initial-values";
import { StockFormInitialValues } from "../../trade/hooks/useStocksForm";
import { OptionFormInitialValues } from "../../trade/hooks/useOptionsForm";
import { CryptoFormInitialValues } from "../../trade/hooks/useCryptoForm";

export const getClosePositionFormValues = (position: OpenPosition): PlaceOrderFormInitialValues | null => {

  if (position.asset_type === AssetType.Stocks) {
    const initialValues: StockFormInitialValues = {
      asset_type: AssetType.Stocks,
      stock: position.short_name,
      side: position.side === Side.Buy ? Side.Sell : Side.Buy,
      quantity: position.quantity,
    };
    return initialValues;
  }

  if (position.asset_type === AssetType.Options) {
    const initialValues: OptionFormInitialValues = {
      asset_type: AssetType.Options,
      stock: position.short_name,
      direction: position.options_direction || undefined,
      side: position.side === Side.Buy ? Side.Sell : Side.Buy,
      quantity: position.quantity,
      expirationDate: position.options_expiration_date || undefined,
      strikePrice: position.options_strike_price || undefined,
    };
    return initialValues;
  }

  if (position.asset_type === AssetType.Crypto) {
    const initialValues : CryptoFormInitialValues = {
      asset_type: AssetType.Crypto,
      symbol: position.short_name,
      amountUnit: CryptoAmountUnit.Coin,
      amount: position.quantity,
      side: Side.Sell
    }
    return initialValues;
  }

  return null;
};
