import { createContext, useContext, useState } from "react"
import { WatchlistItem } from "../../api/watchlist"

/**
 * Select watchlist item
 * 
 */
export interface WatchlistItemContextState {
  item: WatchlistItem | null,
  selectItem: (item: WatchlistItem) => void,
}

const WatchListItemContext = createContext<WatchlistItemContextState>({
  item: null,
  selectItem: (item: WatchlistItem) => {},
});

export const WatchlistItemContextProvider = ({
  children
} : {
  children: React.ReactElement | undefined
}) => {

  const [selected, setSelected] = useState<WatchlistItem|null>(null);

  const state : WatchlistItemContextState = {
    item: selected,
    selectItem: setSelected,
  }

  return <WatchListItemContext.Provider value={state}>
    { children }
  </WatchListItemContext.Provider>
}

export const useWatchListItem = () => useContext(WatchListItemContext)