import { Card } from "antd";
import { useAuthUser } from "../../auth/hooks/useAuthUser";
import { PreferenceForm } from "../../preference/components/PreferenceForm";

export const PreferencePage = () => {
  const { initialized, user } = useAuthUser();
  const pref = user?.preference || null;

  return (
    <Card
      title="Other Settings"
      style={{ height: "100%" }}
      bordered={false}
    >
      {
        initialized && user && (
          <div style={{ width: 'min(100%, 600px)' }}>
            <PreferenceForm pref={pref}  />
          </div>
        )
      }
    </Card>
  );
};
