import axios from './axios';

export interface Ticker {
  symbol: string;
  short_name: string;
  long_name: string
}

export type TickerAutocompleteRequest = {
  keyword: string
  options: boolean
  stocks: boolean
  crypto: boolean
}

const tickerSearchApi = {
  async search (request: TickerAutocompleteRequest, abortController?: AbortController) {
    const { data } = await axios.get<Ticker[]>('/ticker/search', {
      params: request,
      needsBrokerId: true,
      signal: abortController?.signal,
    })
    return data;
  }
}

export default tickerSearchApi;