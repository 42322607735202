import { getToken } from "firebase/messaging";
import { VAPID_KEY } from "./config";
import { messaging } from "./messaging";

export async function getFCMToken() {

  // Check service worker is registered
  const swRegistration = await navigator.serviceWorker.getRegistration(`${process.env.PUBLIC_URL}/service-worker.js`);
  if (swRegistration === undefined) {
    throw new Error("Service worker is not registered")
  }

  // Check notification permission is granted
  if (Notification.permission !== "granted") {
    throw new Error("Notification permission is not granted")
  }

  // Spaces in vapid key is very common mistake
  if (VAPID_KEY.includes(" ")) {
    throw new Error("Vapid key is incorrect it must not have any spaces");
  }

  /**
   * It returns fcm registration token
   * after subscribing push notifications. 
   * So we need to check service worker 
   * and notification permission before calling getTokens
   * 
   */
  return getToken(messaging, {
    vapidKey: VAPID_KEY,
    serviceWorkerRegistration: swRegistration,
  });

}
