import { Avatar, Button, Card, Space, Typography } from "antd";
import { SignalProvider } from "../../../api/signal-providers";
import { Link } from "react-router-dom";
import { EyeOutlined, UserOutlined, TeamOutlined, } from '@ant-design/icons';
import { SignalProviderType } from "../../../api/enums";
import { useEmotionCss } from "@ant-design/use-emotion-css";
import { SignalProviderTypeTag } from "../SignalProviderTypeTag";


/**
 * 
 * Card that show community that a analyst is part of
 * 
 */
export function AnalystCommunityListItem({
  provider,
}: {
  provider: SignalProvider;
}) {

  const label = {
    [SignalProviderType.Individual]: 'Individual',
    [SignalProviderType.Community]: 'Community',
  }[provider.type];

  const icon = {
    [SignalProviderType.Individual]: <UserOutlined />,
    [SignalProviderType.Community]: <TeamOutlined />,
  }[provider.type];

  const cardClass = useEmotionCss(({ token }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: 24,
    '& > :nth-child(1)': {
      flexGrow: 1,
      flexShrink: 1,
    },
    '& > :nth-child(2)': {
      flexGrow: 0,
      flexShrink: 0,
      flexBasis: 'auto',
    },
    [`@media (min-width: ${token.screenLGMin}px)`]: {
      flexDirection: 'row',
      alignItems: 'center',
      gap: 0,
    }
  }))

  return <Card size="small">
    <div className={cardClass}>
      <div style={{ display: 'flex', gap: 8, alignItems: 'center' }}>
        <Avatar
          icon={icon}
          size='large'
          style={{ flexShrink: 0 }}
        >
        </Avatar>
        <Space direction="vertical" size='small'>
          <Typography.Title level={4} style={{ margin: 0 }}>
            {provider.name}
          </Typography.Title>
          <div>
            <SignalProviderTypeTag type={provider.type} />
          </div>
          <Typography.Text type="secondary">
             @{provider.username}
          </Typography.Text>
        </Space>
      </div>

      <div>
        <div style={{ display: 'flex', gap: 8 }}>
          <Link to={`/app/signal-providers/${provider.username}`}>
            <Button icon={<EyeOutlined />} size="large" title="View Profile">
            </Button>
          </Link>
        </div>
      </div>
    </div>
  </Card>
}
