import axios from "./axios"

export type Preference = {
  notifications_enabled: boolean
}

export type UpdatePreferenceRequest = {
  notifications_enabled: boolean
}

const preferenceApi = {
  async update(request: UpdatePreferenceRequest) {
    const { data } = await axios.patch<null>('/preference', request);
    return data;
  }
}

export default preferenceApi;