import { createContext, useContext, useState } from "react"
import { WatchList, WatchlistItem } from "../../api/watchlist-v2"
import useSWR from 'swr';
import api from "../../api";

export type WatchlistContextState = {
  lists: {
    data: WatchList[],
    loading: boolean,
    error: unknown,
    reload: () => void | Promise<void>,
  },
  
  selectedListId: number | null,
  select: (id: number | null) => void | Promise<void>,

  items: {
    data: WatchlistItem[],
    loading: boolean,
    error: unknown,
    reload: () => void | Promise<void>,
  },
}

const WatchlistContext = createContext<WatchlistContextState>({
  selectedListId: null,
  select: (id) => {},

  lists: {
    data: [],
    loading: false,
    error: null,
    reload: () => {},
  },

  items: {
    data: [],
    loading: false,
    error: null,
    reload: () => {},
  }
})

export const WatchlistContextProvider = ({ children  } : { children: React.ReactNode }) => {
  const [selectedWatchlistId, setSelectedWatchlistId] = useState<number | null>(null);

  const watchlists = useSWR(
    '/api/watchlists/v2',
    () => api.watchlistV2.getWatchlists()
  );

  const selectedWatchlistItems = useSWR(
    ['/api/watchlists/v2/', selectedWatchlistId],
    (key) => {
      const id = key[1];
      return id === null
        ? api.watchlistV2.getDefaultWatchlistItems()
        : api.watchlistV2.getWatchlistItems(id)
    }
  )

  const state : WatchlistContextState = {
    selectedListId: selectedWatchlistId,
    select: setSelectedWatchlistId,
    items: {
      data: selectedWatchlistItems.data || [],
      loading: selectedWatchlistItems.isLoading,
      error: selectedWatchlistItems.error,
      reload: () => { selectedWatchlistItems.mutate() },
    },
    lists: {
      data: watchlists.data || [],
      loading: watchlists.isLoading,
      error: watchlists.error,
      reload: () => { watchlists.mutate() }
    }
  }

  return <WatchlistContext.Provider value={state}>
    { children }
  </WatchlistContext.Provider>
}

export const useWatchList = () => useContext(WatchlistContext)