import { Button, Form, Input } from "antd";
import { useState } from "react";
import { ErrorMessage } from "../../../shared/components/ErrorMessage";
import { useWebullPayConnectContext } from "../../contexts/WebullPayConnectContext";

export const PasswordAndPin = () => {
  const {
    login,
  } = useWebullPayConnectContext();

  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState<unknown>(null);

  return <div style={{ margin: '1rem 0' }}>
    <Form<{ password: string, trading_pin: string }>
      layout="vertical"
      onFinish={async (values) => {
        try {
          setError(null);
          setSubmitting(true);
          await login(values.password, values.trading_pin);
        } catch (e) {
          setError(e);
        } finally {
          setSubmitting(false);
        }
      }}
      >
      <ErrorMessage error={error} />
      <Form.Item
        label="Password"
        name="password"
        rules={[
          { required: true, message: 'password is required' },
        ]}
        required
      >
        <Input.Password />
      </Form.Item>

      <Form.Item
        label="Webull Trading Pin"
        name="trading_pin"
        rules={[
          { required: true, message: 'trading pin is required' },
          { pattern: /^\d{6}$/, message: '6 digit pin' }
        ]}
      >
        <Input
          maxLength={6}
          showCount={false}
          placeholder="Webull Trading Pin"
        />
      </Form.Item>
      <div>
        <Button 
          htmlType="submit"
          loading={submitting} 
          size="large" 
          type="primary"
          block
        >
          Login
        </Button>
      </div>
    </Form>
  </div>
}